import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { Button, Tooltip, Popconfirm, notification, Form } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import Api from "../../../Utils/Api";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { CloseOutlined } from "@ant-design/icons";
import TableAdmin from "../../../components/Table/TableAdmin";
import { deleteVersion, getVersionById } from "./../../Version/VersionSlice";
import ModalAddVersion from "./ModalAddVersion";

const ModalVersionByEditon = ({
  handleCancel,
  openModal,
  idEdition,
  idProduct,
}) => {
  const [form] = Form.useForm();
  const listVersion = useSelector((state) => state?.version?.items);
  const meta = useSelector((state) => state?.version?.meta);
  const isLoading = useSelector((state) => state?.version?.isLoading);
  const [nameEdition, setNameEdition] = useState("");
  const dispatch = useDispatch();
  const [modalAddVersion, setModalAddVersion] = useState({
    type: "",
    productId: "",
    editionId: null,
    openModal: false,
    dataEdit: {},
  });

  useEffect(() => {
    if (idEdition) {
      dispatch(getVersionById({ id: idEdition, params: null }));
    }
  }, [dispatch, idEdition]);

  const getEditionById = async (id) => {
    try {
      let response = await Api.get(`api/edition/getbyid/${id}`);
      if (response.status === 200) {
        setNameEdition(response?.data?.data?.title);
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (idEdition) {
      getEditionById(idEdition);
    }
  }, [dispatch, idEdition]);

  // useEffect(() => {
  //   const { search } = props.history.location;
  //   let params;
  //   const filterOptions = search.split("?")[1];
  //   if (filterOptions !== undefined) {
  //     params = `{"${decodeURI(filterOptions)
  //       .replace(/"/g, '\\"')
  //       .replace(/&/g, '","')
  //       .replace(/=/g, '":"')}"}`;
  //     dispatch(getVersionById({ id: idproduct, params: JSON.parse(params) }));
  //   } else {
  //     dispatch(getVersionById({ id: idproduct, params: null }));
  //   }
  // }, [dispatch, props.history.location, idproduct]);

  const columns = [
    { dataIndex: "title", title: "Version", type: "center" },
    {
      dataIndex: "productTitle",
      title: "Sản phẩm",
    },
    {
      dataIndex: "domain",
      title: "Domain",
      render: (value) => (
        <span className="cu font-medium">{value ? value : "-"}</span>
      ),
    },
    {
      dataIndex: "ipAddress",
      title: "Địa chỉ IP",
      render: (value) => (
        <span className="cu font-medium">{value ? value : "-"}</span>
      ),
    },
    {
      title: "Chức năng",
      render: (value) => (
        <div className="flex item-center justify-center">
          <div className="mr-2 transform hover:scale-110">
            <Tooltip title="Chỉnh sửa">
              <i
                onClick={() =>
                  setModalAddVersion({
                    openModal: true,
                    type: "update",
                    productId: idProduct,
                    editionId: idEdition,
                    dataEdit: value,
                  })
                }
                className="fas fa-pencil-alt  stroke-current text-white  bg-[#6E74EF] rounded-full p-[8px] "
              ></i>
            </Tooltip>
          </div>
          <div className="mr-2 transform  hover:scale-110">
            <Popconfirm
              title="Bạn có chắc chắn muốn xóa không ?"
              okText="Đồng ý"
              cancelText="Hủy"
              onConfirm={() => onDelete(value.id)}
            >
              <Tooltip title="Xóa">
                <i className="fas fa-trash-alt  stroke-current text-white  bg-[#EF5D5D] rounded-full p-[8px] cursor-pointer"></i>
              </Tooltip>
            </Popconfirm>
          </div>
        </div>
      ),
    },
    // {
    //   dataIndex: "description",
    //   title: "Ghi chú",
    //   type: "left",
    //   render: (value) =>
    //     value?.length >= 20 ? (
    //       <Popover
    //         content={
    //           <>
    //             <div className="max-w-[250px] break-words">
    //               <h1 className="border-b-2 text-xl font-semibold">Ghi chú </h1>
    //               <p> {value}</p>
    //             </div>
    //           </>
    //         }
    //       >
    //         <span className="font-medium">
    //           {value.substring(0, 20) + " ..."}
    //         </span>
    //       </Popover>
    //     ) : (
    //       <span className="font-medium">{value}</span>
    //     ),
    // },
  ];
  const onDelete = async (id) => {
    try {
      const resultAction = await dispatch(deleteVersion(id));
      unwrapResult(resultAction);
      notification.success({ message: `Xoá version thành công!` });
    } catch (error) {
      if (error.message === "Rejected") {
        notification.error({
          message: `Lỗi: Vui lòng kiểm tra lại kết nối mạng !`,
        });
      } else {
        notification.error({ message: `Lỗi: ${error.message}` });
      }
    }
  };
  // const fecthSearch = (value) => {
  //   console.log("dđ", idEdition);
  //   if (value) {
  //     dispatch(
  //       getVersionById({
  //         id: idEdition,
  //         params: { keyword: value.replace(/[&%?]/g, "") },
  //       })
  //     );
  //   } else {
  //     dispatch(
  //       getVersionById({
  //         id: idEdition,
  //         params: null,
  //       })
  //     );
  //   }
  // };
  // const debounceSearch = useCallback(debounce(fecthSearch, 1000), []);
  // const onSearch = (e) => {
  //   debounceSearch(e.target.value.replace(/[&%?]/g, ""));
  // };

  // const onChangePage = (page, size) => {
  //   dispatch(
  //     getVersionById({ id: idEdition, params: { page: page, size: size } })
  //   );
  // };

  const handleCancelVersion = async () => {
    await setModalAddVersion({
      editionId: "",
      productId: "",
      openModal: false,
      type: "",
      dataEdit: null,
    });
    dispatch(getVersionById({ id: idEdition, params: null }));
  };
  return (
    <>
      <Modal
        title={`Danh sách version "${nameEdition}"`}
        visible={openModal}
        onOk={handleCancel}
        onCancel={handleCancel}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: false }}
        cancelText="Thoát"
        width={"100%"}
        centered
        destroyOnClose={true}
        className="overflow-x-hidden modal-1300"
        closeIcon={
          <Tooltip title="Đóng">
            <CloseOutlined />
          </Tooltip>
        }
      >
        {modalAddVersion.openModal === true && (
          <ModalAddVersion
            editionId={modalAddVersion.editionId}
            productId={modalAddVersion.productId}
            type={modalAddVersion.type}
            dataEdit={modalAddVersion.dataEdit}
            modalAddVersion={modalAddVersion.openModal}
            handleCancelVersion={handleCancelVersion}
          />
        )}
        <div className="overflow-x-auto overflow-y-auto max-h-[1000px] mb-10">
          <div className="flex justify-center px-4 rounded-[10px]">
            <div className="w-full">
              <div className="w-full md:flex md:justify-between">
                <div>
                  <Button
                    type="primary"
                    onClick={() =>
                      setModalAddVersion({
                        openModal: true,
                        type: "create",
                        productId: idProduct,
                        editionId: idEdition,
                        dataEdit: null,
                      })
                    }
                  >
                    <div className="flex items-center">
                      <PlusOutlined className="pr-2" /> Thêm Version
                    </div>
                  </Button>
                </div>
                <Form form={form}>
                  <div className="md:flex md:justify-end">
                    <Form.Item name="searchName">
                      {/* <InputSearch
                        placeholder="Tìm kiếm version..."
                        onChange={onSearch}
                      /> */}
                    </Form.Item>
                  </div>
                </Form>
              </div>
              <div>
                <TableAdmin
                  hiddenAction
                  columns={columns}
                  dataTable={listVersion}
                  // Url={`${ROUTER.PRODUCT.EDITION.VERSION.LIST}/${idproduct}/edit`}
                  spinning={isLoading}
                  onDelete={onDelete}
                  // handlePagination={(v) =>
                  //   onChangePage(v?.currentPage, v?.itemsPerPage)
                  // }
                  // totalItems={meta?.total}
                  meta={meta}
                  // onChangePage={onChangePage}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ModalVersionByEditon;
