import React from "react";
import Chart from "react-apexcharts";

const ChartPie = ({ data }) => {
  let dataLicenKey = [];
  if (
    Number.isInteger(data?.countLicenseKeyOnline) &&
    Number.isInteger(data?.countLiscenseKeyOffline)
  ) {
    dataLicenKey = [data?.countLicenseKeyOnline, data?.countLiscenseKeyOffline];
  }
  const LicenKeyOptions = {
    chart: {
      width: 250,
      type: "donut",
      foreColor: "#fff",
      events: {
        dataPointSelection: (event, chartContext, config) => {},
      },
    },
    stroke: {
      width: 0,
    },
    colors: ["#EF5D5D", "#73D9BD"],
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
        donut: {
          labels: {
            show: true,
            total: {
              showAlways: true,
              show: true,
              label: "Tổng",
              color: "#fff",
            },
            value: {
              color: "#fff",
              fontSize: "38px",
              offsetY: 16,
            },
          },
        },
      },
    },
    fill: {
      type: "gradient",
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val.toFixed(1) + " %";
      },
    },
    labels: ["Online", "Offline"],
    legend: {
      position: "bottom",
    },
  };
  return (
    <div style={{ width: "100%", maxWidth: "100%" }}>
      <Chart
        options={LicenKeyOptions}
        series={dataLicenKey}
        type="donut"
        height={350}
      />
    </div>
  );
};

export default ChartPie;
