import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../../../Utils/Api";

export const getAccountDetail = createAsyncThunk(
  "auth/accountDetail",
  async (id, { rejectWithValue }) => {
    try {
      const response = await Api.get(`/api/account/getbyid/${id}`);
      if (response?.status === 200) {
        return response?.data.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const changeAccountDetail = createAsyncThunk(
  "auth/changeAccountDetail",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Api.post(`api/account/changeinfo`, data);
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);
const AccountDetailSlice = createSlice({
  name: "AccountDetail",
  initialState: {
    isLoading: false,
    accountDetail: {},
  },
  extraReducers: {
    [getAccountDetail.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getAccountDetail.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.accountDetail = action.payload;
    },
    [getAccountDetail.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [changeAccountDetail.pending]: (state, action) => {
      state.isLoading = true;
    },
    [changeAccountDetail.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.accountDetail = action.payload?.data;
    },
    [changeAccountDetail.rejected]: (state, action) => {
      state.isLoading = false;
    },
  },
});
export default AccountDetailSlice.reducer;
