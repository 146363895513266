import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../../Utils/Api";

export const getVersionById = createAsyncThunk(
  "LKM/getVersion",
  async ({ id, params }, { rejectWithValue }) => {
    try {
      const response = await Api.get(`/api/version/${id}/edition`, {
        params: params,
      });
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getAllVersion = createAsyncThunk("LKM/getAllVersion", async () => {
  try {
    const response = await Api.get(`/api/version/getall`);
    if (response?.status === 200) {
      return response?.data;
    }
  } catch (error) {}
});

export const deleteVersion = createAsyncThunk(
  "LKM/deleteVersion",
  async (id, { rejectWithValue }) => {
    try {
      const response = await Api.delete(`/api/version/delete/${id}`);
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const addVersion = createAsyncThunk(
  "LKM/addVersion",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Api.post(`/api/version/create`, data);
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);
export const updateVersion = createAsyncThunk(
  "LKM/updateVersion",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await Api.put(`/api/version/update/${id}`, data);
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);
const VersionSlice = createSlice({
  name: "Version",
  initialState: {
    items: [],
    meta: {
      page: 0,
      size: 10,
      total: 0,
    },
    isLoading: false,
  },
  extraReducers: {
    [getVersionById.pending]: (state) => {
      state.isLoading = true;
    },
    [getVersionById.fulfilled]: (state, action) => {
      const { data, meta } = action?.payload;
      state.items = data;
      state.isLoading = false;
      state.meta = {
        page: meta?.page,
        size: meta?.size,
        total: meta?.total,
      };
    },
    [getVersionById.rejected]: (state) => {
      state.isLoading = false;
    },
    [getAllVersion.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllVersion.fulfilled]: (state, action) => {
      const { data } = action?.payload;
      state.items = data;
      state.isLoading = false;
    },
    [getAllVersion.rejected]: (state) => {
      state.isLoading = false;
    },
    [deleteVersion.pending]: (state) => {
      state.isLoading = true;
    },
    [deleteVersion.fulfilled]: (state, action) => {
      if (action?.payload?.success === true) {
        state.items = state.items.filter((item) => item.id !== action.meta.arg);
      }
      state.isLoading = false;
    },
    [deleteVersion.rejected]: (state) => {
      state.isLoading = false;
    },
    [addVersion.pending]: (state) => {
      state.isLoading = true;
    },
    [addVersion.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [addVersion.rejected]: (state) => {
      state.isLoading = false;
    },
    [updateVersion.pending]: (state) => {
      state.isLoading = true;
    },
    [updateVersion.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [updateVersion.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});
export default VersionSlice.reducer;
