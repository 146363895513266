import React, { useEffect, useState } from "react";
import {
  CBadge,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
import { useSelector, useDispatch } from "react-redux";
import { ReportLicenseKeyTime } from "./../../pages/LicenseKey/LicenseKeySlice";
import ROUTER from "../../routers/routerLink";
import { Link } from "react-router-dom";
import { Tooltip } from "antd";

const TheHeaderDropdownNotif = () => {
  const [isNotif, setIsNotif] = useState(true);
  const dispatch = useDispatch();
  const notifiReportTimeList = useSelector(
    (state) => state.licenseKey.reportTimeList
  );
  let itemsCount;
  if (notifiReportTimeList?.expirationDateWith14Day) {
    itemsCount = notifiReportTimeList?.expirationDateWith14Day.length;
  }

  useEffect(() => {
    dispatch(ReportLicenseKeyTime());
  }, [dispatch]);

  return (
    <CDropdown inNav className="c-header-nav-item mx-2">
      <CDropdownToggle
        className="c-header-nav-link"
        caret={false}
        onClick={() => setIsNotif(false)}
      >
        <Tooltip title="Thông báo licensekey hết hạn">
          <span className="text-lg sm:text-xl">
            <i className="fas fa-bell text-white"></i>
          </span>
        </Tooltip>
        {isNotif && itemsCount > 0 ? (
          <>
            <CBadge shape="pill" color="danger">
              {itemsCount}
            </CBadge>
          </>
        ) : (
          ""
        )}
      </CDropdownToggle>
      <CDropdownMenu
        placement="bottom-end"
        className="mt-3 pt-0 bg-[#2d2d2d] border-none rounded-[8px] "
      >
        <CDropdownItem
          header
          tag="div"
          className="text-center text-white text-[15px] bg-[#262626] border-b-2 border-gray-400"
        >
          <strong>Thông Báo</strong>
        </CDropdownItem>
        {notifiReportTimeList?.listDataOfDay &&
        notifiReportTimeList?.listDataOfDay.length === 0 &&
        notifiReportTimeList?.expirationDateWith14Day &&
        notifiReportTimeList?.expirationDateWith14Day.length === 0 ? (
          <>
            <CDropdownItem header>
              <div>
                <i className="text-[#9E9E9E] text-[13px] font-thin leading-5">
                  Không có thông báo nào !
                </i>
              </div>
            </CDropdownItem>
          </>
        ) : (
          <>
            {/* {notifiReportTimeList?.listDataOfDay &&
            notifiReportTimeList?.listDataOfDay.length > 0 ? (
              <>
                <CDropdownItem header>
                  <div>
                    <i className="fas fa-bell text-red-500"></i>
                    <span className="text-white pl-2">
                      Có tổng
                      <b className="px-1">
                        {notifiReportTimeList?.listDataOfDay &&
                          notifiReportTimeList?.listDataOfDay.length}
                      </b>
                      key đã hết hạn ngày hôm nay
                    </span>
                  </div>
                </CDropdownItem>
              </>
            ) : (
              ""
            )} */}
            {notifiReportTimeList?.expirationDateWith14Day &&
            notifiReportTimeList?.expirationDateWith14Day.length > 0 ? (
              <>
                <CDropdownItem
                  className="hover:bg-gray-400 p-2 rounded-lg m-2"
                  header
                >
                  <Link to={ROUTER.LICENSEKEY_EXPIRATION_DATE.LIST}>
                    <div>
                      <i className="fas fa-bell text-yellow-500"></i>
                      <span className="text-white pl-2">
                        Có
                        <b className="px-1">
                          {notifiReportTimeList?.expirationDateWith14Day &&
                            notifiReportTimeList?.expirationDateWith14Day
                              .length}
                        </b>
                        key sắp hết hạn
                      </span>
                    </div>
                  </Link>
                </CDropdownItem>
              </>
            ) : (
              ""
            )}
            {/* <CDropdownItem header>
              <div className="text-center">
                <Link to={ROUTER.LICENSEKEY_EXPIRATION_DATE.LIST}>
                  Chi tiết
                </Link>
              </div>
            </CDropdownItem> */}
          </>
        )}
      </CDropdownMenu>
    </CDropdown>
  );
};

export default TheHeaderDropdownNotif;
