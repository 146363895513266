import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  notification,
  Button,
  Image,
  Upload,
  Tooltip,
} from "antd";
import {
  UploadOutlined,
  DeleteOutlined,
  VerticalAlignBottomOutlined,
  CloudDownloadOutlined,
  FileTwoTone,
} from "@ant-design/icons";
import FormModal from "../../../components/FormModal/FormModal";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { updateVersion, addVersion } from "./../../Version/VersionSlice";
import Api from "./../../../Utils/Api";
import { API_URL } from "./../../../Utils/Config";
import { getUserInfo } from "./../../../Utils/localStorage/GetUserInfo";
import { beforeUploadFile } from "./../../../components/UploadFile/beforeUploadFile";
import { saveAs } from "file-saver";

const ModalAddVersion = ({
  editionId,
  productId,
  type,
  dataEdit,
  modalAddVersion,
  handleCancelVersion,
}) => {
  const token = getUserInfo("token");
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [listFileUpload, setListFileUpload] = useState([]);

  const [urlFilesRemove, seturlFilesRemove] = useState([]);
  const [defaultUrlFiles, setDefaultUrlFiles] = useState([]);
  const [nameEdition, setNameEdition] = useState("");
  const [typeEdition, setTypeEdition] = useState(null);

  useEffect(() => {
    if (dataEdit !== null) {
      form.setFieldsValue({
        title: dataEdit?.title,
        domain: dataEdit?.domain || "",
        ipAddress: dataEdit?.ipAddress || "",
        description: dataEdit?.description,
      });
      setListFileUpload(dataEdit?.urlFiles);
    } else {
      form.resetFields();
      setListFileUpload([]);
    }
  }, [dataEdit, form]);

  const getEditionById = async (id) => {
    try {
      let response = await Api.get(`api/edition/getbyid/${id}`);
      if (response.status === 200) {
        setNameEdition(response?.data?.data?.title);
        setTypeEdition(response?.data?.data?.type);
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (editionId) {
      getEditionById(editionId);
    }
  }, [dispatch, editionId]);

  const onFinish = async (value) => {
    let remainFile = listFileUpload.filter(
      (x) => urlFilesRemove.indexOf(x) === -1
    );
    const data = {
      title: value?.title?.trim(),
      editionId: editionId,
      productId: productId,
      domain: typeEdition ? value?.domain?.trim() : "",
      ipAddress: typeEdition ? value?.ipAddress?.trim() : "",
      urlFiles: Array.from(new Set([].concat(remainFile, defaultUrlFiles))),
      description: value?.description?.trim() || "",
    };
    if (type === "update" && dataEdit?.id) {
      const id = dataEdit?.id;
      try {
        const resulDispatch = await dispatch(updateVersion({ id, data }));
        unwrapResult(resulDispatch);
        if (resulDispatch.payload.success === true) {
          notification.success({ message: `Sửa thành công !!!` });
          form.resetFields();
          handleCancelVersion();
          setDefaultUrlFiles([]);
          setListFileUpload([]);
          setNameEdition("");
          setTypeEdition(null);
        }
      } catch (error) {
        if (error.message === "Rejected") {
          notification.error({
            message: `Lỗi: Vui lòng kiểm tra lại kết nối mạng !`,
          });
        } else {
          notification.error({ message: `Lỗi: ${error.message}` });
        }
      }
    } else {
      try {
        const resulDispatch = await dispatch(addVersion(data));
        unwrapResult(resulDispatch);
        if (resulDispatch.payload.success === true) {
          notification.success({ message: `Thêm mới thành công !!!` });
          form.resetFields();
          handleCancelVersion();
          setDefaultUrlFiles([]);
          setListFileUpload([]);
          setNameEdition("");
          setTypeEdition(null);
        }
      } catch (error) {
        if (error.message === "Rejected") {
          notification.error({
            message: `Lỗi: Vui lòng kiểm tra lại kết nối mạng !`,
          });
        } else {
          notification.error({ message: `Lỗi: ${error.message}` });
        }
      }
    }
  };

  const uploadFile = {
    className: "custom-antd-upload",
    name: "files",
    action: `${API_URL}/api/version/uploads`,
    multiple: true,
    listType: "picture",
    beforeUpload: beforeUploadFile,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    showUploadList: true,
    onChange(info) {
      for (let i = 0; i < info.fileList.length; i++) {
        if (info.file.status === "done") {
          setListFileUpload([...listFileUpload, info?.file?.response?.data[0]]);
        }
      }
    },
    showUploadList: {
      showDownloadIcon: true,
      downloadIcon: (
        <CloudDownloadOutlined className="text-white hover:scale-110" />
      ),
      showRemoveIcon: true,
      removeIcon: <DeleteOutlined className="text-white hover:scale-110" />,
    },
    onDownload(e) {
      const dataFile = e?.response?.data[0];
      saveAs(
        `${API_URL}/${dataFile?.url}`,
        dataFile?.name?.length >= 50
          ? dataFile?.name?.substring(0, 50) + ". . ."
          : dataFile?.name
      );
    },
    onRemove(e) {
      seturlFilesRemove([
        ...urlFilesRemove,
        listFileUpload.find(
          (item) =>
            item?.name === (e?.response ? e?.response?.data[0]?.name : e?.name)
        ),
      ]);
    },
  };

  const saveFile = (item) => {
    saveAs(
      `${API_URL}/${item?.url}`,
      item?.name.length >= 50
        ? item?.name.substring(0, 50) + ". . ."
        : item?.name
    );
  };

  return (
    <>
      <FormModal
        titleModal={
          type === "update" ? "Chỉnh sửa version" : "Thêm mới version"
        }
        titleForm={"adddEditon"}
        visible={modalAddVersion}
        okText="Áp dụng"
        handleCancel={() => {
          form.resetFields();
          handleCancelVersion();
          setDefaultUrlFiles([]);
          setListFileUpload([]);
          setNameEdition("");
          setTypeEdition(null);
        }}
        width={"100%"}
        className="modal-1100"
        hiddenReset
      >
        <Form
          layout="vertical"
          onFinish={onFinish}
          form={form}
          id={`adddEditon`}
        >
          <div className="flex flex-wrap">
            <div className="w-full md:w-1/2">
              <Form.Item
                name="title"
                className="w-full px-3"
                label="Version"
                rules={[
                  {
                    required: true,
                    message: "Hãy nhập version",
                  },
                ]}
              >
                <Input
                  className="text-white custom-input-ant border w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300  focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                  placeholder="Version"
                />
              </Form.Item>
            </div>
            <div className="w-full md:w-1/2">
              <Form.Item className="w-full px-3" label="Edition">
                <input
                  className=" text-gray-600 bg-gray-300 border pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300 focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                  type="text"
                  value={nameEdition}
                  disabled
                />
              </Form.Item>
            </div>
          </div>
          {typeEdition && (
            <div className="flex flex-wrap">
              <div className="w-full md:w-1/2">
                <Form.Item
                  name="domain"
                  className="w-full px-3"
                  label="Domain"
                  rules={[
                    {
                      required: true,
                      message: "Hãy nhập domain",
                    },
                  ]}
                >
                  <Input
                    className="text-white custom-input-ant border w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300  focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                    placeholder="Domain"
                  />
                </Form.Item>
              </div>
              <div className="w-full md:w-1/2">
                <Form.Item
                  name="ipAddress"
                  className="w-full px-3"
                  label="Địa chỉ IP"
                  rules={[
                    {
                      required: true,
                      message: "Hãy nhập Địa chỉ IP",
                    },
                  ]}
                >
                  <Input
                    className="text-white custom-input-ant border w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300  focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                    placeholder="Địa chỉ IP"
                  />
                </Form.Item>
              </div>
            </div>
          )}
          <div className="">
            <div className="w-full md:w-1/2 px-3">
              <Upload
                defaultFileList={
                  dataEdit?.urlFiles?.length > 0
                    ? [
                        ...dataEdit?.urlFiles.map((item) => {
                          return { ...item, url: `${API_URL}/${item.url}` };
                        }),
                      ]
                    : []
                }
                {...uploadFile}
              >
                <Button>
                  <UploadOutlined />
                  Tải tệp đính kèm
                </Button>
              </Upload>
            </div>
            {/* <div className="w-full md:w-1/2 px-3 mb-2">
              <div className="flex flex-wrap ">
                {defaultUrlFiles &&
                  defaultUrlFiles.map((item, index) => {
                    return (
                      <div className="w-full border mt-2" key={index}>
                        <div className="flex items-center py-[10px] mb-1 mx-3 mt-1">
                          {item?.name?.split(".")[
                            item?.name?.split(".")?.length - 1
                          ] === "jpg" ||
                          item?.name?.split(".")[
                            item?.name?.split(".")?.length - 1
                          ] === "png" ? (
                            <Image
                              preview={false}
                              style={{
                                height: 40,
                                width: 40,
                                objectFit: "cover",
                              }}
                              src={`${API_URL}/${item?.url}`}
                            />
                          ) : (
                            <FileTwoTone className="text-2xl" />
                          )}
                          <div className="ml-3">
                            <span className="text-white">
                              {item?.name?.length >= 40
                                ? item?.name?.substring(0, 40) + "..."
                                : item?.name}
                            </span>
                          </div>
                          <div className="ml-auto">
                            <Tooltip title="Tải xuống">
                              <CloudDownloadOutlined
                                onClick={() => saveFile(item)}
                                className="text-white hover:scale-110 mx-2 mb-2"
                              />
                            </Tooltip>
                            <Tooltip title="Xóa">
                              <DeleteOutlined
                                className="text-white hover:scale-110"
                                onClick={() =>
                                  setDefaultUrlFiles(
                                    defaultUrlFiles.filter(
                                      (e) => e?.name !== item?.name
                                    )
                                  )
                                }
                              />
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div> */}
          </div>
          <div className="flex flex-wrap">
            <div className="w-full">
              <Form.Item
                name="description"
                className="w-full px-3"
                label="Ghi chú"
              >
                <Input.TextArea
                  className="text-white custom-input-ant border w-full rounded-[6px] hover:ring-1  hover:ring-purple-300  focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                  rows={5}
                  placeholder="Ghi chú"
                />
              </Form.Item>
            </div>
          </div>
        </Form>
      </FormModal>
    </>
  );
};

export default ModalAddVersion;
