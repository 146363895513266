import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../../Utils/Api";

export const getAccountByIdLogin = createAsyncThunk(
  "LKM/getAccount",
  async ({ id, params }, { rejectWithValue }) => {
    try {
      const response = await Api.get(`/api/account/${id}/get`, {
        params: params,
      });
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const deleteAccount = createAsyncThunk(
  "LKM/deleteAccount",
  async (id, { rejectWithValue }) => {
    try {
      const response = await Api.delete(`/api/account/delete/${id}`);
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);
export const addAccount = createAsyncThunk(
  "LKM/addAccount",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Api.post(`/api/account/create`, data);
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);
export const updateAccount = createAsyncThunk(
  "LKM/updateAccount",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await Api.put(`/api/account/update/${id}`, data);
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

const AccountSlice = createSlice({
  name: "Account",
  initialState: {
    items: [],
    meta: {
      page: 0,
      size: 10,
      total: 0,
    },
    isLoading: false,
  },
  extraReducers: {
    [getAccountByIdLogin.pending]: (state) => {
      state.isLoading = true;
    },
    [getAccountByIdLogin.fulfilled]: (state, action) => {
      const { data, meta } = action?.payload;
      state.items = data;
      state.isLoading = false;
      state.meta = {
        page: meta?.page,
        size: meta?.size,
        total: meta?.total,
      };
    },
    [getAccountByIdLogin.rejected]: (state) => {
      state.isLoading = false;
    },
    [deleteAccount.pending]: (state) => {
      state.isLoading = true;
    },
    [deleteAccount.fulfilled]: (state, action) => {
      if (action?.payload?.success === true) {
        state.items = state.items.filter((item) => item.id !== action.meta.arg);
      }
      state.isLoading = false;
    },
    [deleteAccount.rejected]: (state) => {
      state.isLoading = false;
    },
    [addAccount.pending]: (state, action) => {
      state.isLoading = true;
    },
    [addAccount.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [addAccount.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [updateAccount.pending]: (state, action) => {
      state.isLoading = true;
    },
    [updateAccount.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [updateAccount.rejected]: (state, action) => {
      state.isLoading = false;
    },
  },
});
export default AccountSlice.reducer;
