import React, { useState, useEffect, useCallback } from "react";
import { Form, Popover, Pagination, Tooltip } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import InputSearch from "../../../components/InputSearch/InputSearch";
import ROUTER from "../../../routers/routerLink";
// import ModalComposeMail from "./ModalComposeMail";
import { useSelector, useDispatch } from "react-redux";
// import { getSendMail } from "./../LicenseKeySendMailIntroSlice";
import { debounce } from "lodash";
import { RenderTimeSendMailList } from "./../../../components/RenderTimeSendMail.js/RenderTimeSendMail";
import {
  getSendMailExpires,
  setFilters,
  removeFilters,
} from "./../LicenseKeyHistorySendMailExpiresSlice";
import LicenseKeyHistorySendMailExpiresFilter from "./../Components/LicenseKeyHistorySendMailExpiresFilter";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";

const LicenseKeyHistorySendMailExpires = (props) => {
  const history = useHistory();
  const search = useLocation().search;
  const [showFilter, setShowFilter] = useState(false);
  const [valueSearch, setValueSearch] = useState("");
  // const [showModal, setShowModal] = useState({
  //   openModal: false,
  //   type: "",
  //   data: {},
  // });
  // const onShowModal = async (type, item) => {
  //   if (type === "forward") {
  //     setShowModal({
  //       openModal: true,
  //       type: type,
  //       data: type === "forward" ? item : {},
  //     });
  //   } else {
  //     setShowModal({
  //       openModal: true,
  //       type: type,
  //       data: null,
  //     });
  //   }
  // };
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const listSendMailExpires = useSelector(
    (state) => state?.historySendMailExpires?.items
  );
  const filters = useSelector(
    (state) => state?.historySendMailExpires?.filters
  );
  const meta = useSelector((state) => state?.historySendMailExpires?.meta);
  // useEffect(() => {
  //   const { search } = props.history.location;
  //   let params;
  //   const filterOptions = search.split("?")[1];
  //   if (filterOptions !== undefined) {
  //     params = `{"${decodeURI(filterOptions)
  //       .replace(/"/g, '\\"')
  //       .replace(/&/g, '","')
  //       .replace(/=/g, '":"')}"}`;
  //     dispatch(getSendMailExpires(JSON.parse(params)));
  //   } else {
  //     dispatch(getSendMailExpires(null));
  //   }
  // }, [dispatch, props.history.location]);

  useEffect(() => {
    if (filters) {
      setValueSearch(filters["keyword"]);
    } else {
      setValueSearch("");
    }
  }, [filters, form]);

  useEffect(() => {
    search && dispatch(setFilters(queryString.parse(search)));
  }, [dispatch, search]);
  useEffect(() => {
    return () => {
      dispatch(removeFilters());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(getSendMailExpires(queryString.parse(search)));
  }, [dispatch, search]);

  useEffect(() => {
    history.push(
      `${
        ROUTER.LICENSEKEY_HISTORY_SEND_MAIL_EXPIRES
          .LICENSEKEY_HISTORY_SEND_MAIL_EXPIRES
      }?${queryString.stringify(filters, {
        skipEmptyString: true,
      })}`
    );
  }, [history, filters]);

  const onChangePage = (page, size) => {
    dispatch(setFilters({ page: page, size: size }));
  };

  // const handleCancel = async () => {
  //   await setShowModal({
  //     openModal: false,
  //     type: "",
  //     data: null,
  //   });
  //   // await dispatch(getSendMailExpires(null));
  // };

  const fecthSearch = (value) => {
    if (value) {
      dispatch(setFilters({ keyword: value, page: "", size: "" }));
    } else {
      dispatch(setFilters({ keyword: "" }));
    }
  };
  const debounceSearch = useCallback(debounce(fecthSearch, 1000), []);
  const onSearch = (e) => {
    setValueSearch(
      e.target.value.replace(/[~`!#$%^&*()_={}[\]:;,<>+\\/|?-]/g, "")
    );
    debounceSearch(
      e.target.value.replace(/[~`!#$%^&*()_={}[\]:;,<>+\\/|?-]/g, "")
    );
  };

  return (
    <>
      {/* <ModalComposeMail
        showModal={showModal?.openModal}
        handleCancel={handleCancel}
        type={showModal?.type}
        data={showModal?.data}
      /> */}
      <div className="overflow-x-auto mb-10 mt-6 bg-[#262626] rounded-[10px]">
        <div className="flex justify-center mb-3 px-4 py-8">
          <div className="w-full">
            <div className="w-full md:flex md:justify-between h-auto">
              <div>
                {/* <ButtonSubmit
                  onClick={() => onShowModal("sendMail", null)}
                  iconButton={<i className="fas fa-plus pr-[15px]"></i>}
                  titleButton="Soạn thư"
                /> */}
              </div>
              <Form form={form}>
                <div className="md:flex md:justify-end">
                  <Form.Item name="searchName">
                    <InputSearch
                      placeholder="Tìm kiếm tiêu đề ..."
                      onChange={onSearch}
                      valueSearch={valueSearch}
                    />
                  </Form.Item>
                  <div className="ml-2 h-[40px]">
                    <Tooltip title="Lọc dữ liệu">
                      <button
                        onClick={() => setShowFilter(true)}
                        className="border-[1px] h-full w-[45px] mr-[2px] rounded-md shadow-md bg-[#262626] hover:ring-2 hover:ring-purple-300 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50"
                      >
                        <FilterOutlined className="text-[20px] text-white font-semibold" />
                      </button>
                    </Tooltip>
                    <LicenseKeyHistorySendMailExpiresFilter
                      openModal={showFilter}
                      handleCancel={() => setShowFilter(false)}
                      style={{ fontSize: "20px" }}
                    />
                  </div>
                </div>
              </Form>
            </div>
            <div className="">
              {listSendMailExpires && listSendMailExpires?.length > 0 ? (
                listSendMailExpires?.map((item, index) => {
                  return (
                    <div className="relative list-mail" key={index}>
                      <a
                        // target="_blank"
                        href={`${ROUTER.LICENSEKEY_HISTORY_SEND_MAIL_EXPIRES.LICENSEKEY_HISTORY_SEND_MAIL_EXPIRES}/${item?.id}`}
                        className="flex  border-t border-b py-2 cursor-pointer"
                      >
                        <div className="w-[310px] flex-col mx-2 overflow-hidden">
                          <div className="capitalize">
                            {item?.sendByAccountId ? (
                              <span className="text-white font-semibold text-base">
                                <Popover
                                  content={
                                    <div>
                                      <div className="capitalize">
                                        <span className="font-semibold ">
                                          <i className="fas fa-user  pr-1"></i>
                                          Họ tên
                                        </span>
                                        :
                                        {item?.sendByAccountName ||
                                          "Email tự động"}
                                      </div>
                                      <div className="pt-[5px]">
                                        <span className="font-semibold">
                                          <i className="fas fa-envelope pr-1"></i>
                                          Email
                                        </span>
                                        : {item?.infoSender?.email || "Chưa có"}
                                      </div>
                                      <div className="pt-[5px]">
                                        <span className="font-semibold">
                                          <i className="fas fa-phone  pr-1"></i>
                                          SĐT
                                        </span>
                                        : {item?.infoSender?.phone || "Chưa có"}
                                      </div>
                                      <div className="pt-[5px] capitalize">
                                        <span className="font-semibold ">
                                          <i className="fas fa-user-tag pr-1"></i>
                                          Chức vụ
                                        </span>
                                        :{" "}
                                        {item?.infoSender?.roleName ||
                                          "Chưa có"}
                                      </div>
                                    </div>
                                  }
                                >
                                  {item?.sendByAccountId &&
                                  item?.sendByAccountName?.length >= 35
                                    ? item?.sendByAccountName?.substring(
                                        0,
                                        35
                                      ) + ". . ."
                                    : item?.sendByAccountName}
                                </Popover>
                              </span>
                            ) : (
                              <div className="text-center text-white font-semibold text-base p-1  w-[120px] bg-green-500">
                                Email tự động
                              </div>
                            )}
                          </div>
                          <div>
                            <span className="text-white text-xs ">
                              <Popover
                                content={`Sản phẩm: ${item?.productName}`}
                              >
                                <b>Sản phẩm : </b>
                                {item?.productName?.length >= 35
                                  ? item?.productName?.substring(0, 35) +
                                    ". . ."
                                  : item?.productName}
                              </Popover>
                            </span>
                          </div>
                          <div>
                            <span className="text-white text-xs ">
                              <Popover
                                content={`Edition: ${item?.editionName}`}
                              >
                                <b>Edition : </b>
                                {item?.editionName?.length >= 35
                                  ? item?.editionName?.substring(0, 35) +
                                    ". . ."
                                  : item?.editionName}
                              </Popover>
                            </span>
                          </div>
                          <div>
                            <span className="text-white text-xs ">
                              <Popover
                                content={`Version: ${item?.versionName}`}
                              >
                                <b>Version : </b>
                                {item?.versionName?.length >= 35
                                  ? item?.versionName?.substring(0, 35) +
                                    ". . ."
                                  : item?.versionName}
                              </Popover>
                            </span>
                          </div>
                          <div>
                            <span className="text-white text-[12px] flex">
                              <b className="mt-1 pr-2">Tới : </b>
                              <div className="flex">
                                {item?.receiveMails
                                  .slice(0, 2)
                                  .map((e, index2) => {
                                    return (
                                      <Tooltip key={index2} title={e}>
                                        <span className="border py-[2px] px-[3px] text-[10px] rounded-2xl mr-1 ">
                                          {e?.length >= 20
                                            ? e?.substring(0, 20) + ". . ."
                                            : e}
                                        </span>
                                      </Tooltip>
                                    );
                                  })}
                                {item?.receiveMails.length > 2 && (
                                  <Popover
                                    content={item?.receiveMails
                                      .slice(2)
                                      .map((e2, indexx) => {
                                        return (
                                          <span>
                                            {item?.receiveMails.slice(2)
                                              .length ==
                                            indexx + 1
                                              ? `${e2}`
                                              : `${e2},  `}
                                          </span>
                                        );
                                      })}
                                  >
                                    <div className="text-white border py-[2px] px-[3px] rounded-2xl mr-2 ">
                                      +{item?.receiveMails?.length - 2}
                                    </div>
                                  </Popover>
                                )}
                              </div>
                            </span>
                          </div>
                        </div>
                        <div className="w-[1050px] sm:mr-[20px] ">
                          <span className="text-white font-semibold">
                            {item?.subject}
                          </span>
                          <span className="text-[#bbc1cc]">
                            &nbsp; - &nbsp;
                          </span>
                          <span className="text-[#bbc1cc]">
                            {item?.context
                              .replace(/<[^>]+>/g, "")
                              .replace(/\&nbsp;/g, " ").length >= 550
                              ? item?.context
                                  .replace(/<[^>]+>/g, "  ")
                                  .replace(/\&nbsp;/g, "  ")
                                  .substring(0, 550) + ". . ."
                              : item?.context
                                  .replace(/<[^>]+>/g, " ")
                                  .replace(/\&nbsp;/g, " ")}
                          </span>
                          {/* <div className="mt-2 flex">
                            {item?.urlAttachment &&
                              item?.urlAttachment
                                ?.slice(0, 2)
                                .map((e, index) => {
                                  return (
                                    <>
                                      <Popover content={e?.split("/")[2]}>
                                        <div
                                          key={index}
                                          className="flex border py-1 px-3 rounded-2xl mr-2"
                                        >
                                          {e?.split(".")[
                                            e?.split(".").length - 1
                                          ] === "jpg" ||
                                          e?.split(".")[
                                            e.split(".").length - 1
                                          ] === "png" ? (
                                            <Image
                                              className="mt-1 pr-2"
                                              preview={false}
                                              src={notImage}
                                            />
                                          ) : (
                                            <i className="fas fa-file-archive text-white mt-1 pr-2"></i>
                                          )}
                                          <a
                                            target="_blank"
                                            rel="noreferrer"
                                            className="text-gray-400"
                                            href={`${API_URL}/${e}`}
                                          >
                                            {e?.split("/")[2]?.split(".")[0]
                                              .length >= 15
                                              ? e
                                                  ?.split("/")[2]
                                                  ?.split(".")[0]
                                                  .substring(0, 15) + ". . ."
                                              : e?.split("/")[2]?.split(".")[0]}
                                          </a>
                                        </div>
                                      </Popover>
                                    </>
                                  );
                                })}
                            {item?.urlAttachment.length > 2 && (
                              <Popover
                                content={`${
                                  item?.urlAttachment?.length - 2
                                } tệp đính kèm khác`}
                              >
                                <div className="text-white border py-1 px-1 rounded-2xl mr-2 ">
                                  +{item?.urlAttachment?.length - 2}
                                </div>
                              </Popover>
                            )}
                          </div> */}
                        </div>

                        <div className="date-mail ml-auto">
                          <span className="text-[#bbc1cc] text-xs mr-2">
                            {RenderTimeSendMailList(item?.sendingTime)}
                          </span>
                        </div>
                      </a>
                      {/* <div className="show-icon-forward">
                        <Tooltip title="Chuyển tiếp">
                          <i
                            onClick={() => onShowModal("forward", item)}
                            className="hover:scale-105 fas fa-share fa-2x text-white cursor-pointer opacity-75"
                          ></i>
                        </Tooltip>
                      </div> */}
                    </div>
                  );
                })
              ) : (
                <div className="flex justify-center h-[150px]">
                  <div className=" flex flex-col text-center text-white justify-center ">
                    <i className="fas fa-exclamation-circle fa-2x"></i>
                    <span className="text-xl mt-2">
                      Không có lịch sử gửi email hết hạn
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-end mt-3 mb-6 mx-3">
          {meta?.total > 10 ? (
            <>
              <Pagination
                // size="small"
                className="custom-pagination"
                current={meta?.page ? meta?.page : 1}
                total={meta?.total}
                pageSize={meta?.size}
                onChange={onChangePage}
                showSizeChanger
              />
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default LicenseKeyHistorySendMailExpires;
