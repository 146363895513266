import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Api from "./../../../Utils/Api";

export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (email, { rejectWithValue }) => {
    try {
      const response = await Api.get(
        `/api/account/forgotpassword/${email?.email}`
      );
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

const ForgotPasswordSlice = createSlice({
  name: "ForgotPassword",
  initialState: {
    isLoading: false,
    message: "",
  },
  extraReducers: {
    [forgotPassword.pending]: (state, action) => {
      state.isLoading = true;
    },
    [forgotPassword.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [forgotPassword.rejected]: (state, action) => {
      state.isLoading = false;
    },
  },
});
export default ForgotPasswordSlice.reducer;
