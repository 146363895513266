import Home from "../pages/Home/Home";
import AccountList from "./../pages/Account/AccountPage/AccountList";
import AccountAdd from "../pages/Account/AccountPage/AccountAdd";
import RoleList from "../pages/Role/RolePage/RoleList";
import LicenseKeyList from "./../pages/LicenseKey/LicenseKeyPage/LicenseKeyList";
import LicenseKeyCategoryList from "../pages/LicenseKeyCategory/LicenseKeyCategoryPage/LicenseKeyCategoryList";
import AccountEdit from "../pages/Account/AccountPage/AccountEdit";
import RoleAdd from "./../pages/Role/RolePage/RoleAdd";
import LicenseKeyCategoryAdd from "./../pages/LicenseKeyCategory/LicenseKeyCategoryPage/LicenseKeyCategoryAdd";
import RoleEdit from "./../pages/Role/RolePage/RoleEdit";
import LicenseKeyAdd from "./../pages/LicenseKey/LicenseKeyPage/LicenseKeyAdd";
import LicenseKeyEdit from "../pages/LicenseKey/LicenseKeyPage/LicenseKeyEdit";
import OrganizationList from "./../pages/Organization/OrganizationPage/OrganizationList";
import OrganizationAdd from "./../pages/Organization/OrganizationPage/OrganizationAdd";
import CustomerList from "./../pages/Customer/CustomerPage/CustomerList";
import ProductList from "./../pages/Product/ProductPage/ProductList";
import DepartmentList from "./../pages/Department/DepartmentPage/DepartmentList";
import DepartmentAdd from "./../pages/Department/DepartmentPage/DepartmentAdd";
import CustomerAdd from "./../pages/Customer/CustomerPage/CustomerAdd";
import ROUTER from "./routerLink";
import PriorityList from "./../pages/Priority/PriorityPage/PriorityList";
import OrganizationEdit from "./../pages/Organization/OrganizationPage/OrganizationEdit";
import DepartmentEdit from "./../pages/Department/DepartmentPage/DepartmentEdit";
import PriorityEdit from "./../pages/Priority/PriorityPage/PriorityEdit";
import PriorityAdd from "./../pages/Priority/PriorityPage/PriorityAdd";
import ProductAdd from "./../pages/Product/ProductPage/ProductAdd";
import ProductEdit from "./../pages/Product/ProductPage/ProductEdit";
import VersionList from "./../pages/Version/VersionPage/VersionList";
import VersionAdd from "./../pages/Version/VersionPage/VersionAdd";
import VersionEdit from "./../pages/Version/VersionPage/VersionEdit";
import CustomerEdit from "./../pages/Customer/CustomerPage/CustomerEdit";
import LicenseKeyCategoryEdit from "./../pages/LicenseKeyCategory/LicenseKeyCategoryPage/LicenseKeyCategoryEdit";
import ChangePassword from "./../pages/Auth/ChangePassword/ChangePassword";
import AuthDetail from "./../pages/Auth/AccountDetail/index";
import LicenseKeyInChartList from "./../pages/LicenseKeyInChart/LicenseKeyInChartPage/LicenseKeyInChartList";
import LicenseKeyCategoryChart from "../pages/LicenseKeyCategoryChart/LicenseKeyCategoryChart";
// import Organization from "../pages/Organization/OrganizationPage/Organization";
import LicenseKeyExpirationDate from "./../pages/LicenseKeyExpirationDate/LicenseKeyExpirationDate";
import LicenseKeySendMailExpirationDate from "../pages/LicenseKeySendMailExpirationDate/LicenseKeySendMailExpirationDate";
import LicenseKeySendMailIntro from "./../pages/LicenseKeySendMailIntro/LicenseKeySendMailIntroPage/LicenseKeySendMailIntro";
import LicenseKeySendMailIntroDetail from "./../pages/LicenseKeySendMailIntro/LicenseKeySendMailIntroPage/LicenseKeySendMailIntroDetail";
import EditionList from "./../pages/Edition/EditionPage/EditionList";
import EditionAdd from "../pages/Edition/EditionPage/EditionAdd";
import EditionEdit from "./../pages/Edition/EditionPage/EditionEdit";
import LicenseKeyHistorySendMailExpires from "../pages/LicenseKeyHistorySendMailExpires/LicenseKeyHistorySendMailExpiresPage/LicenseKeyHistorySendMailExpires";
import LicenseKeyHistorySendMailExpiresDetail from "./../pages/LicenseKeyHistorySendMailExpires/LicenseKeyHistorySendMailExpiresPage/LicenseKeyHistorySendMailExpiresDetail";
import GroupCustomer from "./../pages/GroupCustomer/GroupCustomerPage/GroupCustomer";
import MailActiveList from "../pages/MailActive/MailActiveList";

const routes = [
  {
    path: ROUTER.HOME.HOME,
    exact: true,
    component: Home,
    name: "Trang chủ",
  },
  {
    path: ROUTER.AUTH.CHANGEPASSWORD,
    name: "Đổi mật khẩu",
    component: ChangePassword,
  },
  {
    path: ROUTER.AUTH.AUTHDETAIL,
    name: "Cập Nhật Tài Khoản",
    component: AuthDetail,
  },
  {
    path: `${ROUTER.LICENSEKEYINCHART.LIST}/:id`,
    name: "Danh sách LicenseKey hết hạn",
    component: LicenseKeyInChartList,
  },
  {
    path: ROUTER.LICENSEKEY.LIST,
    exact: true,
    name: "Quản lý LicenseKey",
    component: LicenseKeyList,
  },
  {
    path: ROUTER.LICENSEKEY.ADD,
    name: "Thêm mới LicenseKey",
    component: LicenseKeyAdd,
  },
  {
    path: `${ROUTER.LICENSEKEY.EDIT}/:id`,
    name: "Cập nhật LicenseKey",
    component: LicenseKeyEdit,
  },
  {
    path: ROUTER.LICENSEKEY_CATEGORY.LIST,
    exact: true,
    name: "Quản lý danh mục License",
    component: LicenseKeyCategoryList,
  },
  {
    path: ROUTER.LICENSEKEY_CATEGORY.ADD,
    name: "Thêm danh mục",
    component: LicenseKeyCategoryAdd,
  },
  {
    path: `${ROUTER.LICENSEKEY_CATEGORY.EDIT}/:id`,
    name: "Cập nhật danh mục",
    component: LicenseKeyCategoryEdit,
  },

  {
    path: ROUTER.ROLE.LIST,
    exact: true,
    name: "Quản lý vai trò",
    component: RoleList,
  },
  { path: ROUTER.ROLE.ADD, name: "Thêm mới vai trò", component: RoleAdd },
  {
    path: `${ROUTER.ROLE.EDIT}/:id`,
    name: "Thêm mới vai trò",
    component: RoleEdit,
  },
  {
    path: ROUTER.CUSTOMER.LIST,
    exact: true,
    name: "Quản lý khách hàng",
    component: CustomerList,
  },
  {
    path: ROUTER.CUSTOMER.ADD,
    name: "Thêm mới khách hàng",
    component: CustomerAdd,
  },
  {
    path: `${ROUTER.CUSTOMER.EDIT}/:id`,
    name: "Cập nhật khách hàng",
    component: CustomerEdit,
  },
  // {
  //   path: ROUTER.ORGANIZATION.LIST,
  //   exact: true,
  //   name: "Quản lý tổ chức",
  //   component: Organization,
  // },
  {
    path: ROUTER.ORGANIZATION.LIST,
    exact: true,
    name: "Quản lý tổ chức",
    component: OrganizationList,
  },
  {
    path: ROUTER.ORGANIZATION.ADD,
    name: "Thêm mới tổ chức",
    component: OrganizationAdd,
  },
  {
    path: `${ROUTER.ORGANIZATION.EDIT}/:id`,
    name: "Cập nhật tổ chức",
    component: OrganizationEdit,
  },
  {
    path: ROUTER.DEPARTMENT.LIST,
    exact: true,
    name: "Quản lý phòng ban",
    component: DepartmentList,
  },
  {
    path: ROUTER.DEPARTMENT.ADD,
    name: "Thêm mới phòng ban",
    component: DepartmentAdd,
  },
  {
    path: `${ROUTER.DEPARTMENT.EDIT}/:id`,
    name: "Cập nhật phòng ban",
    component: DepartmentEdit,
  },
  {
    path: ROUTER.PRIORITY.LIST,
    exact: true,
    name: "Quản lý ưu tiên",
    component: PriorityList,
  },
  {
    path: ROUTER.PRIORITY.ADD,
    name: "Thêm mới ưu tiên",
    component: PriorityAdd,
  },
  {
    path: `${ROUTER.PRIORITY.EDIT}/:id`,
    name: "Cập nhật ưu tiên",
    component: PriorityEdit,
  },
  {
    path: ROUTER.PRODUCT.LIST,
    exact: true,
    name: "Quản lý sản phẩm",
    component: ProductList,
  },
  {
    path: ROUTER.PRODUCT.ADD,
    name: "Thêm mới sản phẩm",
    component: ProductAdd,
  },
  {
    path: `${ROUTER.PRODUCT.EDIT}/:id`,
    name: "Cập nhật sản phẩm",
    component: ProductEdit,
  },

  {
    path: `${ROUTER.PRODUCT.EDITION.LIST}/:idproduct`,
    exact: true,
    name: "Quản lý edition",
    component: EditionList,
  },
  {
    path: `${ROUTER.PRODUCT.EDITION.LIST}/:idproduct/add`,
    name: "Thêm mới edition",
    component: EditionAdd,
  },
  {
    path: `${ROUTER.PRODUCT.EDITION.LIST}/:idproduct/edit/:id`,
    name: "Cập nhật edition",
    component: EditionEdit,
  },

  {
    path: `${ROUTER.PRODUCT.EDITION.VERSION.LIST}/:idEdition`,
    exact: true,
    name: "Quản lý Version",
    component: VersionList,
  },
  {
    path: `${ROUTER.PRODUCT.EDITION.VERSION.LIST}/:idEdition/add`,
    name: "Thêm mới version",
    component: VersionAdd,
  },
  {
    path: `${ROUTER.PRODUCT.EDITION.VERSION.LIST}/:idEdition/edit/:id`,
    name: "Cập nhật version",
    component: VersionEdit,
  },

  {
    path: ROUTER.ACCOUNT.LIST,
    exact: true,
    name: "Quản lý tài khoản",
    component: AccountList,
  },
  {
    path: ROUTER.ACCOUNT.ADD,
    name: "Thêm mới tài khoản",
    component: AccountAdd,
  },
  {
    path: `${ROUTER.ACCOUNT.EDIT}/:id`,
    name: "Cập nhật tài khoản",
    component: AccountEdit,
  },
  {
    path: `${ROUTER.LICENSEKEY_CATEGORY_CHART.LIST}/:id`,
    exact: true,
    name: "Danh sách LicenseKey danh mục",
    component: LicenseKeyCategoryChart,
  },
  {
    path: ROUTER.LICENSEKEY_EXPIRATION_DATE.LIST,
    exact: true,
    name: "LicenseKey Sắp hết hạn",
    component: LicenseKeyExpirationDate,
  },
  {
    path: `${ROUTER.LICENSEKEY_SEND_MAIL_EXPIRATIONDATE.LICENSEKEY_SEND_MAIL_EXPIRATIONDATE}/:id`,
    exact: true,
    name: "Gửi email licensekey hết hạn",
    component: LicenseKeySendMailExpirationDate,
  },
  // {
  //   path: ROUTER.LICENSEKEY_SEND_MAIL_INTRO.LICENSEKEY_SEND_MAIL_INTRO,
  //   exact: true,
  //   name: "Gửi email giới thiệu",
  //   component: LicenseKeySendMailIntro,
  // },
  {
    path: `${ROUTER.LICENSEKEY_SEND_MAIL_INTRO.LICENSEKEY_SEND_MAIL_INTRO}/:id`,
    exact: true,
    name: "Chi tiết email",
    component: LicenseKeySendMailIntroDetail,
  },
  {
    path: ROUTER.LICENSEKEY_HISTORY_SEND_MAIL_EXPIRES
      .LICENSEKEY_HISTORY_SEND_MAIL_EXPIRES,
    exact: true,
    name: "Lịch sử gửi email licensekey hết hạn ",
    component: LicenseKeyHistorySendMailExpires,
  },
  {
    path: ROUTER.MAILACTIVE.LIST,
    exact: true,
    name: "Danh sách Email đăng ký kích hoạt tài khoản",
    component: MailActiveList,
  },
  {
    path: ROUTER.LICENSEKEY_HISTORY_SEND_MAIL_EXPIRES
      .LICENSEKEY_HISTORY_SEND_MAIL_EXPIRES,
    exact: true,
    name: "Lịch sử gửi email licensekey hết hạn ",
    component: LicenseKeyHistorySendMailExpires,
  },
  {
    path: `${ROUTER.LICENSEKEY_HISTORY_SEND_MAIL_EXPIRES.LICENSEKEY_HISTORY_SEND_MAIL_EXPIRES}/:id`,
    exact: true,
    name: "Chi tiết email thông báo hết hạn",
    component: LicenseKeyHistorySendMailExpiresDetail,
  },
  {
    path: ROUTER.GROUPCUSTOMER.LIST,
    exact: true,
    name: "Nhóm khách hàng gửi Email",
    component: GroupCustomer,
  },
];

export default routes;
