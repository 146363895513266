import React, { useState, useEffect } from "react";
import {
  Modal,
  Tooltip,
  Form,
  Button,
  Input,
  Select,
  Upload,
  Image,
  notification,
  ConfigProvider,
  Typography,
  message,
} from "antd";
import Creatable from "react-select/creatable";
import Api from "../../../Utils/Api";
import { useSelector, useDispatch } from "react-redux";
import {
  UploadOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  CloseOutlined,
} from "@ant-design/icons";
// import { beforeUploadFile } from "../../../components/UploadFile/beforeUploadFile";
import { unwrapResult } from "@reduxjs/toolkit";
import { sendMailIntro } from "./../LicenseKeySendMailIntroSlice";
import { getAllProduct } from "./../../Product/ProductSlice";
import { getUserInfo } from "./../../../Utils/localStorage/GetUserInfo";
import { API_URL, EDITORCONFIGURATION } from "./../../../Utils/Config";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import ModalTemplateMail from "./ModalTemplateMail";
import { beforeUploadFileEmailTxt } from "./../../../components/UploadFile/beforeUploadFileEmailTxt";

const { Option } = Select;
const ModalComposeMail = ({ handleCancel, showModal, type, data }) => {
  const token = getUserInfo("token");
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [templateSendMailId, setTemplateSendMailId] = useState("default");
  const [listAllTemplateMail, setListAllTemplateMail] = useState([]);
  const [contextTemplateSendMail, setContextTemplateMail] = useState("");
  const [titleTemplateMail, setTitleTemplateMail] = useState("");
  const [selectedProductId, setSelectedProductId] = useState("");
  const [selectedEditionId, setSelectedEditionId] = useState("");
  const [selectedVersionId, setSelectedVersionId] = useState("");
  const [uploadFileVersionById, setUploadFileVersionById] = useState([]);
  const [listEditionByProduct, setListEditionByProduct] = useState([]);
  const [listVersionByEdition, setListVersionByEdition] = useState([]);
  const [listFileUpload, setListFileUpload] = useState([]);
  const [context, setContext] = useState("");
  const [showModalTitleTemplate, setShowModalTitleTemplate] = useState({
    id: "",
    context: null,
    openModal: false,
    title: "",
  });
  const listAllProduct = useSelector((state) => state?.product?.items);
  const isLoading = useSelector((state) => state?.sendMailIntro?.isLoading);
  const [listOptionEmailCustomer, setListOptionEmailCustomer] = useState([]);
  const accountDetail = useSelector(
    (state) => state?.accountDetail?.accountDetail
  );
  useEffect(() => {
    if (data && type === "forward") {
      form.setFieldsValue({
        subject: data?.subject,
        productId: data?.productId,
        editionId: data?.editionId,
        versionId: data?.versionId,
      });
      setListFileUpload(data?.urlAttachment);
      setSelectedProductId(data?.productId);
      setSelectedEditionId(data?.editionId);
      setContextTemplateMail(data?.context);
    } else {
      form.resetFields();
    }
  }, [data, form]);

  const finalUploadFileList = listFileUpload.concat(uploadFileVersionById);
  const getAllCustomer = async () => {
    try {
      let response = await Api.get(`/api/customer/getall`);
      const listOption = [];
      if (response.status === 200) {
        const dataRes = response?.data?.data;

        for (let i = 0; i < dataRes.length; i++) {
          listOption.push({
            label: `${dataRes[i]?.name} (${dataRes[i]?.email})`,
            value: dataRes[i]?.email,
          });
        }
        setListOptionEmailCustomer(listOption);
      }
    } catch (error) {}
  };
  useEffect(() => {
    dispatch(getAllProduct());
    getAllCustomer();
  }, [dispatch]);

  const getListAllTemplateMail = async () => {
    try {
      const { data } = await Api.get(`/api/templatemail/getall`);
      if (data?.success === true) {
        setListAllTemplateMail(data?.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getListAllTemplateMail();
  }, []);

  useEffect(async () => {
    if (selectedProductId) {
      const { data } = await Api.get(
        `/api/edition/product/${selectedProductId}`
      );
      if (data) {
        setListEditionByProduct(data?.data);
      }
    }
  }, [selectedProductId]);
  useEffect(async () => {
    if (selectedEditionId) {
      const { data } = await Api.get(
        `/api/version/edition/${selectedEditionId}`
      );
      if (data) {
        setListVersionByEdition(data?.data);
      }
    }
  }, [selectedEditionId]);

  useEffect(async () => {
    if (selectedVersionId) {
      const { data } = await Api.get(
        `api/version/getbyid/${selectedVersionId}`
      );
      if (data?.data?.urlFiles) {
        setUploadFileVersionById(data?.data?.urlFiles);
      } else {
        setUploadFileVersionById([]);
      }
    }
  }, [selectedVersionId]);

  useEffect(async () => {
    if (templateSendMailId && templateSendMailId !== "default") {
      const { data } = await Api.get(
        `api/templatemail/getbyid/${templateSendMailId}`
      );
      if (data?.success === true) {
        setContextTemplateMail(data?.data?.context);
        setTitleTemplateMail(data?.data?.title);
      }
    } else {
      setContextTemplateMail("");
      setTitleTemplateMail("");
    }
  }, [templateSendMailId]);

  const onFinish = async (value) => {
    const listReceiveMail = [];
    value?.receiveMail.map((e) => listReceiveMail.push(e.value));
    const data = {
      subject: value?.subject?.trim(),
      productId: value?.productId,
      editionId: value?.editionId,
      versionId: value?.versionId,
      receiveMail: listReceiveMail,
      urlAttachment: finalUploadFileList || [],
      context: context,
      createdBy: accountDetail?.id,
    };
    try {
      const resulDispatch = await dispatch(sendMailIntro(data));
      unwrapResult(resulDispatch);
      if (resulDispatch.payload.success === true) {
        notification.success({ message: `Gửi email thành công !!!` });
        handleCancel();
        form.resetFields();
        setListFileUpload([]);
        setUploadFileVersionById([]);
        setSelectedProductId("");
        setSelectedEditionId("");
        setSelectedVersionId("");
        setContext("");
        setContextTemplateMail("");
        setTitleTemplateMail("");
        setTemplateSendMailId("default");
      }
    } catch (error) {
      if (error.message === "Rejected") {
        notification.error({
          message: `Lỗi: Vui lòng kiểm tra lại kết nối mạng !`,
        });
      } else {
        notification.error({ message: `Lỗi: ${error.message}` });
      }
    }
  };

  const uploadFile = {
    name: "files",
    action: `${API_URL}/api/sendmail/uploads`,
    multiple: true,
    listType: "text",
    // beforeUpload: beforeUploadFile,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    onChange(info) {
      // let listUrls = [];
      // if (info.fileList.length > 0) {
      //   for (let i = 0; i < info.fileList.length; i++) {
      //     if (info.fileList[i].status && info.fileList[i].status === "done") {
      //       listUrls.push(info?.fileList[i]?.response?.data[0]);
      //     }
      //   }
      //   setListFileUpload(listUrls);
      // } else {
      //   setListFileUpload([]);
      // }
      let listUrls = [];
      if (info?.file?.status === "done") {
        if (info?.file?.response?.data[0]) {
          listUrls.push(info?.file?.response?.data[0]);
        }
      }
    },
    showUploadList: false,
  };
  const uploadFileEmail = {
    name: "file",
    action: `${API_URL}/api/sendmail/txt/uploads`,
    multiple: true,
    listType: "text",
    beforeUpload: beforeUploadFileEmailTxt,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    onChange(info) {
      if (info.file.status === "done") {
        const listReceiveMail = [];
        for (let i = 0; i < info.file.response.emails.length; i++) {
          listReceiveMail.push({
            value: info.file.response.emails[i],
            label: info.file.response.emails[i],
          });
        }
        form.setFieldsValue({
          receiveMail: listReceiveMail,
        });
        message.success(`Tải file email lên thành công !`);
      } else if (info.file.status === "error") {
        message.error(`Tải file thất bại !`);
      }
    },
    showUploadList: false,
  };

  const onChangeTemplateSendMailIntro = (e) => {
    if (e && e !== "default") {
      setTemplateSendMailId(e);
    } else {
      setTemplateSendMailId("default");
    }
  };
  const handleCancelModalTitle = async () => {
    await setShowModalTitleTemplate({
      id: "",
      openModal: false,
      context: null,
      title: "",
    });
    // setTemplateSendMailId("");
    setTitleTemplateMail("");
    getListAllTemplateMail();
  };
  const OnHandleSaveTemPlateMail = () => {
    if (templateSendMailId && context === "") {
      Modal.confirm({
        title: "Nội dung đang để trống, bạn có muốn xóa mẫu email này không !",
        okText: "Xóa",
        cancelText: "Hủy",
        // icon: <ExportOutlined />,
        onOk: async () => {
          const responseDelete = await Api.delete(
            `/api/templatemail/delete/${templateSendMailId}`
          );
          if (responseDelete?.data?.success === true) {
            notification.success({
              message: `Xóa thành công !!!`,
            });
            getListAllTemplateMail();
            setTitleTemplateMail("");
            setTemplateSendMailId("default");
          }
        },
        onCancel() {},
      });
    } else {
      setShowModalTitleTemplate({
        id: templateSendMailId,
        context: context,
        openModal: true,
        title: titleTemplateMail,
      });
    }
  };
  return (
    <>
      <ModalTemplateMail
        showModalTemplateMail={showModalTitleTemplate.openModal}
        context={showModalTitleTemplate.context}
        id={showModalTitleTemplate.id}
        title={showModalTitleTemplate.title}
        handleCancelModalTitle={handleCancelModalTitle}
        setTemplateSendMailId={setTemplateSendMailId}
      />
      <Modal
        confirmLoading={isLoading}
        title={type === "forward" ? "Chuyển tiếp" : "Soạn thư"}
        visible={showModal}
        onCancel={() => {
          handleCancel();
          form.resetFields();
          setListFileUpload([]);
          setUploadFileVersionById([]);
          setSelectedProductId("");
          setSelectedVersionId("");
          setSelectedEditionId("");
          setContextTemplateMail("");
          setContext("");
          setTitleTemplateMail("");
          setTemplateSendMailId("default");
        }}
        width={"90%"}
        centered
        destroyOnClose={true}
        footer={[
          <Button
            onClick={() => {
              handleCancel();
              form.resetFields();
              setListFileUpload([]);
              setUploadFileVersionById([]);
              setSelectedProductId("");
              setSelectedEditionId("");
              setSelectedVersionId("");
              setContextTemplateMail("");
              setContext("");
              setTitleTemplateMail("");
              setTemplateSendMailId("default");
            }}
          >
            Thoát
          </Button>,
          <Button
            className="min-w-[80px]"
            form={`composemail`}
            type="primary"
            htmlType="submit"
            disabled={isLoading}
            loading={isLoading}
          >
            Gửi
          </Button>,
        ]}
        closeIcon={
          <Tooltip title="Đóng">
            <CloseOutlined />
          </Tooltip>
        }
      >
        <ConfigProvider
          renderEmpty={() => (
            <div style={{ textAlign: "center", marginTop: "10px" }}>
              <InfoCircleOutlined style={{ fontSize: 30, color: "white" }} />
              <p className="text-white mt-1">Trống</p>
            </div>
          )}
        >
          <Form
            layout="vertical"
            onFinish={onFinish}
            form={form}
            id={`composemail`}
          >
            <div className="flex flex-wrap">
              <Form.Item
                name="subject"
                className="w-full md:w-1/2 px-3"
                label="Tiêu đề"
                rules={[
                  {
                    required: true,
                    message: "Hãy nhập tiêu đề",
                  },
                ]}
              >
                <Input
                  className="text-white custom-input-ant border w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300  focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                  placeholder="Tiêu đề"
                />
              </Form.Item>
              <div className="relative w-full md:w-1/2 px-3 flex">
                <Form.Item
                  name="receiveMail"
                  label="Người nhận"
                  className="w-full"
                  rules={[
                    {
                      required: true,
                      message: "Hãy chọn người nhận",
                    },
                  ]}
                >
                  <Creatable
                    formatCreateLabel={(inputText) => `Thêm mới "${inputText}"`}
                    isSearchable={true}
                    className="custom-react-select"
                    placeholder="Người nhận"
                    isMulti
                    options={listOptionEmailCustomer}
                  />
                </Form.Item>
                <Tooltip title="Upload file email txt">
                  <Upload {...uploadFileEmail}>
                    <Typography.Link
                      className="absolute top-0 right-4"
                      href="#API"
                    >
                      Upload file txt
                    </Typography.Link>
                  </Upload>
                </Tooltip>
              </div>
            </div>
            <div className="flex flex-wrap">
              <Form.Item
                name="productId"
                className="w-full md:w-1/2 px-3"
                label="Sản phẩm"
                rules={[
                  {
                    required: true,
                    message: "Hãy chọn sản phẩm",
                  },
                ]}
              >
                <Select
                  className="ant-select-custom border w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300  focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                  showSearch
                  allowClear
                  placeholder="Chọn sản phẩm"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(values) => setSelectedProductId(values)}
                >
                  {listAllProduct?.map((item) => {
                    return (
                      <Option key={item.id} value={item.id}>
                        {item.title}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                name="editionId"
                className="w-full md:w-1/2 px-3"
                label="Edition"
              >
                <Select
                  className="ant-select-custom border w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300  focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                  showSearch
                  allowClear
                  placeholder={
                    selectedProductId ? "Chọn edition" : "Chọn sản phẩm trước"
                  }
                  disabled={selectedProductId ? false : true}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(values) => setSelectedEditionId(values)}
                >
                  {listEditionByProduct?.map((item) => {
                    return (
                      <Option key={item.id} value={item.id}>
                        {item.title}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
            <div className="flex flex-wrap">
              <Form.Item
                name="versionId"
                className="w-full md:w-1/2 px-3"
                label="Version"
              >
                <Select
                  className="ant-select-custom border w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300  focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                  showSearch
                  allowClear
                  placeholder={
                    selectedEditionId ? "Chọn version" : "Chọn edition trước"
                  }
                  disabled={selectedEditionId ? false : true}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(values) => setSelectedVersionId(values)}
                >
                  {listVersionByEdition?.map((item) => {
                    return (
                      <Option key={item.id} value={item.id}>
                        {item.title}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
            <div className="flex flex-wrap">
              <Form.Item
                name="urlAttachment"
                className="w-full md:w-1/2 px-3"
                label="Tệp đính kèm"
              >
                <Upload {...uploadFile}>
                  <Button>
                    <UploadOutlined /> Tải tệp đính kèm
                  </Button>
                </Upload>
              </Form.Item>
            </div>
            <div className="flex flex-wrap max-h-[200px] overflow-x-auto">
              <div className="uploaded-file-list">
                {listFileUpload &&
                  listFileUpload.map((item, index) => {
                    return (
                      <div className="uploaded-file-list-item mx-3" key={index}>
                        {item.split(".")[item.split(".").length - 1] ===
                          "jpg" ||
                        item.split(".")[item.split(".").length - 1] ===
                          "png" ? (
                          <Image
                            preview={false}
                            style={{
                              height: 40,
                              width: 40,
                              objectFit: "cover",
                            }}
                            src={`${API_URL}/${item}`}
                          />
                        ) : (
                          <i className="fas fa-file-archive fa-3x text-white"></i>
                        )}
                        <div className="uploaded-file-list-item-title">
                          <a
                            target="_blank"
                            href={`${API_URL}/${item}`}
                            rel="noreferrer"
                            className="text-gray-300"
                          >
                            {item?.split("/")[2]?.split(".")[0]}
                          </a>
                        </div>
                        <Button
                          style={{ height: "40px" }}
                          onClick={() =>
                            setListFileUpload(
                              listFileUpload.filter((e) => e !== item)
                            )
                          }
                        >
                          <DeleteOutlined className="text-lg" />
                        </Button>
                      </div>
                    );
                  })}
              </div>
              <div className="uploaded-file-list">
                {uploadFileVersionById &&
                  uploadFileVersionById.map((item, index) => {
                    return (
                      <div className="uploaded-file-list-item mx-3" key={index}>
                        {item.split(".")[item.split(".").length - 1] ===
                          "jpg" ||
                        item.split(".")[item.split(".").length - 1] ===
                          "png" ? (
                          <Image
                            preview={false}
                            style={{
                              height: 40,
                              width: 40,
                              objectFit: "cover",
                            }}
                            src={`${API_URL}/${item}`}
                          />
                        ) : (
                          <i className="fas fa-file-archive fa-3x text-white"></i>
                        )}
                        <div className="uploaded-file-list-item-title">
                          <a
                            target="_blank"
                            href={`${API_URL}/${item}`}
                            rel="noreferrer"
                            className="text-gray-300"
                          >
                            {item?.split("/")[2]?.split(".")[0]}
                          </a>
                        </div>
                        <Button
                          style={{ height: "40px" }}
                          onClick={() =>
                            setUploadFileVersionById(
                              uploadFileVersionById.filter((e) => e !== item)
                            )
                          }
                        >
                          <DeleteOutlined />
                        </Button>
                      </div>
                    );
                  })}
              </div>
            </div>

            <div className="flex flex-wrap relative">
              <Form.Item
                name="context"
                className="w-full px-3"
                label="Nội dung"
                rules={[
                  {
                    required: true,
                    message: "Hãy nhập nội dung thư",
                  },
                ]}
              >
                <CKEditor
                  editor={Editor}
                  data={contextTemplateSendMail}
                  config={EDITORCONFIGURATION}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setContext(data);
                  }}
                />
              </Form.Item>
              <div className="absolute top-[31px] right-4 h-[37px] cursor-pointer">
                <Select
                  placeholder="Chọn mẫu"
                  className="ant-select-custom2 border-l border-r h-full w-[200px]"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  value={templateSendMailId}
                  onChange={onChangeTemplateSendMailIntro}
                >
                  <Option value="default">Mặc định</Option>
                  {listAllTemplateMail?.map((item) => {
                    return (
                      <Option key={item.id} value={item.id}>
                        {item.title}
                      </Option>
                    );
                  })}
                </Select>
                <Button
                  onClick={OnHandleSaveTemPlateMail}
                  style={{
                    height: "39px",
                    marginLeft: "7px",
                    borderRight: "1px",
                    opacity: 0.7,
                  }}
                  disabled={
                    !context && templateSendMailId === "default" ? true : false
                  }
                  type="primary"
                >
                  Lưu mẫu
                </Button>
              </div>
            </div>
          </Form>
        </ConfigProvider>
      </Modal>
    </>
  );
};

export default ModalComposeMail;
