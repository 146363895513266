import React, { useState, useCallback, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ButtonSubmit from "../../../components/Button/ButtonSubmit";
import InputSearch from "../../../components/InputSearch/InputSearch";
import { unwrapResult } from "@reduxjs/toolkit";
import { deleteOrganization } from "../OrganizationSlice";
import ROUTER from "../../../routers/routerLink";
import { debounce } from "lodash";
import { getOrganization } from "./../OrganizationSlice";
import { notification, Button, Form } from "antd";
import TableAdmin from "./../../../components/Table/TableAdmin";
import Api from "../../../Utils/Api";
import OrganizationTree from "./OrganizationTree";
import queryString from "query-string";

const OrganizationList = (props) => {
  const [valueSearch, setValueSearch] = useState("");
  const search = useLocation().search;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const listOrganization = useSelector((state) => state?.organization?.items);
  const meta = useSelector((state) => state?.organization?.meta);
  const isLoading = useSelector((state) => state?.organization?.isLoading);
  const [dataModal, setDataModal] = useState({
    openModal: false,
    idOrganization: "",
  });

  useEffect(() => {
    if (search) {
      setValueSearch(queryString.parse(search)["keyword"]);
    } else {
      setValueSearch("");
    }
  }, [search]);

  useEffect(() => {
    const { search } = props.history.location;
    let params;
    const filterOptions = search.split("?")[1];
    if (filterOptions !== undefined) {
      params = `{"${decodeURI(filterOptions)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')}"}`;
      dispatch(getOrganization(JSON.parse(params)));
    } else {
      dispatch(getOrganization(null));
    }
  }, [dispatch, props.history.location]);

  const columns = [
    { dataIndex: "title", title: "Tên tổ chức", sorting: true, type: "left" },
    {
      title: "Phòng ban",
      sorting: true,
      render: (value) => (
        <Button onClick={() => showModalTree(value)}>Chi tiết</Button>
      ),
    },
    // {
    //   dataIndex: "note",
    //   title: "Ghi chú",
    //   type: "left",
    //   render: (value) =>
    //     value?.length >= 20 ? (
    //       <Popover
    //         content={
    //           <>
    //             <div className="max-w-[250px] break-words">
    //               <h1 className="border-b-2 text-xl font-semibold">Ghi chú </h1>
    //               <p> {value}</p>
    //             </div>
    //           </>
    //         }
    //       >
    //         <span className="font-medium">
    //           {value.substring(0, 20) + " ..."}
    //         </span>
    //       </Popover>
    //     ) : (
    //       <span className="font-medium">{value}</span>
    //     ),
    // },
  ];
  const showModalTree = async (value) => {
    try {
      let response = await Api.get(
        `api/organization/${value?.id}/getdepartment`
      );
      if (response.status === 200) {
        setDataModal({
          openModal: true,
          idOrganization: value?.id,
        });
      }
    } catch (error) {}
  };
  const handleCancel = async () => {
    await setDataModal({
      openModal: false,
      idOrganization: null,
    });
    dispatch(getOrganization(null));
  };
  const onDelete = async (id) => {
    try {
      const resultAction = await dispatch(deleteOrganization(id));
      unwrapResult(resultAction);
      notification.success({ message: `Xoá thành công !` });
    } catch (error) {
      if (error.message === "Rejected") {
        notification.error({
          message: `Lỗi: Vui lòng kiểm tra lại kết nối mạng !`,
        });
      } else {
        notification.error({ message: `Lỗi: ${error.message}` });
      }
    }
  };
  const fecthSearch = (value) => {
    if (value) {
      props.history.push(`${ROUTER.ORGANIZATION.LIST}?keyword=${value}`);
    } else {
      props.history.push(ROUTER.ORGANIZATION.LIST);
      dispatch(getOrganization(null));
    }
  };
  const debounceSearch = useCallback(debounce(fecthSearch, 1000), []);
  const onSearch = (e) => {
    setValueSearch(
      e.target.value.replace(/[~`!#$%^&*()_={}[\]:;,<>+\\/|?-]/g, "")
    );
    debounceSearch(
      e.target.value.replace(/[~`!#$%^&*()_={}[\]:;,<>+\\/|?-]/g, "")
    );
  };

  const onChangePage = (page, size) => {
    const { search } = props.history.location;
    if (search !== "") {
      let params;
      const filterOptions = search.split("?")[1];
      if (filterOptions !== undefined) {
        params = `{"${decodeURI(filterOptions)
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"')}"}`;
      }
      const obj = JSON.parse(params);
      params = { ...obj, page, size };
      const urlParameters = [];
      Object.entries(params).forEach((e) => {
        if (e[1] !== undefined && e[1] !== "") {
          urlParameters.push(e.join("="));
        }
      });
      props.history.push(
        ROUTER.ORGANIZATION.LIST.concat(`?${urlParameters.join("&")}`)
      );
    } else {
      props.history.push(
        ROUTER.ORGANIZATION.LIST.concat(`?page=${page}&size=${size}`)
      );
    }
  };

  return (
    <>
      <OrganizationTree
        openModal={dataModal.openModal}
        type={dataModal.type}
        idOrganization={dataModal.idOrganization}
        handleCancel={handleCancel}
      />
      <div className="overflow-x-auto mb-10 mt-4">
        <div className="flex justify-center  mb-3 bg-[#262626] px-4 py-8 rounded-[10px]">
          <div className="w-full">
            <div className="w-full  mb-3 md:flex md:justify-between  h-[40px]">
              <div>
                <NavLink to={ROUTER.ORGANIZATION.ADD}>
                  <ButtonSubmit
                    iconButton={<i className="fas fa-plus pr-[15px]"></i>}
                    titleButton="Thêm mới"
                  />
                </NavLink>
              </div>
              <Form form={form}>
                <div className="md:flex md:justify-end ">
                  <Form.Item name="searchName">
                    <InputSearch
                      onChange={onSearch}
                      valueSearch={valueSearch}
                    />
                  </Form.Item>
                </div>
              </Form>
            </div>
            <div>
              <TableAdmin
                Url={ROUTER.ORGANIZATION.EDIT}
                onDelete={onDelete}
                columns={columns}
                spinning={isLoading}
                dataTable={listOrganization}
                meta={meta}
                onChangePage={onChangePage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrganizationList;
