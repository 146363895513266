import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { Tree, TreeNode } from "react-organizational-chart";
import { Button, Tooltip, Popconfirm, notification } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import DepartmentAddModal from "./DepartmentAddModal";
import Api from "../../../Utils/Api";
import { useDispatch } from "react-redux";
import { deleteDepartment } from "../../Department/DeparmentSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { CloseOutlined } from "@ant-design/icons";

const OrganizationTree = ({ handleCancel, openModal, idOrganization }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [modalAddDepartment, setModalAddDepartment] = useState({
    type: "",
    organizationId: null,
    openModal: false,
    dataEdit: {},
  });
  const getTreeOrganization = async (id) => {
    try {
      let response = await Api.get(`api/organization/${id}/getdepartment`);
      if (response.status === 200) {
        setData(response?.data?.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (idOrganization) {
      getTreeOrganization(idOrganization);
    }
  }, [idOrganization]);
  const onDeleteDepartment = async (id) => {
    try {
      const resultAction = await dispatch(deleteDepartment(id));
      unwrapResult(resultAction);
      notification.success({ message: `Xoá thành công!` });
      await getTreeOrganization(idOrganization);
    } catch (error) {
      if (error.message === "Rejected") {
        notification.error({
          message: `Lỗi: Vui lòng kiểm tra lại kết nối mạng !`,
        });
      } else {
        notification.error({ message: `Lỗi: ${error.message}` });
      }
    }
  };
  const RenderOrganization = ({ dataOrganization }) => {
    return (
      <div className="border-[1px] border-gray-400 rounded-lg p-3 inline-block ">
        <div className="flex justify-center">
          <i className="fas fa-sitemap fa-2x text-blue-400"></i>
          <div className=" text-white text-2xl font-normal ml-3">
            <span> {dataOrganization?.title}</span>
          </div>
        </div>
      </div>
    );
  };

  const ParentDepartment = ({ dataDepartment }) => {
    return (
      <div className="list-department relative border-[1px] border-gray-400  rounded-lg  inline-block p-3 min-w-[70px]">
        <div className="show-icon-department z-50">
          <Tooltip
            title="Chỉnh sửa"
            onClick={() =>
              setModalAddDepartment({
                type: "update",
                openModal: true,
                organizationId: dataDepartment?.organizationId,
                dataEdit: dataDepartment,
              })
            }
          >
            <i className="fas fa-edit opacity-75  text-gray-500  rounded-full pr-2 cursor-pointer"></i>
          </Tooltip>
          <Popconfirm
            title="Bạn có chắc chắn muốn xóa không ?"
            onConfirm={() => onDeleteDepartment(dataDepartment?.id)}
          >
            <Tooltip title="Xóa">
              <i className="fas fa-times opacity-75 text-gray-500   rounded-full cursor-pointer"></i>
            </Tooltip>
          </Popconfirm>
        </div>
        <div className="flex justify-center mt-2">
          <i className="fas fa-building text-blue-400"></i>
          <div className=" text-white text-xs font-normal ml-2">
            <span> {dataDepartment?.title}</span>
          </div>
        </div>
      </div>
    );
  };
  // const ParentCustomer = ({ dataCustomer }) => {
  //   const content = (
  //     <div>
  //       <div>
  //         <span>
  //           <i className="fas fa-phone"></i> :
  //           {dataCustomer?.phone ? dataCustomer?.phone : "Chưa có"}
  //         </span>
  //       </div>
  //       <div className="pt-[5px]">
  //         <span>
  //           <i className="fas fa-envelope"></i> :
  //           {dataCustomer?.email ? dataCustomer?.email : "Chưa có"}
  //         </span>
  //       </div>
  //       <div className="pt-[5px]">
  //         <span>
  //           <i className="far fa-gem"></i> :{dataCustomer?.priorityTitle}
  //         </span>
  //       </div>
  //     </div>
  //   );
  //   return (
  //     <Popover content={content} title="Thông tin">
  //       <div className="border-[1px] border-gray-400 rounded-lg p-3 inline-block ">
  //         <div className="flex justify-center">
  //           <i className="fas fa-user-alt text-blue-400"></i>
  //           <div className=" text-white text-sm font-normal ml-2">
  //             <span>{dataCustomer?.name}</span>
  //           </div>
  //         </div>
  //       </div>
  //     </Popover>
  //   );
  // };

  const handleCancelDepartment = async () => {
    await setModalAddDepartment({
      organizationId: "",
      openModal: false,
      type: "",
      dataEdit: null,
    });
    await getTreeOrganization(idOrganization);
  };
  return (
    <>
      <Modal
        title="Sơ đồ tổ chức"
        visible={openModal}
        onOk={handleCancel}
        onCancel={handleCancel}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: false }}
        cancelText="Thoát"
        width={"95%"}
        // className="modal-800"
        centered
        destroyOnClose={true}
        className="overflow-x-hidden"
        closeIcon={
          <Tooltip title="Đóng">
            <CloseOutlined />
          </Tooltip>
        }
      >
        <Button
          type="primary"
          onClick={() =>
            setModalAddDepartment({
              openModal: true,
              type: "create",
              organizationId: data?.id,
              dataEdit: null,
            })
          }
        >
          <div className="flex items-center">
            <PlusOutlined className="pr-2" /> Thêm Phòng Ban
          </div>
        </Button>
        <DepartmentAddModal
          organizationId={modalAddDepartment.organizationId}
          type={modalAddDepartment.type}
          dataEdit={modalAddDepartment.dataEdit}
          modalAddDepartment={modalAddDepartment.openModal}
          handleCancelDepartment={handleCancelDepartment}
        />
        <Tree
          lineWidth={"2px"}
          lineColor={"#bbc"}
          lineBorderRadius={"12px"}
          label={<RenderOrganization dataOrganization={data} />}
        >
          {data?.childen &&
            data?.childen.map((item, index) => {
              return (
                <>
                  <TreeNode
                    className="relative h-full"
                    key={index}
                    label={<ParentDepartment dataDepartment={item} />}
                  >
                    {/* {item.childen
                      ? item.childen.map((item2, index2) => {
                          return (
                            <TreeNode
                              key={index2}
                              label={<ParentCustomer dataCustomer={item2} />}
                            ></TreeNode>
                          );
                        })
                      : null} */}
                  </TreeNode>
                </>
              );
            })}
        </Tree>
      </Modal>
    </>
  );
};

export default OrganizationTree;
