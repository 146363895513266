import React, { useState, useCallback, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ButtonSubmit from "../../../components/Button/ButtonSubmit";
import { FilterOutlined } from "@ant-design/icons";
import LicenseKeyFilter from "../Components/LicenseKeyFilter";
import InputSearch from "../../../components/InputSearch/InputSearch";
import ROUTER from "../../../routers/routerLink";
import {
  getLicenseKey,
  deleteLicenseKey,
  setFilters,
  removeFilters,
} from "./../LicenseKeySlice";
import { notification, Tooltip, Form } from "antd";
import { unwrapResult } from "@reduxjs/toolkit";
import { debounce } from "lodash";
import TableAdmin from "./../../../components/Table/TableAdmin";
import RenderStatusExpiry from "./../../../components/RenderStatusExpiry/RenderStatusExpiry";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";

const LicenseKeyList = (props) => {
  const [valueSearch, setValueSearch] = useState("");
  const history = useHistory();
  const search = useLocation().search;
  const [form] = Form.useForm();
  const [showFilter, setShowFilter] = useState(false);
  const dispatch = useDispatch();
  const listLicenseKey = useSelector((state) => state?.licenseKey?.items);
  const meta = useSelector((state) => state?.licenseKey?.meta);
  const filters = useSelector((state) => state?.licenseKey?.filters);
  const isLoading = useSelector((state) => state?.licenseKey?.isLoading);
  // useEffect(() => {
  //   const { search } = props.history.location;
  //   let params;
  //   const filterOptions = search.split("?")[1];
  //   if (filterOptions !== undefined) {
  //     params = `{"${decodeURI(filterOptions)
  //       .replace(/"/g, '\\"')
  //       .replace(/&/g, '","')
  //       .replace(/=/g, '":"')}"}`;
  //     dispatch(getLicenseKey(JSON.parse(params)));
  //   } else {
  //     dispatch(getLicenseKey(null));
  //   }
  // }, [dispatch, props.history.location]);

  useEffect(() => {
    if (filters) {
      setValueSearch(filters["keyword"]);
    } else {
      setValueSearch("");
    }
  }, [filters, form]);

  useEffect(() => {
    search && dispatch(setFilters(queryString.parse(search)));
  }, [dispatch, search]);
  useEffect(() => {
    return () => {
      dispatch(removeFilters());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(getLicenseKey(queryString.parse(search)));
  }, [dispatch, search]);

  useEffect(() => {
    history.push(
      `${ROUTER.LICENSEKEY.LIST}?${queryString.stringify(filters, {
        skipEmptyString: true,
      })}`
    );
  }, [history, filters]);
  const columns = [
    // {
    //   dataIndex: "macAddress",
    //   title: "MAC",
    //   sorting: true,
    //   type: "center",
    //   render: (value) => (
    //     <span className="font-medium">{value !== null ? value : "N/A"}</span>
    //   ),
    // },
    {
      dataIndex: "customerName",
      title: "Khách Hàng",
      sorting: true,
      type: "left",
      render: (value) => (
        <span className="font-medium capitalize">{value}</span>
      ),
    },
    {
      dataIndex: "email",
      title: "Tài khoản",
      sorting: true,
      type: "left",
      render: (value) => (
        <span className="font-medium">{value ? value : "N/A"}</span>
      ),
    },
    {
      dataIndex: "productName",
      title: "Sản phẩm",
      sorting: true,
      type: "left",
    },
    {
      dataIndex: "versionName",
      title: "Version",
      sorting: true,
      type: "center",
      render: (value) => (
        <span className="font-medium">{value ? value : "N/A"}</span>
      ),
    },
    {
      dataIndex: "licenseType",
      title: "Loại",
      sorting: true,
      type: "center",
      render: (value) => (
        <span className="font-medium">
          {value === 1
            ? "Online"
            : value === 2
            ? "Offline"
            : value === 3
            ? "S/p Ncc khác"
            : "N/A"}
        </span>
      ),
    },
    {
      dataIndex: "licenseKeyCategoryName",
      title: "Danh mục",
      sorting: true,
      type: "center",
      render: (value) => (
        <span className="font-medium">{value ? value : "N/A"}</span>
      ),
    },
    {
      dataIndex: "startDate",
      title: "Ngày kích hoạt",
      sorting: true,
      type: "center",
      render: (value) => (
        <span className="font-medium capitalize">
          {moment(value).format("DD-MM-YYYY")}
        </span>
      ),
    },
    {
      dataIndex: "expirationDate",
      title: "Ngày hết hạn",
      sorting: true,
      type: "center",
      render: (value) => (
        <span className="font-medium capitalize">
          {moment(value).format("DD-MM-YYYY")}
        </span>
      ),
    },
    {
      title: "Trạng thái",
      sorting: true,
      type: "center",
      render: (value) => RenderStatusExpiry(value?.expirationDate),
    },
    // {
    //   dataIndex: "note",
    //   title: "Ghi chú",
    //   sorting: true,
    //   type: "left",
    //   render: (value) =>
    //     value.length >= 20 ? (
    //       <Popover
    //         content={
    //           <>
    //             <div className="max-w-[250px] break-words">
    //               <h1 className="border-b-2 text-xl font-semibold">Ghi chú </h1>
    //               <p> {value}</p>
    //             </div>
    //           </>
    //         }
    //       >
    //         <span className="font-medium">
    //           {value.substring(0, 20) + " ..."}
    //         </span>
    //       </Popover>
    //     ) : (
    //       <span className="font-medium">{value}</span>
    //     ),
    // },
  ];
  const onDelete = async (id) => {
    try {
      const resultAction = await dispatch(deleteLicenseKey(id));
      unwrapResult(resultAction);
      notification.success({ message: `Xoá thành công!` });
    } catch (error) {
      if (error.message === "Rejected") {
        notification.error({
          message: `Lỗi: Vui lòng kiểm tra lại kết nối mạng !`,
        });
      } else {
        notification.error({ message: `Lỗi: ${error.message}` });
      }
    }
  };
  const fecthSearch = (value) => {
    if (value) {
      dispatch(setFilters({ keyword: value, page: "", size: "" }));
    } else {
      dispatch(setFilters({ keyword: "" }));
    }
  };
  const debounceSearch = useCallback(debounce(fecthSearch, 1000), []);
  const onSearch = (e) => {
    setValueSearch(
      e.target.value.replace(/[~`!#$%^&*()_={}[\]:;,<>+\\/|?-]/g, "")
    );
    debounceSearch(
      e.target.value.replace(/[~`!#$%^&*()_={}[\]:;,<>+\\/|?-]/g, "")
    );
  };
  const onChangePage = (page, size) => {
    // const { search } = props.history.location;
    // if (search !== "") {
    //   let params;
    //   const filterOptions = search.split("?")[1];
    //   if (filterOptions !== undefined) {
    //     params = `{"${decodeURI(filterOptions)
    //       .replace(/"/g, '\\"')
    //       .replace(/&/g, '","')
    //       .replace(/=/g, '":"')}"}`;
    //   }
    //   const obj = JSON.parse(params);
    //   params = { ...obj, page, size };
    //   const urlParameters = [];
    //   Object.entries(params).forEach((e) => {
    //     if (e[1] !== undefined && e[1] !== "") {
    //       urlParameters.push(e.join("="));
    //     }
    //   });
    //   props.history.push(
    //     ROUTER.LICENSEKEY.LIST.concat(`?${urlParameters.join("&")}`)
    //   );
    // } else {
    //   props.history.push(
    //     ROUTER.LICENSEKEY.LIST.concat(`?page=${page}&size=${size}`)
    //   );
    // }
    dispatch(setFilters({ page: page, size: size }));
  };
  return (
    <>
      <div className="overflow-x-auto mb-10 mt-4">
        <div className="flex justify-center mb-3 bg-[#262626] px-4 py-8 rounded-[10px]">
          <div className="w-full">
            <div className="w-full md:flex md:justify-between h-[40px] mb-3">
              <div>
                <NavLink to={ROUTER.LICENSEKEY.ADD}>
                  <ButtonSubmit
                    iconButton={<i className="fas fa-plus pr-[15px]"></i>}
                    titleButton="Thêm mới"
                  />
                </NavLink>
              </div>
              <Form form={form}>
                <div className="md:flex md:justify-end">
                  <Form.Item name="searchName">
                    <InputSearch
                      placeholder="Tìm kiếm theo email..."
                      onChange={onSearch}
                      valueSearch={valueSearch}
                    />
                  </Form.Item>
                  <div className="ml-2 h-[40px]">
                    <Tooltip title="Lọc dữ liệu">
                      <button
                        onClick={() => setShowFilter(true)}
                        className="border-[1px] h-full w-[45px] mr-[2px] rounded-md shadow-md bg-[#262626] hover:ring-2 hover:ring-purple-300 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50"
                      >
                        <FilterOutlined className="text-[20px] text-white font-semibold" />
                      </button>
                    </Tooltip>
                    <LicenseKeyFilter
                      openModal={showFilter}
                      handleCancel={() => setShowFilter(false)}
                      style={{ fontSize: "20px" }}
                    />
                  </div>
                </div>
              </Form>
            </div>
            <div className="">
              <TableAdmin
                downloadTxt
                Url={ROUTER.LICENSEKEY.EDIT}
                onDelete={onDelete}
                columns={columns}
                spinning={isLoading}
                dataTable={listLicenseKey}
                meta={meta}
                onChangePage={onChangePage}
                // handlePagination={(v) =>
                //   onChangePage(v?.currentPage, v?.itemsPerPage)
                // }
                // totalItems={meta?.total}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LicenseKeyList;
