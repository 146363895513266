export default {
  HOME: {
    HOME: "/",
  },
  AUTH: {
    LOGIN: "/login",
    // REGISTER: "/register",
    CHANGEPASSWORD: "/change-password",
    AUTHDETAIL: "/accountdetail",
    FORGOTPASSWORD: "/forgotpassword",
  },

  //account
  ACCOUNT: {
    LIST: "/account",
    ADD: "/account/add",
    EDIT: "/account/edit",
  },
  ROLE: {
    LIST: "/role",
    ADD: "/role/add",
    EDIT: "/role/edit",
  },

  //customer
  CUSTOMER: {
    LIST: "/customer",
    ADD: "/customer/add",
    EDIT: "/customer/edit",
  },
  ORGANIZATION: {
    LIST: "/organization",
    ADD: "/organization/add",
    EDIT: "/organization/edit",
  },
  DEPARTMENT: {
    LIST: "/department",
    ADD: "/department/add",
    EDIT: "/department/edit",
  },
  PRIORITY: {
    LIST: "/priority",
    ADD: "/priority/add",
    EDIT: "/priority/edit",
  },

  ERROR: {
    NOTFOUND: "/notfound",
    SERVERERROR: "/servererror",
  },

  //licenseKey
  LICENSEKEY: {
    LIST: "/license-key",
    ADD: "/license-key/add",
    EDIT: "/license-key/edit",
  },
  LICENSEKEY_CATEGORY_CHART: {
    LIST: "/license-key-category_chart",
  },
  LICENSEKEYINCHART: {
    LIST: "/license-key-in-chart",
  },
  LICENSEKEY_CATEGORY: {
    LIST: "/license-key-category",
    ADD: "/license-key-category/add",
    EDIT: "/license-key-category/edit",
  },
  LICENSEKEY_HISTORY_SEND_MAIL_EXPIRES: {
    LICENSEKEY_HISTORY_SEND_MAIL_EXPIRES: "/history-send-mail-expires",
  },
  MAILACTIVE:{
    LIST:"/list-mail-active",
  },
  LICENSEKEY_EXPIRATION_DATE: {
    LIST: "/license-key-expiration-date",
  },
  LICENSEKEY_SEND_MAIL_EXPIRATIONDATE: {
    LICENSEKEY_SEND_MAIL_EXPIRATIONDATE:
      "/license-key-send-mail-expirationdate",
  },
  LICENSEKEY_SEND_MAIL_INTRO: {
    LICENSEKEY_SEND_MAIL_INTRO: "/license-key-send-mail-intro",
  },
  //product
  PRODUCT: {
    LIST: "/product",
    ADD: "/product/add",
    EDIT: "/product/edit",
    EDITION: {
      LIST: "/product/edition",
      ADD: "/product/edition/add",
      EDIT: "/product/edition/edit",
      VERSION: {
        LIST: "/product/edition/version",
        ADD: "/product/edition/version/add",
        EDIT: "/product/edition/version/edit",
      },
    },
  },
  VERSION: {
    LIST: "/version",
    ADD: "/version/add",
    EDIT: "/version/edit",
  },
  GROUPCUSTOMER: {
    LIST: "/groupcustomer",
  },
};
