import React from "react";
import ROUTER from "../../routers/routerLink";

export const nav1 = [
  {
    _tag: "CSidebarNavItem",
    name: "Trang chủ",
    to: ROUTER.HOME.HOME,
    icon: <i className="fas fa-home pr-[30px]"></i>,
  },

  {
    _tag: "CSidebarNavTitle",
    _children: ["Danh mục"],
  },
  {
    _tag: "CSidebarNavItem",
    name: "Sản Phẩm",
    to: ROUTER.PRODUCT.LIST,
    icon: (
      <i className="fas fa-copy pr-[30px]" style={{ maxWidth: "40px" }}></i>
    ),
  },
  {
    _tag: "CSidebarNavItem",
    name: "LicenseKey",
    to: ROUTER.LICENSEKEY.LIST,
    icon: <i className="fas fa-key pr-[30px]" style={{ maxWidth: "40px" }}></i>,
  },
  {
    _tag: "CSidebarNavTitle",
    _children: ["Gửi Email"],
  },
  {
    _tag: "CSidebarNavItem",
    name: "Nhóm khách hàng gửi Email",
    to: ROUTER.GROUPCUSTOMER.LIST,
    icon: (
      <i
        className="fas fa-users-cog pr-[30px]"
        style={{ maxWidth: "40px" }}
      ></i>
    ),
  },
  {
    _tag: "CSidebarNavItem",
    name: "Lịch sử gửi email hết hạn",
    to: "/history-send-mail-expires",
    icon: (
      <i className="fas fa-history pr-[30px]" style={{ maxWidth: "40px" }}></i>
    ),
  },
  ,
  {
    _tag: "CSidebarNavItem",
    name: "Email chờ kích hoạt",
    to: ROUTER.MAILACTIVE.LIST,
    icon: (
      <i className="fas fa-history pr-[30px]" style={{ maxWidth: "40px" }}></i>
    ),
  },
  // {
  //   _tag: "CSidebarNavItem",
  //   name: "Gửi email giới thiệu",
  //   to: ROUTER.LICENSEKEY_SEND_MAIL_INTRO.LICENSEKEY_SEND_MAIL_INTRO,
  //   icon: (
  //     <i className="fas fa-clock pr-[30px]" style={{ maxWidth: "40px" }}></i>
  //   ),
  // },

  {
    _tag: "CSidebarNavTitle",
    _children: ["Quản lý khách hàng"],
  },
  {
    _tag: "CSidebarNavItem",
    name: "Khách hàng",
    to: ROUTER.CUSTOMER.LIST,
    icon: (
      <i className="fas fa-users pr-[30px]" style={{ maxWidth: "40px" }}></i>
    ),
  },
  // {
  //   _tag: "CSidebarNavItem",
  //   name: "Phòng Ban",
  //   to: "/department",
  //   icon: (
  //     <i className="fas fa-building pr-[30px]" style={{ maxWidth: "40px" }}></i>
  //   ),
  // },
  {
    _tag: "CSidebarNavItem",
    name: "Tổ chức",
    to: ROUTER.ORGANIZATION.LIST,
    icon: (
      <i className="fas fa-sitemap pr-[30px]" style={{ maxWidth: "40px" }}></i>
    ),
  },
  // {
  //   _tag: "CSidebarNavItem",
  //   name: "Ưu tiên",
  //   to: "/priority",
  //   icon: <i className="far fa-gem pr-[30px]" style={{ maxWidth: "40px" }}></i>,
  // },

  {
    _tag: "CSidebarNavTitle",
    _children: ["Quản lý tài khoản"],
  },
  {
    _tag: "CSidebarNavItem",
    name: "Tài Khoản",
    to: ROUTER.ACCOUNT.LIST,
    icon: (
      <i
        className="fas fa-user-alt max-w-1/3 pr-[30px]"
        style={{ maxWidth: "40px" }}
      ></i>
    ),
  },
];

export const nav2 = [
  {
    _tag: "CSidebarNavItem",
    name: "Dashboard",
    to: ROUTER.HOME.HOME,
    icon: <i className="fas fa-home pr-[30px]"></i>,
  },
  {
    _tag: "CSidebarNavTitle",
    _children: ["Danh mục"],
  },
  {
    _tag: "CSidebarNavItem",
    name: "Sản Phẩm",
    to: ROUTER.PRODUCT.LIST,
    icon: (
      <i className="fas fa-copy pr-[30px]" style={{ maxWidth: "40px" }}></i>
    ),
  },
  {
    _tag: "CSidebarNavItem",
    name: "LicenseKey",
    to: ROUTER.LICENSEKEY.LIST,
    icon: <i className="fas fa-key pr-[30px]" style={{ maxWidth: "40px" }}></i>,
  },
  {
    _tag: "CSidebarNavTitle",
    _children: ["Gửi Email"],
  },
  {
    _tag: "CSidebarNavItem",
    name: "Nhóm khách hàng gửi Email",
    to: ROUTER.GROUPCUSTOMER.LIST,
    icon: (
      <i
        className="fas fa-users-cog pr-[30px]"
        style={{ maxWidth: "40px" }}
      ></i>
    ),
  },
  {
    _tag: "CSidebarNavItem",
    name: "Lịch sử gửi email hết hạn",
    to: ROUTER.LICENSEKEY_HISTORY_SEND_MAIL_EXPIRES
      .LICENSEKEY_HISTORY_SEND_MAIL_EXPIRES,
    icon: (
      <i className="fas fa-history pr-[30px]" style={{ maxWidth: "40px" }}></i>
    ),
  },
  // {
  //   _tag: "CSidebarNavItem",
  //   name: "Gửi email giới thiệu",
  //   to: ROUTER.LICENSEKEY_SEND_MAIL_INTRO.LICENSEKEY_SEND_MAIL_INTRO,
  //   icon: (
  //     <i className="fas fa-clock pr-[30px]" style={{ maxWidth: "40px" }}></i>
  //   ),
  // },
  {
    _tag: "CSidebarNavTitle",
    _children: ["Quản lý khách hàng"],
  },
  {
    _tag: "CSidebarNavItem",
    name: "Khách hàng",
    to: ROUTER.CUSTOMER.LIST,
    icon: (
      <i className="fas fa-users pr-[30px]" style={{ maxWidth: "40px" }}></i>
    ),
  },
  // {
  //   _tag: "CSidebarNavItem",
  //   name: "Phòng Ban",
  //   to: "/department",
  //   icon: (
  //     <i className="fas fa-building pr-[30px]" style={{ maxWidth: "40px" }}></i>
  //   ),
  // },
  {
    _tag: "CSidebarNavItem",
    name: "Tổ chức",
    to: ROUTER.ORGANIZATION.LIST,
    icon: (
      <i className="fas fa-sitemap pr-[30px]" style={{ maxWidth: "40px" }}></i>
    ),
  },
  // {
  //   _tag: "CSidebarNavItem",
  //   name: "Ưu tiên",
  //   to: "/priority",
  //   icon: <i className="far fa-gem pr-[30px]" style={{ maxWidth: "40px" }}></i>,
  // },
];
