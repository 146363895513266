import moment from "moment";

export const RenderTimeSendMailDetail = (time) => {
  if (moment(time).format("DD-MM-YYYY") === moment().format("DD-MM-YYYY")) {
    if (moment().format("HH") - moment(time).format("HH") === 0) {
      return `${moment(time).format("HH:mm")} (${
        moment().format("mm") - moment(time).format("mm")
      } phút trước)`;
    } else {
      return `${moment(time).format("HH:mm")} (${
        moment().format("HH") - moment(time).format("HH")
      } giờ trước)`;
    }
  } else if (
    moment(time).format("MM-YYYY") === moment().format("MM-YYYY") &&
    moment(time).format("YYYYDD") - moment().format("YYYYDD") < 15
  ) {
    return `${moment(time).format("HH:mm, DD/MM/YYYY")} (${
      moment().format("DD") - moment(time).format("DD")
    } ngày trước)`;
  } else {
    return `${moment(time).format("HH:mm, DD/MM/YYYY")}`;
  }
};

export const RenderTimeSendMailList = (time) => {
  if (moment(time).format("DD-MM-YYYY") === moment().format("DD-MM-YYYY")) {
    if (moment().format("HH") - moment(time).format("HH") === 0) {
      return `${moment(time).format("HH:mm")} (${
        moment().format("mm") - moment(time).format("mm")
      } phút trước)`;
    } else {
      return `${moment(time).format("HH:mm")} (${
        moment().format("HH") - moment(time).format("HH")
      } giờ trước)`;
    }
  } else {
    return `${moment(time).format("HH:mm, DD/MM/YYYY")}`;
  }
};
