import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import store from "./store/index";
import { Provider } from "react-redux";
import { icons } from "./assets/icons/index";
import "@fortawesome/fontawesome-free/css/all.css";
import "antd/dist/antd.css";
import "./assets/css/tailwind.css";
import "./assets/css/custom-antd.css";
import * as serviceWorker from "./serviceWorker";


React.icons = icons;
ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      {/* <Suspense fallback={loading}> */}
      <App />
      {/* </Suspense> */}
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);
serviceWorker.unregister();
