import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
} from "@coreui/react";

import CIcon from "@coreui/icons-react";
import logo1 from "../../assets/image/logo.png";
import logo2 from "../../assets/image/favicon.ico";
import { nav1, nav2 } from "./_nav";
import { Tooltip } from "antd";
// sidebar nav config

const TheSidebar = () => {
  const dispatch = useDispatch();
  const show = useSelector((state) => state.changeState.sidebarShow);
  const accountDetail = useSelector(
    (state) => state?.accountDetail?.accountDetail
  );

  return (
    <CSidebar
      show={show}
      onShowChange={(val) => dispatch({ type: "set", sidebarShow: val })}
    >
      <CSidebarBrand className="d-md-down-none" to="/">
        <CIcon
          className="c-sidebar-brand-full w-[130px] h-[65px] py-1"
          src={logo1}
        />
        <CIcon
          className="c-sidebar-brand-minimized w-[70px] h-[65px] px-[10px] py-[12px]"
          src={logo2}
        />
      </CSidebarBrand>
      <CSidebarNav className="mt-3">
        <CCreateElement
          items={accountDetail?.roleCode === "employee" ? nav2 : nav1}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle,
          }}
        />
      </CSidebarNav>
      <Tooltip title="Thu nhỏ/Mở rộng">
        <CSidebarMinimizer className="c-d-md-down-none" />
      </Tooltip>
    </CSidebar>
  );
};

export default React.memo(TheSidebar);
