import React, { useCallback, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ButtonSubmit from "../../../components/Button/ButtonSubmit";
import InputSearch from "../../../components/InputSearch/InputSearch";
import { notification } from "antd";
import { debounce } from "lodash";
import { unwrapResult } from "@reduxjs/toolkit";
import ROUTER from "../../../routers/routerLink";
import TableAdmin from "./../../../components/Table/TableAdmin";
import {
  getLicenseKeyCategory,
  deleteLicenseKeyCategory,
} from "./../LicenseKeyCategorySlice";
const LicenseKeyCategoryList = (props) => {
  const dispatch = useDispatch();
  const listCategoryLiscenkey = useSelector(
    (state) => state?.licenseKeyCategory?.items
  );
  const meta = useSelector((state) => state?.licenseKeyCategory?.meta);
  const isLoading = useSelector(
    (state) => state?.licenseKeyCategory?.isLoading
  );
  useEffect(() => {
    const { search } = props.history.location;
    let params;
    const filterOptions = search.split("?")[1];
    if (filterOptions !== undefined) {
      params = `{"${decodeURI(filterOptions)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')}"}`;
      dispatch(getLicenseKeyCategory(JSON.parse(params)));
    } else {
      dispatch(getLicenseKeyCategory(null));
    }
  }, [dispatch, props.history.location]);
  const columns = [
    { dataIndex: "title", title: "Tên danh mục", sorting: true, type: "left" },
  ];
  const onDelete = async (id) => {
    try {
      const resultAction = await dispatch(deleteLicenseKeyCategory(id));
      unwrapResult(resultAction);
      notification.success({ message: `Xoá thành công!` });
    } catch (error) {
      notification.error({ message: `Lỗi: ${error.message}` });
    }
  };
  const fecthSearch = (value) => {
    if (value) {
      props.history.push(
        ROUTER.LICENSEKEY_CATEGORY.LIST.concat(`?keyword=${value}`)
      );
    } else {
      props.history.push(ROUTER.LICENSEKEY_CATEGORY.LIST);
      dispatch(getLicenseKeyCategory(null));
    }
  };
  const debounceSearch = useCallback(debounce(fecthSearch, 1000), []);
  const onSearch = (e) => {
    debounceSearch(e.target.value);
  };
  const onChangePage = (page, size) => {
    const { search } = props.history.location;
    if (search !== "") {
      let params;
      const filterOptions = search.split("?")[1];
      if (filterOptions !== undefined) {
        params = `{"${decodeURI(filterOptions)
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"')}"}`;
      }
      const obj = JSON.parse(params);
      params = { ...obj, page, size };
      const urlParameters = [];
      Object.entries(params).forEach((e) => {
        if (e[1] !== undefined && e[1] !== "") {
          urlParameters.push(e.join("="));
        }
      });
      props.history.push(
        ROUTER.LICENSEKEY_CATEGORY.LIST.concat(`?${urlParameters.join("&")}`)
      );
    } else {
      props.history.push(
        ROUTER.LICENSEKEY_CATEGORY.LIST.concat(`?page=${page}&size=${size}`)
      );
    }
  };
  return (
    <>
      <div className="overflow-x-auto mb-10 mt-4">
        <div className="flex justify-center mb-3 bg-[#262626] px-4 py-8 rounded-[10px]">
          <div className="w-full">
            <div className="w-full mb-3 md:flex md:justify-between">
              <div>
                <NavLink to={ROUTER.LICENSEKEY_CATEGORY.ADD}>
                  <ButtonSubmit
                    iconButton={<i className="fas fa-plus pr-[15px]"></i>}
                    titleButton="Thêm mới"
                  />
                </NavLink>
              </div>
              <div className="md:flex md:justify-end ">
                <InputSearch onChange={onSearch} />
              </div>
            </div>
            <div className="">
              <TableAdmin
                Url={ROUTER.LICENSEKEY_CATEGORY.EDIT}
                onDelete={onDelete}
                columns={columns}
                spinning={isLoading}
                dataTable={listCategoryLiscenkey}
                meta={meta}
                onChangePage={onChangePage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LicenseKeyCategoryList;
