import { Col, Row, Checkbox, Popover } from "antd";

const SelectColumn = (props) => {
  const { defaultColumn, handleChildSelectColumn } = props;
  let plainOptions = defaultColumn?.map(function (obj) {
    return obj?.dataIndex;
  });

  function onChange(checkedValues) {
    handleChildSelectColumn(checkedValues);
  }

  const childContent = (
    <Row gutter={24}>
      <Col span={24}>
        <Checkbox.Group
          style={{ maxWidth: "150px" }}
          onChange={onChange}
          defaultValue={plainOptions}
        >
          <Row gutter={24}>
            {defaultColumn?.map((i) => (
              <Col key={i?.dataIndex} span={24}>
                <Checkbox value={i?.dataIndex}>{i?.title}</Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
      </Col>
    </Row>
  );
  return (
    <Popover content={childContent} title="Thay đổi trường" trigger="hover">
      <i className="fab fa-buromobelexperte w-9 h-9 stroke-current text-amber-600 pt-[11px] cursor-pointer " />
    </Popover>
  );
};

export default SelectColumn;
