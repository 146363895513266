import React, { useEffect, useState } from "react";
import { TweenOneGroup } from "rc-tween-one";
import { PlusOutlined, CloseOutlined } from "@ant-design/icons";
import {
  Modal,
  message,
  Tag,
  InputNumber,
  Tooltip,
  Button,
  Input,
  Form,
  notification,
  Select,
  Checkbox,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getConfigSendMail,
  addConfigSendMail,
  deleteConfigSendMail,
  addTempalteMailAuto,
  updateTempalteMailAuto,
} from "../ConfigSendMailLicenseKeySlice";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { EDITORCONFIGURATION } from "../../../../Utils/Config";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { unwrapResult } from "@reduxjs/toolkit";
import Api from "../../../../Utils/Api";

const { Option } = Select;
const ModalConfigSendMailAuto = ({ modalConfigMail, setModalConfigMail }) => {
  const dispatch = useDispatch();
  const [context, setContext] = useState("");
  const [subject, setSubject] = useState("");
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [templateSendMailId, setTemplateSendMailId] = useState(null);
  const [customerGroupId, setCustomerGroupId] = useState([]);
  const [listAllTemplateMailAuto, setListAllTemplateMailAuto] = useState([]);
  const [listAllCustomergroup, setListAllCustomergroup] = useState([]);
  const listConfigMail = useSelector((state) => state?.configSendMail?.items);
  const [checkboxDefaultMail, setCheckboxDefaultMail] = useState(false);
  // const templateMailById = useSelector(
  //   (state) => state?.configSendMail?.templateMailById
  // );

  useEffect(() => {
    if (listAllTemplateMailAuto && listAllTemplateMailAuto?.length > 0) {
      setTemplateSendMailId(listAllTemplateMailAuto[0]?.id);
      setSubject(listAllTemplateMailAuto[0]?.subject);
      setContext(listAllTemplateMailAuto[0]?.context);

      if (listAllTemplateMailAuto[0]?.customerGroupId) {
        setCustomerGroupId(listAllTemplateMailAuto[0]?.customerGroupId);
      } else {
        setCustomerGroupId([]);
      }
    }
  }, [listAllTemplateMailAuto]);

  const getTempalteMailById = async (id) => {
    try {
      let response = await Api.get(`api/templatemailauto/getbyid/${id}`);
      if (response.status === 200) {
        setTemplateSendMailId(response?.data?.data?.id);
        setSubject(response?.data?.data?.subject);
        setContext(response?.data?.data?.context);
        if (response?.data?.data?.customerGroupId) {
          setCustomerGroupId(response?.data?.data?.customerGroupId);
        } else {
          setCustomerGroupId([]);
        }

        if (response?.data?.data?.isDefault) {
          setCheckboxDefaultMail(response?.data?.data?.isDefault);
        } else {
          setCheckboxDefaultMail(false);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (templateSendMailId && templateSendMailId !== "default") {
      getTempalteMailById(templateSendMailId);
    } else {
      setSubject("");
      setContext("");
      setCheckboxDefaultMail(false);
      setCustomerGroupId([]);
    }
  }, [templateSendMailId]);

  const getAllTemplateAuto = async () => {
    try {
      const { data } = await Api.get(`/api/templatemailauto/getall`);
      if (data?.success === true) {
        setListAllTemplateMailAuto(data?.data);
      }
    } catch (error) {}
  };
  const getAllCustomergroup = async () => {
    try {
      const { data } = await Api.get(`/api/customergroup/getall`);
      if (data?.success === true) {
        setListAllCustomergroup(data?.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getAllTemplateAuto();
    getAllCustomergroup();
  }, []);

  useEffect(() => {
    dispatch(getConfigSendMail());
  }, [dispatch]);

  const handleCancleModal = () => {
    setModalConfigMail(false);
    setInputVisible(false);
    // setContext("");
    // setSubject("");
    // setCheckboxDefaultMail(false);
    // setCustomerGroupId([]);
    // setTemplateSendMailId(null);
    if (listAllTemplateMailAuto && listAllTemplateMailAuto?.length > 0) {
      setTemplateSendMailId(listAllTemplateMailAuto[0]?.id);
      setSubject(listAllTemplateMailAuto[0]?.subject);
      setContext(listAllTemplateMailAuto[0]?.context);

      if (listAllTemplateMailAuto[0]?.customerGroupId !== []) {
        setCustomerGroupId(listAllTemplateMailAuto[0]?.customerGroupId);
      } else {
        setCustomerGroupId([]);
      }
    }
  };

  const handleClose = async (removedTag) => {
    await dispatch(deleteConfigSendMail(removedTag));
    await dispatch(getConfigSendMail());
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e) => {
    setInputValue(e);
  };

  const handleInputConfirm = async () => {
    if (typeof inputValue === "number" && inputValue > 0) {
      const data = { date: inputValue };
      await dispatch(addConfigSendMail(data));
      await dispatch(getConfigSendMail());
      await setInputVisible(false);
    } else {
      message.error("Vui lòng nhập đúng định dạng !");
    }
  };

  const onFinishTemplateMail = async () => {
    const data = {
      subject: subject.trim(),
      context: context,
      customerGroupId: customerGroupId,
      isDefault: checkboxDefaultMail,
    };
    if (templateSendMailId && templateSendMailId !== "default") {
      if (context === "" || subject.trim() === "") {
        Modal.confirm({
          title: "Nội dung đang để trống, bạn có muốn xóa mẫu mail này không !",
          okText: "Xóa",
          cancelText: "Hủy",
          // icon: <ExportOutlined />,
          onOk: async () => {
            const responseDelete = await Api.delete(
              `/api/templatemailauto/delete/${templateSendMailId}`
            );
            if (responseDelete?.data?.success === true) {
              notification.success({
                message: `Xóa thành công !!!`,
              });
              getAllTemplateAuto();
              setTemplateSendMailId(listAllTemplateMailAuto[0]?.id);
            }
          },
          onCancel() {},
        });
      } else {
        let id = templateSendMailId;
        try {
          const resulDispatch = await dispatch(
            updateTempalteMailAuto({ id, data })
          );
          unwrapResult(resulDispatch);
          if (resulDispatch.payload.success === true) {
            notification.success({
              message: `Chỉnh sửa email tự động thành công !!!`,
            });
            getAllTemplateAuto();
            setTemplateSendMailId(resulDispatch.payload?.data?.id);
            handleCancleModal();
          }
        } catch (error) {
          if (error.message === "Rejected") {
            notification.error({
              message: `Lỗi: Vui lòng kiểm tra lại kết nối mạng !`,
            });
          } else {
            notification.error({ message: `Lỗi: ${error.message}` });
          }
        }
      }
    } else {
      try {
        const resulDispatch = await dispatch(addTempalteMailAuto(data));
        unwrapResult(resulDispatch);
        if (resulDispatch.payload.success === true) {
          notification.success({
            message: `Thêm mới email tự động thành công !!!`,
          });
          getAllTemplateAuto();
          setTemplateSendMailId(resulDispatch.payload?.data?.id);
          handleCancleModal();
        }
      } catch (error) {
        if (error.message === "Rejected") {
          notification.error({
            message: `Lỗi: Vui lòng kiểm tra lại kết nối mạng !`,
          });
        } else {
          notification.error({ message: `Lỗi: ${error.message}` });
        }
      }
    }
  };

  const onChangeTemplateSendMailIntro = (e) => {
    if (e && e !== "default") {
      setTemplateSendMailId(e);
    } else {
      setTemplateSendMailId("default");
    }
  };
  const onChangeCustomerGroup = (e) => {
    setCustomerGroupId(e);
  };

  const onAddKeyWordMail = (keyword) => {
    setContext(
      context.slice(0, context.lastIndexOf("</")) +
        ` ${keyword}` +
        context.slice(context.lastIndexOf("</"))
    );
  };
  return (
    <Modal
      title="Cài đặt gửi email LicenseKey hết hạn"
      visible={modalConfigMail}
      onOk={handleCancleModal}
      onCancel={handleCancleModal}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: false }}
      cancelText="Thoát"
      width={"80%"}
      className="overflow-x-auto"
      centered
      destroyOnClose={true}
      closeIcon={
        <Tooltip title="Đóng">
          <CloseOutlined />
        </Tooltip>
      }
    >
      <div
        style={{ marginBottom: 16 }}
        className="border-[1px] border-gray-200 rounded-lg p-3"
      >
        <span className="text-white font-semibold">
          Số ngày thông báo gửi email hết hạn :
        </span>
        <div className="ml-3">
          <TweenOneGroup
            enter={{
              scale: 0.8,
              opacity: 0,
              type: "from",
              duration: 100,
            }}
            onEnd={(e) => {
              if (e.type === "appear" || e.type === "enter") {
                e.target.style = "display: inline-block";
              }
            }}
            leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
            appear={false}
          >
            {listConfigMail?.map((tag, index) => {
              return (
                <span
                  key={index}
                  style={{ display: "inline-block", marginTop: "10px" }}
                >
                  <Tag
                    className="tag-custom"
                    closable={true}
                    onClose={(e) => {
                      e.preventDefault();
                      handleClose(tag.id);
                    }}
                  >
                    {tag.date} Ngày
                  </Tag>
                </span>
              );
            })}
          </TweenOneGroup>
          {inputVisible && (
            <InputNumber
              // ref={saveInputRef}
              type="text"
              size="small"
              style={{ width: 78 }}
              value={inputValue}
              onChange={handleInputChange}
              onBlur={handleInputConfirm}
              onPressEnter={handleInputConfirm}
            />
          )}
          {!inputVisible && (
            <Tag onClick={showInput} className="site-tag-plus mt-2">
              <span className="flex">
                <PlusOutlined className="pt-1 pr-1" /> Thêm mới
              </span>
            </Tag>
          )}
        </div>
      </div>

      <div className="mt-4 border-[1px] border-gray-200 rounded-lg p-3">
        <span className="text-white font-semibold">
          Nội dung email tự động gửi hết hạn :
        </span>
        <div className="grid grid-cols-7 mt-2 ml-3">
          <div className="col-span-7 md:col-span-5">
            <div className="flex">
              <div>
                <label className="text-white text-sm mb-1">
                  Tên mẫu email:
                </label>
                <div className=" h-[38px] cursor-pointer">
                  <Select
                    placeholder="Mẫu"
                    className="ant-select-custom2 border-l border-r h-full w-[400px]"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={templateSendMailId}
                    onChange={onChangeTemplateSendMailIntro}
                  >
                    <Option value="default">Thêm mới</Option>
                    {listAllTemplateMailAuto?.map((item) => {
                      return (
                        <Option key={item.id} value={item?.id}>
                          <Tooltip title={item?.subject}>
                            {item?.subject.length >= 50
                              ? item?.subject?.substring(0, 50) + ". . ."
                              : item?.subject}
                          </Tooltip>
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </div>
              <div>
                <Checkbox
                  className="ml-3 text-white mt-[30px]"
                  checked={checkboxDefaultMail}
                  onChange={() => setCheckboxDefaultMail(!checkboxDefaultMail)}
                >
                  Đặt làm mặc định
                </Checkbox>
              </div>
            </div>

            <div className="mt-3">
              <label className="text-white text-sm mb-1">
                Nhóm khách hàng:
              </label>
              <div className=" h-[38px] cursor-pointer">
                <Select
                  mode="multiple"
                  placeholder="Chọn nhóm khách hàng"
                  className="ant-select-custom2 border-l border-r h-auto min-w-[400px] overflow-x-auto"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  value={customerGroupId}
                  onChange={onChangeCustomerGroup}
                >
                  {listAllCustomergroup?.map((item) => {
                    return (
                      <Option key={item.id} value={item?.id}>
                        {item?.title}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </div>

            <div className="mt-3">
              <label className="text-white text-sm mb-1">Tiêu đề:</label>
              <Input
                onChange={(e) => setSubject(e.target.value)}
                value={subject}
                className="w-full h-[38px]"
                placeholder="Tiêu đề"
              />
            </div>
            <div className="flex flex-wrap mt-3">
              <label className="text-white text-sm mb-1">Nội dung:</label>
              <Form.Item className="w-full">
                <CKEditor
                  editor={Editor}
                  data={context}
                  config={EDITORCONFIGURATION}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setContext(data);
                  }}
                />
              </Form.Item>
            </div>
            <div className="flex justify-center">
              <div className="mx-2">
                <Button
                  onClick={onFinishTemplateMail}
                  size="large"
                  type="primary"
                  htmlType="submit"
                  className="w-[100px]"
                >
                  Lưu lại
                </Button>
              </div>
            </div>
          </div>
          <div className="shadow-lg bg-[#ffffff] p-3 ml-3 col-span-7 md:col-span-2 max-h-[300px]">
            <h1 className="px-2 text-blue-500 text-xl font-bold">
              Keyword Mail
            </h1>
            <ul className="text-base  pb-2">
              <li className="px-2 py-2 ">
                <span className=" inline-block font-thin w-[120px]">
                  Tài khoản
                </span>
                <span className="w">
                  <CopyToClipboard text="@taikhoan">
                    <Tooltip placement="bottom" title="Copy">
                      <i
                        onClick={() => onAddKeyWordMail("@taikhoan")}
                        className="bg-[#5b5b5c] hover:bg-[#747475] p-1 ml-2 cursor-pointer"
                      >
                        @taikhoan
                      </i>
                    </Tooltip>
                  </CopyToClipboard>
                </span>
              </li>
              <li className="px-2 py-2 ">
                <span className=" inline-block font-thin w-[120px]">
                  Tên sản phẩm
                </span>
                <span>
                  <CopyToClipboard text="@tensanpham">
                    <Tooltip placement="bottom" title="Copy">
                      <i
                        onClick={() => onAddKeyWordMail("@tensanpham")}
                        className="bg-[#5b5b5c] hover:bg-[#747475] p-1 ml-2 cursor-pointer"
                      >
                        @tensanpham
                      </i>
                    </Tooltip>
                  </CopyToClipboard>
                </span>
              </li>
              <li className="px-2 py-2 ">
                <span className=" inline-block font-thin w-[120px]">
                  Ngày kích hoạt
                </span>
                <span>
                  <CopyToClipboard text="@ngaykichhoat">
                    <Tooltip placement="bottom" title="Copy">
                      <i
                        onClick={() => onAddKeyWordMail("@ngaykichhoat")}
                        className="bg-[#5b5b5c] hover:bg-[#747475] p-1 ml-2 cursor-pointer"
                      >
                        @ngaykichhoat
                      </i>
                    </Tooltip>
                  </CopyToClipboard>
                </span>
              </li>
              <li className="px-2 py-2 ">
                <span className=" inline-block font-thin w-[120px]">
                  Ngày hết hạn
                </span>
                <span>
                  <CopyToClipboard text="@ngayhethan">
                    <Tooltip placement="bottom" title="Copy">
                      <i
                        onClick={() => onAddKeyWordMail("@ngayhethan")}
                        className="bg-[#5b5b5c] hover:bg-[#747475] p-1 ml-2 cursor-pointer"
                      >
                        @ngayhethan
                      </i>
                    </Tooltip>
                  </CopyToClipboard>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* </Form.Item> */}
    </Modal>
  );
};

export default ModalConfigSendMailAuto;
