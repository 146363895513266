import React, { useState } from "react";
import {
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { Tooltip } from "antd";
import ModalConfigSendMailAuto from "./components/ModalConfigSendMailAuto";
import ModalConfigurationEmail from "./components/ModalConfigurationEmail";

const ConfigSendMailLicenseKey = () => {
  const [modalConfigMail, setModalConfigMail] = useState(false);
  const [modalConfigurationEmail, setModalConfigurationEmail] = useState(false);

  return (
    <>
      <ModalConfigSendMailAuto
        modalConfigMail={modalConfigMail}
        setModalConfigMail={setModalConfigMail}
      />
      <ModalConfigurationEmail
        modalConfigurationEmail={modalConfigurationEmail}
        setModalConfigurationEmail={setModalConfigurationEmail}
      />
      <CDropdown inNav className="c-header-nav-items" direction="down">
        <CDropdownToggle className="c-header-nav-link" caret={false}>
          <Tooltip title="Cài đặt gửi Email">
            <span className=" font-semibold text-lg sm:text-xl capitalize ">
              <i className="fas fa-cog text-white"></i>
            </span>
          </Tooltip>
        </CDropdownToggle>
        <CDropdownMenu
          className="mt-2 pt-0 bg-[#2d2d2d] border-none rounded-[8px]"
          placement="bottom-end"
        >
          <CDropdownItem
            header
            tag="div"
            className="text-white text-[15px] bg-[#262626] border-b-2 border-gray-400"
          >
            <div>
              <span className="capitalize text-[17px]">
                <strong>Cài đặt gửi Email</strong>
              </span>
            </div>
          </CDropdownItem>
          <CDropdownItem
            className="text-white hover:bg-[#4f7b77]"
            onClick={() => setModalConfigMail(true)}
          >
            <CIcon name="cil-settings" className="mfe-2" />
            Chỉnh sửa gửi email tự động
          </CDropdownItem>
          <CDropdownItem
            className="text-white hover:bg-[#4f7b77]"
            onClick={() => setModalConfigurationEmail(true)}
          >
            <CIcon name="cil-settings" className="mfe-2" />
            Cấu hình Email
          </CDropdownItem>
        </CDropdownMenu>
      </CDropdown>
    </>
  );
};

export default ConfigSendMailLicenseKey;
