import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import ButtonCancel from "../../../components/Button/ButtonCancel";
import ButtonSubmit from "../../../components/Button/ButtonSubmit";
import { notification, Image, Button, Upload, message, Checkbox } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { unwrapResult } from "@reduxjs/toolkit";
import ROUTER from "../../../routers/routerLink";
import { addProduct } from "../ProductSlice";
import { API_URL } from "./../../../Utils/Config";
import { beforeUploadImage } from "../../../components/UploadFile/beforeUploadImage";
import noImage from "../../../assets/image/no-image.jpg";
import { getUserInfo } from "./../../../Utils/localStorage/GetUserInfo";

const ProductAdd = () => {
  const token = getUserInfo("token");
  const history = useHistory();
  const dispatch = useDispatch();
  const [urlImage, setUrlImage] = useState("");
  const [checkboxProductHtsc, setCheckboxProductHtsc] = useState(false);
  const {
    handleSubmit,
    register,
    // setError,
    formState: { errors },
  } = useForm();

  //   useEffect(() => {
  //     dispatch(getAllOrganization());
  //   }, [dispatch]);
  const onSubmit = async (value) => {
    const data = {
      description: value?.description?.trim() || "",
      title: value?.title?.trim() || "",
      urlImage: urlImage || "",
      isHTSC: checkboxProductHtsc,
    };
    try {
      const resulDispatch = await dispatch(addProduct(data));
      unwrapResult(resulDispatch);
      if (resulDispatch.payload.success === true) {
        notification.success({ message: `Thêm mới thành công !!!` });
        history.push(ROUTER.PRODUCT.LIST);
      }
    } catch (error) {
      if (error.message === "Rejected") {
        notification.error({
          message: `Lỗi: Vui lòng kiểm tra lại kết nối mạng !`,
        });
      } else {
        notification.error({ message: `Lỗi: ${error.message}` });
      }
    }
  };
  const uploadImage = {
    name: "file",
    action: `${API_URL}/api/product/uploadimage`,
    multiple: true,
    beforeUpload: beforeUploadImage,
    showUploadList: false,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    onChange(info) {
      if (info.file.status === "done") {
        setUrlImage(info.file.response.data);
        message.success(` Tải ảnh lên thành công !`);
      } else if (info.file.status === "error") {
        message.error(` Tải ảnh thất bại`);
      }
    },
  };
  return (
    <>
      <div className="flex justify-center mt-[30px]">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full max-w-[1100px] overflow-hidden shadow-lg bg-[#262626] rounded-[10px]"
        >
          <div className="mx-[40px] my-[30px]">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-full md:col-span-8">
                <div className="flex flex-wrap mb-3">
                  <div className="w-full">
                    <label className=" text-white text-sm font-bold mb-2">
                      Tên sản phẩm
                      <span className="text-red-600 font-extrabold text-[17px] ml-1">
                        *
                      </span>
                    </label>
                    <input
                      className={`bg-[#414141] border pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300 ${
                        errors?.title
                          ? "focus:ring-red-500 focus:ring-1"
                          : "focus:ring-purple-500 focus:ring-2"
                      } focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent`}
                      type="text"
                      placeholder="Tên sản phẩm"
                      {...register("title", {
                        required: true,
                        // pattern: validateInput,
                      })}
                    />
                    <div className="mt-1 h-[10px]">
                      <span className="text-red-500">
                        {errors?.title && errors?.title.type === "required"
                          ? "Hãy nhập tên sản phẩm"
                          : ""}
                        {errors?.title && errors.title.type === "pattern"
                          ? "Không được chứa kí tự đặc biệt"
                          : ""}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap mb-2">
                  <div className="w-full">
                    <label
                      className=" text-white text-sm font-bold"
                      onClick={() =>
                        setCheckboxProductHtsc(!checkboxProductHtsc)
                      }
                    >
                      Sản phẩm nội bộ
                    </label>
                    <Checkbox
                      className="ml-3"
                      checked={checkboxProductHtsc}
                      onChange={() =>
                        setCheckboxProductHtsc(!checkboxProductHtsc)
                      }
                    ></Checkbox>
                  </div>
                </div>
                <div className="flex flex-wrap mb-8">
                  <div className="w-full">
                    <label className=" text-white text-sm font-bold mb-2">
                      Mô tả
                    </label>
                    <textarea
                      className="bg-[#414141] pl-[10px] pt-[10px] w-full border rounded-[6px] hover:ring-2 hover:ring-purple-300 focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                      rows={5}
                      placeholder="Mô tả"
                      {...register("description")}
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-full md:col-span-4 xl:ml-[10px] flex items-center flex-col mb-6 md:mb-0">
                <div className="mb-[15px] md:mt-[30px]">
                  <Image
                    src={urlImage ? `${API_URL}/${urlImage}` : noImage}
                    style={{
                      height: 200,
                      width: 200,
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                    alt=""
                  />
                </div>

                <Upload {...uploadImage}>
                  <Button
                    style={{ borderRadius: "2px" }}
                    icon={<UploadOutlined />}
                  >
                    Chọn ảnh
                  </Button>
                </Upload>
              </div>
            </div>

            <div className="flex justify-center mb-[50px]">
              <div className="mx-2">
                <ButtonCancel onClick={() => history.goBack()} />
              </div>
              <div className="mx-2">
                <ButtonSubmit titleButton="Xác nhận" />
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default ProductAdd;
