import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../../Utils/Api";

export const getLicenseKey = createAsyncThunk(
  "LKM/getLicenseKey",
  async (params, { rejectWithValue }) => {
    try {
      const response = await Api.get(`/api/licensekey/fill`, {
        params: params,
      });
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);
export const getLicenseKeyFilter = createAsyncThunk(
  "LKM/getLicenseKeyFilter",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Api.post(`/api/licensekey/get`, data);
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);
export const ReportLicenseKeyTime = createAsyncThunk(
  "LKM/ReportLicenseKeyTime",
  async () => {
    try {
      const response = await Api.get(`api/report/licensekeytime`);
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {}
  }
);
export const getAllLicenseKey = createAsyncThunk(
  "LKM/getAllLicenseKey",
  async () => {
    try {
      const response = await Api.get(`/api/licensekey/getall`);
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {}
  }
);
export const deleteLicenseKey = createAsyncThunk(
  "LKM/deleteLicenseKey",
  async (id, { rejectWithValue }) => {
    try {
      const response = await Api.delete(`/api/licensekey/delete/${id}`);
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const addLicenseKey = createAsyncThunk(
  "LKM/addLicenseKey",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Api.post(`/api/licensekey/create`, data);
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);
export const updateLicenseKey = createAsyncThunk(
  "LKM/updateLicenseKey",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await Api.put(`/api/licensekey/update/${id}`, data);
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

const LicenseKeySlice = createSlice({
  name: "LicenseKey",
  initialState: {
    items: [],
    filters: {},
    reportTimeList: [],
    meta: {
      page: 0,
      size: 10,
      total: 0,
    },
    isLoading: false,
  },
  reducers: {
    setFilters: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    removeFilters: (state) => {
      state.filters = {};
    },
  },
  extraReducers: {
    [getLicenseKey.pending]: (state) => {
      state.isLoading = true;
    },
    [getLicenseKey.fulfilled]: (state, action) => {
      const { data, meta } = action?.payload;
      state.isLoading = false;
      state.items = data;
      state.meta = {
        page: meta?.page,
        size: meta?.size,
        total: meta?.total,
      };
    },
    [getLicenseKey.rejected]: (state) => {
      state.isLoading = false;
    },
    [getLicenseKeyFilter.pending]: (state) => {
      state.isLoading = true;
    },
    [getLicenseKeyFilter.fulfilled]: (state, action) => {
      const { data, meta } = action?.payload;
      state.isLoading = false;
      state.items = data;
      state.meta = {
        page: meta?.page,
        size: meta?.size,
        total: meta?.total,
      };
    },
    [getLicenseKeyFilter.rejected]: (state) => {
      state.isLoading = false;
    },
    [ReportLicenseKeyTime.pending]: (state) => {
      state.isLoading = true;
    },
    [ReportLicenseKeyTime.fulfilled]: (state, action) => {
      state.reportTimeList = action?.payload?.data;
      state.isLoading = false;
    },
    [ReportLicenseKeyTime.rejected]: (state) => {
      state.isLoading = false;
    },
    [getAllLicenseKey.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllLicenseKey.fulfilled]: (state, action) => {
      state.items = action?.payload?.data;
      state.isLoading = false;
    },
    [getAllLicenseKey.rejected]: (state) => {
      state.isLoading = false;
    },
    [deleteLicenseKey.pending]: (state) => {
      state.isLoading = true;
    },
    [deleteLicenseKey.fulfilled]: (state, action) => {
      if (action?.payload?.success === true) {
        state.items = state.items.filter(
          (item) => item.id !== action?.meta?.arg
        );
        state.reportTimeList.listDataOfDay =
          state.reportTimeList.expirationDateWith14Day.filter(
            (item) => item.id !== action?.meta?.arg
          );
        state.reportTimeList.expirationDateWith14Day =
          state.reportTimeList.expirationDateWith14Day.filter(
            (item) => item.id !== action?.meta?.arg
          );
      }
      state.isLoading = false;
    },
    [deleteLicenseKey.rejected]: (state) => {
      state.isLoading = false;
    },
    [addLicenseKey.pending]: (state) => {
      state.isLoading = true;
    },
    [addLicenseKey.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [addLicenseKey.rejected]: (state) => {
      state.isLoading = false;
    },
    [updateLicenseKey.pending]: (state) => {
      state.isLoading = true;
    },
    [updateLicenseKey.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [updateLicenseKey.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export const { setFilters, removeFilters } = LicenseKeySlice.actions;
export default LicenseKeySlice.reducer;
