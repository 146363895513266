import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../../Utils/Api";

export const getPriority = createAsyncThunk(
  "LKM/getPriority",
  async (params, { rejectWithValue }) => {
    try {
      const response = await Api.get(`/api/priority/get`, {
        params: params,
      });
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getAllPriority = createAsyncThunk(
  "LKM/getAllPriority",
  async () => {
    try {
      const response = await Api.get(`/api/priority/getall`);
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {}
  }
);

export const deletePriority = createAsyncThunk(
  "LKM/deletePriority",
  async (id, { rejectWithValue }) => {
    try {
      const response = await Api.delete(`/api/priority/delete/${id}`);
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const addPriority = createAsyncThunk(
  "LKM/addPriority",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Api.post(`/api/priority/create`, data);
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);
export const updatePriority = createAsyncThunk(
  "LKM/updatePriority",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await Api.put(`/api/priority/update/${id}`, data);
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);
const PrioritySlice = createSlice({
  name: "Priority",
  initialState: {
    items: [],
    meta: {
      page: 0,
      size: 10,
      total: 0,
    },
    isLoading: false,
  },
  extraReducers: {
    [getPriority.pending]: (state) => {
      state.isLoading = true;
    },
    [getPriority.fulfilled]: (state, action) => {
      const { data, meta } = action?.payload;
      state.items = data;
      state.isLoading = false;
      state.meta = {
        page: meta?.page,
        size: meta?.size,
        total: meta?.total,
      };
    },
    [getPriority.rejected]: (state) => {
      state.isLoading = false;
    },
    [getAllPriority.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllPriority.fulfilled]: (state, action) => {
      state.items = action?.payload?.data;
      state.isLoading = false;
    },
    [getAllPriority.rejected]: (state) => {
      state.isLoading = false;
    },
    [deletePriority.pending]: (state) => {
      state.isLoading = true;
    },
    [deletePriority.fulfilled]: (state, action) => {
      if (action?.payload?.success === true) {
        state.items = state.items.filter((item) => item.id !== action.meta.arg);
      }
      state.isLoading = false;
    },
    [deletePriority.rejected]: (state) => {
      state.isLoading = false;
    },
    [addPriority.pending]: (state) => {
      state.loading = true;
    },
    [addPriority.fulfilled]: (state) => {
      state.loading = false;
    },
    [addPriority.rejected]: (state) => {
      state.loading = false;
    },
    [updatePriority.pending]: (state) => {
      state.loading = true;
    },
    [updatePriority.fulfilled]: (state) => {
      state.loading = false;
    },
    [updatePriority.rejected]: (state) => {
      state.loading = false;
    },
  },
});
export default PrioritySlice.reducer;
