import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import avtUser from "../../../assets/image/user.png";
import ButtonCancel from "../../../components/Button/ButtonCancel";
import ButtonSubmit from "../../../components/Button/ButtonSubmit";
import {
  validateEmail,
  validateInputName,
  validatePhoneNumber,
} from "../../../Utils/Validate/validateInput";
import ROUTER from "../../../routers/routerLink";
import { useHistory } from "react-router-dom";
import { Image, Upload, Tooltip, message, notification } from "antd";
import { beforeUploadImage } from "../../../components/UploadFile/beforeUploadImage";
import { API_URL } from "./../../../Utils/Config";
import { getUserInfo } from "../../../Utils/localStorage/GetUserInfo";
import { changeAccountDetail } from "./AccountDetailSlice";
import { unwrapResult } from "@reduxjs/toolkit";

const AuthDetail = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const accountDetail = useSelector(
    (state) => state?.accountDetail?.accountDetail
  );
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const [avatar, setAvatar] = useState("");
  const token = getUserInfo("token");
  useEffect(() => {
    if (accountDetail) {
      reset(accountDetail);
      setValue("name", accountDetail?.name, { shouldDirty: true });
      setValue("phone", accountDetail?.phone, { shouldDirty: true });
      setAvatar(accountDetail?.avatar);
    }
  }, [accountDetail, reset, setValue]);

  const onSubmit = async (value) => {
    const data = {
      id: accountDetail?.id,
      avatar: avatar ? avatar : "",
      phone: value?.phone?.trim(),
      name: value?.name?.trim(),
    };
    try {
      const resulDispatch = await dispatch(changeAccountDetail(data));
      unwrapResult(resulDispatch);
      if (resulDispatch.payload.success === true) {
        notification.success({ message: `Cập nhật tài khoản thành công !` });
        history.push(ROUTER.HOME.HOME);
      }
    } catch (error) {
      if (error.message === "Rejected") {
        notification.error({
          message: `Lỗi: Vui lòng kiểm tra lại kết nối mạng !`,
        });
      } else {
        notification.error({ message: `Lỗi: ${error.message}` });
      }
    }
  };
  const uploadImage = {
    name: "file",
    action: `${API_URL}/api/account/uploadimage`,
    multiple: true,
    beforeUpload: beforeUploadImage,
    showUploadList: false,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    onChange(info) {
      if (info.file.status === "done") {
        setAvatar(info.file.response.data);
        message.success(`Tải ảnh lên thành công !`);
      } else if (info.file.status === "error") {
        message.error(`Tải ảnh thất bại`);
      }
    },
  };
  return (
    <>
      <div className="flex justify-center">
        <div className="flex bg-[#262626] overflow-hidden rounded-[5px] w-full max-w-[1200px] ">
          <div className="shadow-lg bg-[#414141] rounded-[5px] mr-2 ml-6 my-6 w-0 md:w-1/3 md:block hidden">
            <div className="flex items-center flex-col transform translate-y-1/4">
              <div className="relative">
                <Image
                  src={avatar ? `${API_URL}/${avatar}` : avtUser}
                  style={{
                    height: 200,
                    width: 200,
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                  alt=""
                />
                <Tooltip title="Thay đổi ảnh">
                  <Upload
                    {...uploadImage}
                    className="absolute top-1/2 right-9 transform translate-y-16  cursor-pointer"
                  >
                    <i className="fas fa-pencil-alt  stroke-current text-white  bg-[#6E74EF] rounded-full p-[8px] "></i>
                  </Upload>
                </Tooltip>
              </div>

              <h1 className="text-xl font-semibold text-white capitalize mt-3">
                {accountDetail?.name}
              </h1>
              <span className="bg-[#EB873E] text-white p-2 rounded-[5px] capitalize">
                {accountDetail?.roleName}
              </span>
            </div>
          </div>
          <div className="shadow-lg bg-[#414141] rounded-[5px] mr-6 my-6 w-full md:w-2/3 ">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className=" overflow-hidden "
            >
              <div className="mx-[20px] md:mx-[40px] my-[30px]">
                <h1 className="text-white text-[30px] font-extrabold text-center mb-3">
                  Cập nhật tài khoản
                </h1>
                <div className="w-full md:px-3 mb-3">
                  <label className=" text-white text-sm font-bold mb-2">
                    Email
                  </label>
                  <input
                    className={`text-gray-600 bg-gray-300 border pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300 ${
                      errors?.email
                        ? "focus:ring-red-500 focus:ring-1"
                        : "focus:ring-purple-500 focus:ring-2"
                    } focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent`}
                    type="email"
                    disabled
                    placeholder="vietanh@gmail.com"
                    {...register("email", {
                      required: true,
                      pattern: validateEmail,
                    })}
                  />
                  <div className="mt-1 h-[10px]">
                    <span className="text-red-500">
                      {errors?.email && errors?.email.type === "required"
                        ? "Hãy nhập địa chỉ email"
                        : ""}
                      {errors?.email && errors.email.type === "pattern"
                        ? "Định dạng Email không hợp lệ"
                        : ""}
                    </span>
                  </div>
                </div>
                <div className="w-full md:px-3 mb-3 ">
                  <label className=" text-white text-sm font-bold mb-2">
                    Họ và tên
                    <span className="text-red-600 font-extrabold text-[17px] ml-1">
                      *
                    </span>
                  </label>
                  <input
                    className={`bg-[#414141] border pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300 ${
                      errors?.name
                        ? "focus:ring-red-500 focus:ring-1"
                        : "focus:ring-purple-500 focus:ring-2"
                    } focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent`}
                    type="text"
                    placeholder="Họ và tên"
                    {...register("name", {
                      required: true,
                      pattern: validateInputName,
                    })}
                  />
                  <div className="mt-1 h-[10px]">
                    <span className="text-red-500">
                      {errors?.name && errors?.name.type === "required"
                        ? "Hãy nhập họ và tên"
                        : ""}
                      {errors?.name && errors.name.type === "pattern"
                        ? "Không được chứa số và kí tự đặc biệt"
                        : ""}
                    </span>
                  </div>
                </div>

                <div className="w-full md:px-3 mb-3 ">
                  <label className=" text-white text-sm font-bold mb-2">
                    Số điện thoại
                  </label>
                  <input
                    className={`bg-[#414141] border pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300 ${
                      errors?.phone
                        ? "focus:ring-red-500 focus:ring-1"
                        : "focus:ring-purple-500 focus:ring-2"
                    } focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent`}
                    type="text"
                    placeholder="Số điện thoại"
                    {...register("phone", {
                      pattern: validatePhoneNumber,
                    })}
                  />
                  <div className="mt-1 h-[10px]">
                    <span className="text-red-500">
                      {errors?.phone && errors.phone.type === "pattern"
                        ? "Số điện thoại không hợp lệ"
                        : ""}
                    </span>
                  </div>
                </div>
                <div className="w-full mb-3 block md:hidden ">
                  <div className="flex items-center flex-col">
                    <div className="relative">
                      <Image
                        src={avatar ? `${API_URL}/${avatar}` : avtUser}
                        style={{
                          height: 200,
                          width: 200,
                          borderRadius: "50%",
                          objectFit: "cover",
                        }}
                        alt=""
                      />
                      <Tooltip title="Thay đổi ảnh">
                        <Upload
                          {...uploadImage}
                          className="absolute top-1/2 right-9 transform translate-y-16  cursor-pointer"
                        >
                          <i className="fas fa-pencil-alt  stroke-current text-white  bg-[#6E74EF] rounded-full p-[8px] "></i>
                        </Upload>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-center mb-6">
                <div className="mx-2">
                  <ButtonCancel onClick={() => history.goBack()} />
                </div>
                <div className="mx-2">
                  <ButtonSubmit titleButton="Cập nhật" />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthDetail;
