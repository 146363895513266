import React, { useState } from "react";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory } from "react-router-dom";
import ROUTER from "../../../routers/routerLink";

const Notification = ({ notifiReportTimeList }) => {
  const history = useHistory();
  const [scroll14day, setScroll14day] = useState({
    items: Array.from({ length: 2 }),
    hasMore: true,
  });
  // const [scrollToDay, setScrollToDay] = useState({
  //   items: Array.from({ length: 2 }),
  //   hasMore: true,
  // });

  const fetchMoreDataWith14Day = () => {
    if (
      scroll14day.items.length >=
      notifiReportTimeList?.expirationDateWith14Day?.length
    ) {
      setScroll14day({ hasMore: false });
      return;
    }
    setTimeout(() => {
      setScroll14day({
        items: scroll14day.items.concat(Array.from({ length: 2 })),
      });
    }, 1000);
  };

  // const fetchMoreDataToDay = () => {
  //   if (
  //     scrollToDay.items.length >= notifiReportTimeList?.listDataOfDay?.length
  //   ) {
  //     setScrollToDay({ hasMore: false });
  //     return;
  //   }
  //   setTimeout(() => {
  //     setScrollToDay({
  //       items: scrollToDay.items.concat(Array.from({ length: 2 })),
  //     });
  //   }, 1000);
  // };
  return (
    <>
      <h1 className="text-white font-extrabold text-[25px]">
        Thông báo
        <sup className="text-white text-[13px] pl-1">
          (
          {notifiReportTimeList?.expirationDateWith14Day &&
            notifiReportTimeList?.expirationDateWith14Day.length}
          )
        </sup>
      </h1>
      {/* <div className="pr-2 border-b-[1px] border-[#706E6E] pb-3 mt-2">
        <span className="text-red-600 font-extrabold text-[13px]">Hôm nay</span>
        <sup className="text-white text-[13px] pl-1">
          (
          {notifiReportTimeList?.listDataOfDay &&
            notifiReportTimeList?.listDataOfDay.length}
          )
        </sup>
        {notifiReportTimeList?.listDataOfDay &&
        notifiReportTimeList?.listDataOfDay?.length === 0 ? (
          <>
            <div className="my-6">
              <i className="text-[#9E9E9E] text-[13px] font-thin leading-5">
                Không có thông báo nào
              </i>
            </div>
          </>
        ) : (
          <>
            <InfiniteScroll
              dataLength={scrollToDay.items.length}
              hasMore={scrollToDay.hasMore}
              loader={<h4 className="text-center">Loading...</h4>}
              next={fetchMoreDataToDay}
              height={170}
              endMessage={
                <p className="text-center text-gray-400">
                  <b>Yay! You have seen it all</b>
                </p>
              }
            >
              {notifiReportTimeList?.listDataOfDay &&
                notifiReportTimeList?.listDataOfDay.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="pl-1 py-1 pr-[2px] hover:bg-[#4d706d] hover:rounded-md"
                    >
                      <span className="text-[#9E9E9E] font-medium leading-5">
                        <span className="text-blue-400">&#9679;</span> Khách
                        hàng
                        <span className="capitalize text-white p-1">
                          {item?.customerName}
                        </span>
                        đã hết hạn key của sản phẩm {item?.productName} vào hôm
                        nay
                      </span>
                    </div>
                  );
                })}
            </InfiniteScroll>
          </>
        )}
      </div> */}
      <div className="pr-2 pb-3 mt-3">
        {/* <span className="text-red-600 font-extrabold text-[13px]">
          Dưới 14 ngày
        </span> */}
        {/* <sup className="text-white text-[13px] pl-1">
          (
          {notifiReportTimeList?.expirationDateWith14Day &&
            notifiReportTimeList?.expirationDateWith14Day.length}
          )
        </sup> */}
        {notifiReportTimeList?.expirationDateWith14Day &&
        notifiReportTimeList?.expirationDateWith14Day?.length === 0 ? (
          <>
            <div className="my-6">
              <i className="text-[#9E9E9E] text-[13px] font-thin leading-5">
                Không có thông báo nào
              </i>
            </div>
          </>
        ) : (
          <>
            <InfiniteScroll
              dataLength={scroll14day.items.length}
              hasMore={scroll14day.hasMore}
              loader={<h4 className="text-center">Loading...</h4>}
              height={200}
              next={fetchMoreDataWith14Day}
              // endMessage={
              //   <p className="text-center text-gray-400">
              //     <b>Yay! You have seen it all</b>
              //   </p>
              // }
            >
              {notifiReportTimeList?.expirationDateWith14Day &&
                notifiReportTimeList?.expirationDateWith14Day.map(
                  (item, index) => {
                    return (
                      <div
                        key={index}
                        className="pl-1 py-1 pr-[2px] hover:bg-[#4d706d] hover:rounded-md cursor-pointer"
                        onClick={() =>
                          history.push(`${ROUTER.LICENSEKEY.EDIT}/${item?.id}`)
                        }
                      >
                        <span className="text-[#9E9E9E] font-medium leading-5">
                          <span className="text-blue-400">&#9679;</span> Khách
                          hàng
                          <span className="capitalize text-white p-1">
                            {item?.customerName}
                          </span>
                          sắp hết hạn key của sản phẩm {item?.productName} (
                          {moment(item?.expirationDate).format("DD-MM-YYYY")})
                        </span>
                      </div>
                    );
                  }
                )}
            </InfiniteScroll>
          </>
        )}
      </div>
    </>
  );
};

export default Notification;
