import React, { useEffect } from "react";
import { Form, Input, notification } from "antd";
import FormModal from "../../../components/FormModal/FormModal";
import {
  addDepartment,
  updateDepartment,
} from "../../Department/DeparmentSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
const DepartmentAddModal = ({
  organizationId,
  type,
  dataEdit,
  modalAddDepartment,
  handleCancelDepartment,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  useEffect(() => {
    if (dataEdit !== null) {
      form.setFieldsValue({
        title: dataEdit?.title,
        note: dataEdit?.note,
      });
    } else {
      form.resetFields();
    }
  }, [dataEdit, form]);

  const onFinish = async (value) => {
    const data = {
      note: value?.note?.trim() || "",
      title: value?.title?.trim() || "",
      organizationId: organizationId,
    };
    if (type === "update" && dataEdit?.id) {
      const id = dataEdit?.id;
      try {
        const resulDispatch = await dispatch(updateDepartment({ id, data }));
        unwrapResult(resulDispatch);
        if (resulDispatch.payload.success === true) {
          notification.success({ message: `Sửa thành công !!!` });
          form.resetFields();
          handleCancelDepartment();
        }
      } catch (error) {
        if (error.message === "Rejected") {
          notification.error({
            message: `Lỗi: Vui lòng kiểm tra lại kết nối mạng !`,
          });
        } else {
          notification.error({ message: `Lỗi: ${error.message}` });
        }
      }
    } else {
      try {
        const resulDispatch = await dispatch(addDepartment(data));
        unwrapResult(resulDispatch);
        if (resulDispatch.payload.success === true) {
          notification.success({ message: `Thêm mới thành công !!!` });
          form.resetFields();
          handleCancelDepartment();
        }
      } catch (error) {
        if (error.message === "Rejected") {
          notification.error({
            message: `Lỗi: Vui lòng kiểm tra lại kết nối mạng !`,
          });
        } else {
          notification.error({ message: `Lỗi: ${error.message}` });
        }
      }
    }
  };
  return (
    <>
      <FormModal
        titleModal={dataEdit ? "Cập nhật phòng ban" : "Thêm phòng ban"}
        titleForm={"adddepartment"}
        visible={modalAddDepartment}
        okText="Áp dụng"
        handleCancel={handleCancelDepartment}
        width={"90%"}
        className="modal-800"
        hiddenReset
      >
        <Form
          layout="vertical"
          onFinish={onFinish}
          form={form}
          id={`adddepartment`}
        >
          <div className="flex flex-wrap">
            <div className="w-full">
              <Form.Item
                name="title"
                className="w-full px-3"
                label="Tên phòng ban"
                rules={[
                  {
                    required: true,
                    message: "Hãy nhập phòng ban",
                  },
                ]}
              >
                <Input
                  className="text-white custom-input-ant border w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300  focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                  placeholder="Tên phòng ban"
                />
              </Form.Item>
            </div>
            <div className="w-full">
              <Form.Item name="note" className="w-full px-3" label="Ghi chú">
                <Input.TextArea
                  className="text-white custom-input-ant border w-full rounded-[6px] hover:ring-1  hover:ring-purple-300  focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                  rows={5}
                  placeholder="Ghi chú"
                />
              </Form.Item>
            </div>
          </div>
        </Form>
      </FormModal>
    </>
  );
};

export default DepartmentAddModal;
