import { message } from "antd";

export function beforeUploadFileEmailTxt(file) {
  const isJpgOrPng = file.type === "text/plain";
  if (!isJpgOrPng) {
    message.error("Vui lòng chọn đúng định dạng !!!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error(" Vui lòng chọn ảnh nhỏ hơn 2Mb!");
  }
  return isJpgOrPng && isLt2M;
}
