import { create } from "axios";
import { API_URL } from "./Config";
// import { history } from "./history";
import { getUserInfo } from "./localStorage/GetUserInfo";

const Api = create({
  baseURL: API_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Cache: "no-cache",
  },
  timeout: 30000,
});

// Add a request interceptor
Api.interceptors.request.use((config) => {
  const token = getUserInfo("token");
  return {
    ...config,
    headers: { ...config.headers, Authorization: `Bearer ${token}` },
  };
});

// Add a response interceptor
Api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      window.location.reload();
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    return Promise.reject(error.response);
  }
);

export default Api;
