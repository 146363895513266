import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ButtonCancel from "./../../../components/Button/ButtonCancel";
import ButtonSubmit from "./../../../components/Button/ButtonSubmit";
import {
  notification,
  Popover,
  DatePicker,
  Modal,
  Tooltip,
  Select,
  ConfigProvider,
  Tabs,
  Upload,
  Button,
  Image,
} from "antd";
import {
  CloseOutlined,
  InfoCircleOutlined,
  CalendarOutlined,
  UploadOutlined,
  DeleteTwoTone,
  DeleteOutlined,
  VerticalAlignBottomOutlined,
  CloudDownloadOutlined,
} from "@ant-design/icons";
import { unwrapResult } from "@reduxjs/toolkit";
import { getAllOrganization } from "../../Organization/OrganizationSlice";
import { addProduct, getAllProduct } from "../../Product/ProductSlice";
import ROUTER from "../../../routers/routerLink";
import Api from "../../../Utils/Api";
import { getAllLicenseKeyCategory } from "../../LicenseKeyCategory/LicenseKeyCategorySlice";
import { addLicenseKey } from "../LicenseKeySlice";
import { CopyToClipboard } from "react-copy-to-clipboard";
import moment from "moment";
import { validateEmail } from "../../../Utils/Validate/validateInput";
import generator from "generate-password";
import { API_URL } from "./../../../Utils/Config";
import { getUserInfo } from "./../../../Utils/localStorage/GetUserInfo";
import { beforeUploadFile } from "./../../../components/UploadFile/beforeUploadFile";
import { saveAs } from "file-saver";
import Creatable from "react-select/creatable";
import { addEdition } from "../../Edition/EditionSlice";
import { addVersion } from "../../Version/VersionSlice";


const { Option } = Select;
const { TabPane } = Tabs;
const LicenseKeyAdd = () => {
  const token = getUserInfo("token");
  const dataAvalableTime = ["30", "60", "365", "730"];
  const history = useHistory();
  const dispatch = useDispatch();
  const [copyToClipboard, setCopyToClipboard] = useState({
    value: "",
    copied: false,
  });

  const [newOption, setNewOption] = useState("");
  const [avalableTime, setAvalableTime] = useState("");
  const [errAvalableTime, setErrAvalableTime] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [password, setPassword] = useState("");
  const [errPassword, setErrPassword] = useState(false);
  const [expirationDate, setExpirationDate] = useState("");
  const [lastKey, setLastKey] = useState("");
  const [infoCustomer, setInfoCustomer] = useState(null);
  const [infoVersion, setInfoVersion] = useState(null);
  const [infoEdition, setInfoEdition] = useState(null);
  const [idOrganization, setIdOrganization] = useState(null);
  const [idDepartment, setIdDepartment] = useState(null);
  const [idCustomer, setIdCustomer] = useState(null);
  const [planProduct, setPlanProduct] = useState([]);
  const [idProductNotHtsc, setIdProductNotHtsc] = useState(null);
  const [idProductIsHtsc, setIdProductIsHtsc] = useState(null);
  const [idEditionIsHtsc, setIdEditionIsHtsc] = useState(null);
  const [idEditionNotHtsc, setIdEditionNotHtsc] = useState(null);
  const [idVersionNotHtsc, setIdVersionNotHtsc] = useState(null);
  const [idVersionIsHtsc, setIdVersionIsHtsc] = useState(null);
  const [downloadLink, setDownloadLink] = useState("");
  const [listDepartmentByOrganization, setListDepartmentByOrganization] =
    useState([]);
  const [listCustomer, setListCustomer] = useState([]);
  const [listEditionByProductIsHtsc, setListEditionByProductIsHtsc] = useState(
    []
  );
  const [listEditionByProductNotHtsc, setListEditionByProductNotHtsc] =
    useState([]);
  const [listVersionByEditionIsHtsc, setListVersionByEditionIsHtsc] = useState(
    []
  );
  const [listVersionByEditionNotHtsc, setListVersionByEditionNotHtsc] =
    useState([]);
  const [keyTab, setKeyTab] = useState("1");
  const [listProductIsHtsc, setListProductIsHtsc] = useState([]);
  const [listProductNotHtsc, setListProductNotHtsc] = useState([]);
  const [listFileUpload, setListFileUpload] = useState([]);
  const [urlFilesRemove, seturlFilesRemove] = useState([]);
  // const listAllProduct = useSelector((state) => state?.product?.items);
  const listAllLicenseKeyCategory = useSelector(
    (state) => state?.licenseKeyCategory?.items
  );
  const listAllOrganization = useSelector((state) => state.organization.items);
  const accountDetail = useSelector(
    (state) => state?.accountDetail?.accountDetail
  );
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm();
  const getProductIsHtsc = async () => {
    try {
      let response = await Api.get(`/api/product/getbyishtsc/is`);
      if (response.status === 200) {
        setListProductIsHtsc(response?.data?.data);
      }
    } catch (error) { }
  };
  const getProductNotHtsc = async () => {
    try {
      let response = await Api.get(`/api/product/getbyishtsc/not`);
      const listOption = [];
      if (response.status === 200) {
        const dataRes = response?.data?.data;

        for (let i = 0; i < dataRes.length; i++) {
          listOption.push({
            label: `${dataRes[i]?.title}`,
            value: dataRes[i]?.id,
          });
        }
        setListProductNotHtsc(listOption);
      }
    } catch (error) { }
  };
  useEffect(() => {
    getProductIsHtsc();
    getProductNotHtsc();
  }, []);

  useEffect(() => {
    dispatch(getAllOrganization());
    dispatch(getAllProduct());
    dispatch(getAllLicenseKeyCategory());
  }, [dispatch]);

  const getDepartmentByOrganization = async (id) => {
    try {
      let response = await Api.get(`/api/department/${id}/organization`);
      if (response.status === 200) {
        setListDepartmentByOrganization(response?.data?.data);
      }
    } catch (error) { }
  };
  useEffect(() => {
    if (idOrganization) {
      getDepartmentByOrganization(idOrganization);
    }
  }, [idOrganization]);

  const getCustomerByOrganization = async (idAccount, idOrganization) => {
    try {
      let response = await Api.get(
        `/api/customer/${idAccount}/organization/${idOrganization}`
      );
      if (response.status === 200) {
        setListCustomer(response?.data?.data);
      }
    } catch (error) { }
  };
  useEffect(() => {
    if (idOrganization && !idDepartment) {
      const idAccount = accountDetail?.id;
      getCustomerByOrganization(idAccount, idOrganization);
    }
  }, [idOrganization, idDepartment, accountDetail]);

  const getCustomerByDepartment = async (idAccount, idDepartment) => {
    try {
      let response = await Api.get(
        `/api/customer/${idAccount}/department/${idDepartment}`
      );
      if (response.status === 200) {
        setListCustomer(response?.data?.data);
      }
    } catch (error) { }
  };
  useEffect(() => {
    if (idDepartment) {
      const idAccount = accountDetail?.id;
      getCustomerByDepartment(idAccount, idDepartment);
    }
  }, [accountDetail, idDepartment]);

  const getCustomerById = async (idCustomer) => {
    try {
      let response = await Api.get(`api/customer/getbyid/${idCustomer}`);
      if (response.status === 200) {
        setValue("email", response?.data?.data?.email, { shouldDirty: true });
        setInfoCustomer(response?.data?.data);
        setPlanProduct(response?.data?.data?.productId);
      }
    } catch (error) { }
  };
  useEffect(() => {
    if (idCustomer) {
      getCustomerById(idCustomer);
    }
  }, [idCustomer]);

  useEffect(() => {
    if (idVersionIsHtsc) {
      getVersionById(idVersionIsHtsc);
    }
  }, [idVersionIsHtsc]);
  useEffect(() => {
    if (idEditionIsHtsc) {
      getEditionByIdIsHtsc(idEditionIsHtsc);
    }
  }, [idEditionIsHtsc]);

  const getEditionByProductIsHtsc = async (id) => {
    try {
      let response = await Api.get(`/api/edition/product/${id}`);
      if (response.status === 200) {
        setListEditionByProductIsHtsc(response?.data?.data);
      }
    } catch (error) { }
  };
  const getEditionByProductNotHtsc = async (id) => {
    try {
      let response = await Api.get(`/api/edition/product/${id}`);
      const listOption = [];
      if (response.status === 200) {
        const dataRes = response?.data?.data;

        for (let i = 0; i < dataRes.length; i++) {
          listOption.push({
            label: `${dataRes[i]?.title}`,
            value: dataRes[i]?.id,
          });
        }
        setListEditionByProductNotHtsc(listOption);
      }
    } catch (error) { }
  };
  useEffect(() => {
    if (idProductIsHtsc !== null) {
      getEditionByProductIsHtsc(idProductIsHtsc);
    }
  }, [idProductIsHtsc]);
  useEffect(() => {
    if (idProductNotHtsc !== null) {
      getEditionByProductNotHtsc(idProductNotHtsc?.value);
    }
  }, [idProductNotHtsc]);

  const getVersionByEditionIsHtsc = async (id) => {
    try {
      let response = await Api.get(`/api/version/edition/${id}`);
      if (response.status === 200) {
        setListVersionByEditionIsHtsc(response?.data?.data);
      }
    } catch (error) { }
  };

  useEffect(() => {
    if (idEditionIsHtsc !== null) {
      getVersionByEditionIsHtsc(idEditionIsHtsc);
    }
  }, [idEditionIsHtsc]);
  const getVersionByEditionNotHtsc = async (id) => {
    try {
      let response = await Api.get(`/api/version/edition/${id}`);
      const listOption = [];
      if (response.status === 200) {
        const dataRes = response?.data?.data;

        for (let i = 0; i < dataRes.length; i++) {
          listOption.push({
            label: `${dataRes[i]?.title}`,
            value: dataRes[i]?.id,
          });
        }
        setListVersionByEditionNotHtsc(listOption);
      }
    } catch (error) { }
  };

  useEffect(() => {
    if (idEditionNotHtsc !== null) {
      getVersionByEditionNotHtsc(idEditionNotHtsc?.value);
    }
  }, [idEditionNotHtsc]);

  //export txt
  const makeTextFile = () => {
    const data = new Blob([`LicenseKey:  ${lastKey}`], { type: "text/plain" });
    if (downloadLink !== "") window.URL.revokeObjectURL(downloadLink);
    setDownloadLink(window.URL.createObjectURL(data));
  };
  useEffect(() => {
    makeTextFile();
  }, [lastKey]);

  const handleOk = () => {
    setIsModalVisible(false);
    history.push(ROUTER.LICENSEKEY.LIST);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    history.push(ROUTER.LICENSEKEY.LIST);
  };

  const getVersionById = async (id) => {
    try {
      let response = await Api.get(`api/version/getbyid/${id}`);
      if (response.status === 200) {
        setInfoVersion(response?.data?.data);
      }
    } catch (error) { }
  };
  const getEditionByIdIsHtsc = async (id) => {
    try {
      let response = await Api.get(`api/edition/getbyid/${id}`);
      if (response.status === 200) {
        setInfoEdition(response?.data?.data);
      }
    } catch (error) { }
  };
  const onChangeOrganization = (value) => {
    setIdOrganization(value);
    setIdDepartment(null);
    setIdCustomer(null);
    setListCustomer([]);
    setListDepartmentByOrganization([]);
    setInfoCustomer(null);
  };
  const onChangeDepartment = (value) => {
    setIdDepartment(value);
    setIdCustomer(null);
    setListCustomer([]);
  };
  const onChangeCustomer = (value) => {
    setIdCustomer(value);
    setInfoCustomer(null);
  };
  const onChangeProductIsHtsc = (value) => {
    setIdProductIsHtsc(value);
    setInfoVersion(null);
    setValue("versionId", "", { shouldDirty: true });
  };
  const onChangeProductNotHtsc = (value) => {
    setIdProductNotHtsc(value);
    setIdEditionNotHtsc(null);
    setIdVersionNotHtsc(null);
    // setListVersionByEditionNotHtsc([]);
    // setValue("versionId", "", { shouldDirty: true });
    // setValue("editionId", "", { shouldDirty: true });
  };
  const onChangeEditionIsHtsc = (value) => {
    setIdEditionIsHtsc(value.target.value);
    setPassword("");
    setValue("email", infoCustomer?.email, { shouldDirty: true });
    setValue("avalableTime", "", { shouldDirty: true });
    setValue("firstKey", "", { shouldDirty: true });
    setExpirationDate("");
    setStartDate("");
  };
  const onChangeEditionNotHtsc = (value) => {
    setIdEditionNotHtsc(value);
    setIdVersionNotHtsc(null);
  };
  const onChangeVersionNotHtsc = (value) => {
    setIdVersionNotHtsc(value);
  };

  // const onChangeVersionNotHtsc = (value) => {
  //   setIdVersionNotHtsc(value.target.value);
  //   setInfoVersion(null);
  // };
  const onChangeVersionIsHtsc = (value) => {
    setIdVersionIsHtsc(value.target.value);
    setInfoVersion(null);
  };

  const onChangeExpirationDate = (e) => {
    setExpirationDate(moment(e).format("YYYY-MM-DD"));
  };

  const onChangeStartDate = (e) => {
    setStartDate(moment(e).format("YYYY-MM-DD"));
  };
  const onSubmitOfHtsc = async (value) => {
    if (infoEdition?.type === true) {
      if (password) {
        setErrPassword(false);
        const data = {
          productId: idProductIsHtsc,
          customerId: idCustomer,
          licenseKeyCategoryId: value?.licenseKeyCategoryId,
          editionId: value?.editionId,
          versionId: value?.versionId,
          firstKey: "",
          avalableTime: "",
          expirationDate: expirationDate ? expirationDate : "",
          startDate: startDate ? startDate : moment().format("YYYY-MM-DD"),
          email: value?.email ? value?.email : "",
          password: password ? password : "",
          note: value?.note,
          createdBy: accountDetail?.id,
        };
        try {
          const resulDispatch = await dispatch(addLicenseKey(data));
          unwrapResult(resulDispatch);
          if (resulDispatch.payload.success === true) {
            notification.success({ message: `Thêm mới thành công !!!` });
            history.push(ROUTER.LICENSEKEY.LIST);
          }
        } catch (error) {
          if (error.message === "Rejected") {
            notification.error({
              message: `Lỗi: Vui lòng kiểm tra lại kết nối mạng !`,
            });
          } else {
            notification.error({ message: `Lỗi: ${error.message}` });
          }
        }
      } else {
        setErrPassword(true);
      }
    } else {
      if (avalableTime) {
        setErrAvalableTime(false);
        const data = {
          productId: idProductIsHtsc,
          customerId: idCustomer,
          licenseKeyCategoryId: value?.licenseKeyCategoryId,
          editionId: value?.editionId,
          versionId: value?.versionId,
          firstKey: value?.firstKey ? value?.firstKey : "",
          avalableTime: avalableTime || 0,
          expirationDate: "",
          startDate: "",
          note: value?.note,
          createdBy: accountDetail?.id,
        };
        try {
          const resulDispatch = await dispatch(addLicenseKey(data));
          unwrapResult(resulDispatch);
          if (resulDispatch.payload.success === true) {
            notification.success({ message: `Thêm mới thành công !!!` });
            if (resulDispatch?.payload?.data?.lastKey === null) {
              history.push(ROUTER.LICENSEKEY.LIST);
            } else {
              setLastKey(resulDispatch?.payload?.data?.lastKey);
              setCopyToClipboard({
                value: resulDispatch?.payload?.data?.lastKey,
                copied: false,
              });
              setIsModalVisible(true);
            }
          }
        } catch (error) {
          if (error.message === "Rejected") {
            notification.error({
              message: `Lỗi: Vui lòng kiểm tra lại kết nối mạng !`,
            });
          } else {
            notification.error({ message: `Lỗi: ${error.message}` });
          }
        }
      } else {
        setErrAvalableTime(true);
      }
    }
  };
  const onSubmitOutSideHTSC = async (value) => {
    let remainFile = listFileUpload.filter(
      (x) => urlFilesRemove.indexOf(x) === -1
    );
    const data = {
      productId: idProductNotHtsc?.value,
      editionId: idEditionNotHtsc?.value,
      versionId: idVersionNotHtsc?.value,
      customerId: idCustomer,
      licenseKeyCategoryId: value?.licenseKeyCategoryId,
      lastKey: value?.lastKey ? value?.lastKey : "",
      email: value?.email ? value?.email : "",
      password: value?.password ? value?.password : "",
      note: value?.note,
      createdBy: accountDetail?.id,
      expirationDate: expirationDate ? expirationDate : "",
      startDate: startDate ? startDate : "",
      urlFiles: remainFile ? remainFile : [],
    };
    try {
      const resulDispatch = await dispatch(addLicenseKey(data));
      unwrapResult(resulDispatch);
      if (resulDispatch.payload.success === true) {
        notification.success({ message: `Thêm mới thành công !!!` });
        history.push(ROUTER.LICENSEKEY.LIST);
      }
    } catch (error) {
      if (error.message === "Rejected") {
        notification.error({
          message: `Lỗi: Vui lòng kiểm tra lại kết nối mạng !`,
        });
      } else {
        notification.error({ message: `Lỗi: ${error.message}` });
      }
    }
  };

  const content = (
    <div>
      <div>
        <span className="font-semibold">
          <i className="fas fa-phone  pr-1"></i>
          SĐT
        </span>
        : {infoCustomer?.phone || "Chưa có"}
      </div>
      <div className="pt-[5px]">
        <span className="font-semibold">
          <i className="fas fa-envelope pr-1"></i>
          Email
        </span>
        : {infoCustomer?.email || "Chưa có"}
      </div>
      <div className="pt-[5px]">
        <span className="font-semibold">
          <i className="far fa-gem pr-1"></i>
          Ưu tiên
        </span>
        : {infoCustomer?.priorityTitle || "Chưa có"}
      </div>
    </div>
  );
  const contentVersion = (
    <div>
      <div>
        <span className="font-semibold">
          <i className="fas fa-code-branch pl-[2px] pr-1"></i>Version
        </span>
        : {infoVersion?.title}
      </div>
      <div>
        <span NameName=" font-semibold">
          <i className="fas fa-star-half-alt pr-1"></i>
          Domain
        </span>
        : {infoVersion?.domain || "-"}
      </div>
      <div>
        <span NameName=" font-semibold">
          <i className="fas fa-star-half-alt pr-1"></i>
          Ip
        </span>
        : {infoVersion?.ipAddress || "-"}
      </div>
    </div>
  );
  const onGeneratePassWord = () => {
    setPassword(
      generator.generate({
        length: 10,
        numbers: true,
      })
    );
  };
  const onChangeAvalableTime = (value) => {
    setAvalableTime(value);
  };

  const renderTextPlanProduct = (id) => {
    if (planProduct.find((product) => product === id)) {
      return (
        <>
          <i className="text-xs pt-[7px]">Sản phẩm quan tâm</i>
        </>
      );
    }
  };
  const callbackKeyTab = (e) => {
    setKeyTab(e);
  };
  const onChangePassword = (e) => {
    setPassword(e?.target?.value.replace(/[#<>]/g, ""));
  };
  const uploadFile = {
    className: "custom-antd-upload",
    name: "files",
    action: `${API_URL}/api/licensekey/uploads`,
    multiple: true,
    listType: "picture",
    beforeUpload: beforeUploadFile,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    showUploadList: true,
    onChange(info) {
      for (let i = 0; i < info.fileList.length; i++) {
        if (info.file.status === "done") {
          setListFileUpload([...listFileUpload, info?.file?.response?.data[0]]);
        }
      }
    },
    showUploadList: {
      showDownloadIcon: true,
      downloadIcon: (
        <CloudDownloadOutlined className="text-white hover:scale-110" />
      ),
      showRemoveIcon: true,
      removeIcon: <DeleteOutlined className="text-white hover:scale-110" />,
    },
    onDownload(e) {
      const dataFile = e?.response?.data[0];
      saveAs(
        `${API_URL}/${dataFile?.url}`,
        dataFile?.name?.length >= 50
          ? dataFile?.name?.substring(0, 50) + ". . ."
          : dataFile?.name
      );
    },
    onRemove(e) {
      seturlFilesRemove([
        ...urlFilesRemove,
        listFileUpload.find(
          (item) => item?.name === e?.response?.data[0]?.name
        ),
      ]);
    },
    onPreview(e) {
      window.open(`${API_URL}/${e?.response?.data[0]?.name}`);
    },
  };

  const onCreateOptionProductNotHtsc = async (value) => {
    const data = {
      title: value.trim(),
      urlImage: "",
      description: "",
      isHTSC: false,
    };
    try {
      const resulDispatch = await dispatch(addProduct(data));
      unwrapResult(resulDispatch);
      if (resulDispatch.payload.success === true) {
        notification.success({
          message: `Thêm mới sản phẩm thành công !!!`,
        });
        getProductNotHtsc();
        setIdProductNotHtsc({
          value: resulDispatch?.payload?.data?.id,
          label: resulDispatch?.payload?.data?.title,
        });
      }
    } catch (error) {
      if (error.message === "Rejected") {
        notification.error({
          message: `Lỗi: Vui lòng kiểm tra lại kết nối mạng !`,
        });
      } else {
        notification.error({ message: `Lỗi: ${error.message}` });
      }
    }
  };
  const onCreateOptionEditionNotHtsc = async (value) => {
    const data = {
      title: value.trim(),
      productId: idProductNotHtsc?.value,
      description: "",
      type: "",
    };
    try {
      const resulDispatch = await dispatch(addEdition(data));
      unwrapResult(resulDispatch);
      if (resulDispatch.payload.success === true) {
        notification.success({
          message: `Thêm mới edition của "${idProductNotHtsc?.label}" thành công !!!`,
        });
        getEditionByProductNotHtsc(resulDispatch?.payload?.data?.productId);
        setIdEditionNotHtsc({
          value: resulDispatch?.payload?.data?.id,
          label: resulDispatch?.payload?.data?.title,
        });
      }
    } catch (error) {
      if (error.message === "Rejected") {
        notification.error({
          message: `Lỗi: Vui lòng kiểm tra lại kết nối mạng !`,
        });
      } else {
        notification.error({ message: `Lỗi: ${error.message}` });
      }
    }
  };
  const onCreateOptionVersionNotHtsc = async (value) => {
    const data = {
      title: value.trim(),
      productId: idProductNotHtsc?.value,
      editionId: idEditionNotHtsc?.value,
      domain: "",
      ipAddress: "",
      description: "",
      urlFiles: [],
    };
    try {
      const resulDispatch = await dispatch(addVersion(data));
      unwrapResult(resulDispatch);
      if (resulDispatch.payload.success === true) {
        notification.success({
          message: `Thêm mới version của "${idEditionNotHtsc?.label}" thành công !!!`,
        });
        getVersionByEditionNotHtsc(resulDispatch?.payload?.data?.editionId);
        setIdVersionNotHtsc({
          value: resulDispatch?.payload?.data?.id,
          label: resulDispatch?.payload?.data?.title,
        });
      }
    } catch (error) {
      if (error.message === "Rejected") {
        notification.error({
          message: `Lỗi: Vui lòng kiểm tra lại kết nối mạng !`,
        });
      } else {
        notification.error({ message: `Lỗi: ${error.message}` });
      }
    }
  };

  return (
    <>
      <div className="flex justify-center ">
        <Modal
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: false }}
          cancelText="Thoát"
          width={"90%"}
          className="modal-800"
          centered
          destroyOnClose={true}
          closeIcon={
            <Tooltip title="Đóng">
              <CloseOutlined />
            </Tooltip>
          }
        >
          <div className="w-full  p-2 lg:flex overflow-x-auto text-center">
            <span className="text-white  text-[15px] pt-[2px]">{lastKey}</span>
            <div className="ml-2">
              <CopyToClipboard
                text={copyToClipboard.value}
                onCopy={() => setCopyToClipboard({ copied: true })}
              >
                <Tooltip placement="bottom" title="Nhấn để copy">
                  <button className="p-1 text-white rounded-lg bg-gray-700  ">
                    Copy <i className="fas fa-copy"></i>
                  </button>
                </Tooltip>
              </CopyToClipboard>
              <a
                className="p-1 ml-[5px] text-white rounded-lg bg-blue-600"
                download={`licensekey${infoCustomer?.name}.txt`}
                href={downloadLink}
              >
                Tải xuống <i className="fas fa-download"></i>
              </a>
            </div>
          </div>
        </Modal>
        <div className="w-full max-w-[1200px] overflow-hidden shadow-lg bg-[#262626] rounded-[10px] mb-12">
          <Tabs
            defaultActiveKey="1"
            centered
            onChange={callbackKeyTab}
            className="custom-tab-antd text-white"
          >
            <TabPane tab="Sản phẩm nội bộ" key="1"></TabPane>
            <TabPane tab="Sản phẩm của NCC khác" key="2"></TabPane>
          </Tabs>
          {keyTab === "1" ? (
            <>
              <ConfigProvider
                renderEmpty={() => (
                  <div style={{ textAlign: "center", marginTop: "10px" }}>
                    <InfoCircleOutlined
                      style={{ fontSize: 30, color: "white" }}
                    />
                    <p className="text-white mt-1">Trống</p>
                  </div>
                )}
              >
                <form onSubmit={handleSubmit(onSubmitOfHtsc)}>
                  <div className="grid grid-cols-2 md:gap-6 mx-[40px] my-[30px]">
                    <div className="col-span-2 md:col-span-1">
                      <div className="flex flex-wrap mb-6">
                        <div className="w-full pl-3 ">
                          <label className=" text-white text-sm font-bold mb-2">
                            Tổ chức
                            <span className="text-red-600 font-extrabold text-[17px] ml-1">
                              *
                            </span>
                          </label>
                          <Select
                            className="ant-select-custom border w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300  focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                            showSearch
                            allowClear
                            placeholder="Chọn tổ chức"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={onChangeOrganization}
                            value={idOrganization}
                          >
                            {listAllOrganization?.map((item) => {
                              return (
                                <Option key={item.id} value={item.id}>
                                  {item.title}
                                </Option>
                              );
                            })}
                          </Select>
                        </div>
                        <div className="w-full pl-3 pt-4">
                          <label className=" text-white text-sm font-bold mb-2">
                            Phòng ban
                          </label>
                          <Select
                            className="ant-select-custom border w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300  focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                            showSearch
                            allowClear
                            placeholder="Chọn phòng ban"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            value={idDepartment}
                            onChange={onChangeDepartment}
                          >
                            {listDepartmentByOrganization?.map((item) => {
                              return (
                                <Option key={item.id} value={item.id}>
                                  {item.title}
                                </Option>
                              );
                            })}
                          </Select>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-2 md:col-span-1">
                      <div className="grid grid-cols-6 mb-6 gap-6 ">
                        <div className="hidden md:block col-span-1 w-full mt-[75px] pl-3">
                          <i className="fas fa-angle-double-right fa-3x text-gray-500"></i>
                        </div>
                        <div className="col-span-6 md:col-span-5 ">
                          <div className="w-full pl-3 md:px-3 md:mt-[50px]">
                            <div className="flex">
                              <label className="text-white text-sm font-bold mb-2">
                                Khách hàng
                                <span className="text-red-600 font-extrabold text-[17px] ml-1">
                                  *
                                </span>
                              </label>
                              <div
                                className={`${infoCustomer === null ? "hidden" : ""
                                  } ml-auto cursor-pointer`}
                              >
                                <Popover content={content} title="Thông tin">
                                  <i className="far fa-eye text-white hover:text-purple-700"></i>
                                </Popover>
                              </div>
                            </div>
                            <Select
                              className="ant-select-custom border w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300  focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                              showSearch
                              allowClear
                              placeholder="Chọn khách hàng"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              onChange={onChangeCustomer}
                              value={idCustomer}
                            >
                              {listCustomer?.map((item) => {
                                return (
                                  <Option key={item.id} value={item.id}>
                                    {item.name}
                                  </Option>
                                );
                              })}
                            </Select>
                            <div className="mt-1 h-[10px]">
                              <span className="text-red-500">
                                {errors?.customerId &&
                                  errors?.customerId.type === "required"
                                  ? "Hãy chọn khách hàng"
                                  : ""}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-2 md:col-span-1">
                      <div className="flex flex-wrap mb-6">
                        <div className="w-full pl-3 ">
                          <label className=" text-white text-sm font-bold mb-2">
                            Sản phẩm
                            <span className="text-red-600 font-extrabold text-[17px] ml-1">
                              *
                            </span>
                          </label>
                          <Select
                            className="ant-select-custom border w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300  focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                            // showSearch
                            allowClear
                            value={idProductIsHtsc}
                            placeholder="Chọn sản phẩm"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={onChangeProductIsHtsc}
                          >
                            {listProductIsHtsc?.map((item) => {
                              return (
                                <Option value={item.id} label={item.title}>
                                  <div className="flex justify-between">
                                    {item.title}
                                    {renderTextPlanProduct(item.id)}
                                  </div>
                                </Option>
                              );
                            })}
                          </Select>
                        </div>
                        <div className="w-full pl-3 pt-4">
                          <label className=" text-white text-sm font-bold mb-2">
                            Edition
                          </label>
                          <span className="text-red-600 font-extrabold text-[17px] ml-1">
                            *
                          </span>
                          <select
                            {...register("editionId")}
                            className={`bg-[#414141] border pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300 ${errors?.editionId
                                ? "focus:ring-red-500 focus:ring-1"
                                : "focus:ring-purple-500 focus:ring-2"
                              } focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent`}
                            value={idEditionIsHtsc}
                            onChange={onChangeEditionIsHtsc}
                          >
                            {listEditionByProductIsHtsc?.map((item) => {
                              return (
                                <>
                                  <option value="" disabled selected hidden>
                                    Chọn edition
                                  </option>
                                  <option key={item.id} value={item.id}>
                                    {item.title} (
                                    {item?.type === false
                                      ? "Offline"
                                      : "Online"}
                                    )
                                  </option>
                                  ;
                                </>
                              );
                            })}
                          </select>
                          <div className="mt-1 h-[10px]">
                            <span className="text-red-500">
                              {errors?.editionId &&
                                errors?.editionId.type === "required"
                                ? "Hãy chọn edition của sản phẩm"
                                : ""}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-2 md:col-span-1">
                      <div className="grid grid-cols-6 mb-6 gap-6 ">
                        <div className="hidden md:block col-span-1 w-full mt-[75px] pl-3">
                          <i className="fas fa-angle-double-right fa-3x text-gray-500"></i>
                        </div>
                        <div className="col-span-6 md:col-span-5 ">
                          <div className="w-full pl-3 md:px-3 md:mt-[50px]">
                            <div className="flex">
                              <label className="text-white text-sm font-bold mb-2">
                                Version
                                <span className="text-red-600 font-extrabold text-[17px] ml-1">
                                  *
                                </span>
                              </label>
                              <div
                                className={`${infoVersion === null ? "hidden" : ""
                                  } ml-auto cursor-pointer`}
                              >
                                <Popover
                                  content={contentVersion}
                                  title="Thông tin"
                                >
                                  <i className="far fa-eye text-white hover:text-purple-700"></i>
                                </Popover>
                              </div>
                            </div>
                            <select
                              {...register("versionId")}
                              className={`bg-[#414141] border pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300 ${errors?.versionId
                                  ? "focus:ring-red-500 focus:ring-1"
                                  : "focus:ring-purple-500 focus:ring-2"
                                } focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent`}
                              onChange={onChangeVersionIsHtsc}
                            >
                              {listVersionByEditionIsHtsc?.map((item) => {
                                return (
                                  <>
                                    <option value="" disabled selected hidden>
                                      Chọn Version
                                    </option>
                                    <option key={item.id} value={item.id}>
                                      {item.title}
                                    </option>
                                    ;
                                  </>
                                );
                              })}
                            </select>
                            <div className="mt-1 h-[10px]">
                              <span className="text-red-500">
                                {errors?.versionId &&
                                  errors?.versionId.type === "required"
                                  ? "Hãy chọn Version của sản phẩm"
                                  : ""}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {infoEdition && infoEdition?.type === false ? (
                      <>
                        <div className="col-span-2">
                          <div className="flex flex-wrap">
                            <div className="w-full md:w-1/2 pl-3  md:px-3 mb-6 md:mb-0 ">
                              <label className=" text-white text-sm font-bold mb-2">
                                Key
                                <span className="text-red-600 font-extrabold text-[17px] ml-1">
                                  *
                                </span>
                              </label>
                              <input
                                className={`bg-[#414141] border pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300 ${errors?.firstKey
                                    ? "focus:ring-red-500 focus:ring-1"
                                    : "focus:ring-purple-500 focus:ring-2"
                                  } focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent`}
                                type="text"
                                placeholder="Nhập key"
                                {...register("firstKey", {
                                  required: true,
                                })}
                              />
                              <div className="mt-1 h-[10px]">
                                <span className="text-red-500">
                                  {errors?.firstKey &&
                                    errors?.firstKey.type === "required"
                                    ? "Hãy nhập key "
                                    : ""}
                                </span>
                              </div>
                            </div>
                            <div className="w-full md:w-1/2 pl-3  md:px-3 mb-6 md:mb-0 ">
                              <label className=" text-white text-sm font-bold mb-2">
                                Thời gian sử dụng (ngày)
                                <span className="text-red-600 font-extrabold text-[17px] ml-1">
                                  *
                                </span>
                              </label>

                              <Select
                                className="ant-select-custom border w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300  focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                                showSearch
                                optionFilterProp="children"
                                onChange={onChangeAvalableTime}
                                onSearch={(e) => {
                                  if (e.length > 0) setNewOption(e);
                                }}
                                filterOption={(input, option) =>
                                  option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {newOption &&
                                  dataAvalableTime.filter(
                                    (o) => o === newOption
                                  ).length === 0 && (
                                    <Option key={newOption} value={newOption}>
                                      {newOption}
                                    </Option>
                                  )}
                                {dataAvalableTime.map((item, index) => {
                                  return (
                                    <Option key={index} value={item}>
                                      {item}
                                    </Option>
                                  );
                                })}
                              </Select>
                              <div className="mt-1 h-[10px]">
                                <span className="text-red-500">
                                  {errAvalableTime
                                    ? "Hãy chọn thời gian sử dụng"
                                    : ""}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : infoEdition && infoEdition?.type === true ? (
                      <>
                        <div className="col-span-2">
                          <div className="flex flex-wrap ">
                            <div className="w-full md:w-1/2 pl-3 mt-[13px] mb-6 md:mb-0  md:px-3 ">
                              <label className=" text-white text-sm font-bold mb-2">
                                Tài khoản
                                <span className="text-red-600 font-extrabold text-[17px] ml-1">
                                  *
                                </span>
                              </label>
                              <input
                                className={`bg-[#414141] border pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300 ${errors?.email
                                    ? "focus:ring-red-500 focus:ring-1"
                                    : "focus:ring-purple-500 focus:ring-2"
                                  } focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent`}
                                type="text"
                                placeholder="Tài khoản"
                                {...register("email", {
                                  required: true,
                                  pattern: validateEmail,
                                })}
                              />
                              <div className="mt-1 h-[10px]">
                                <span className="text-red-500">
                                  {errors?.email &&
                                    errors?.email.type === "required"
                                    ? "Hãy nhập tài khoản "
                                    : ""}
                                  {errors?.email &&
                                    errors.email.type === "pattern"
                                    ? "Tài khoản có định dạng email"
                                    : ""}
                                </span>
                              </div>
                            </div>
                            <div className="w-full md:w-1/2 pl-3 mt-[13px]  md:px-3 mb-6 md:mb-0 ">
                              <div className="flex">
                                <label className=" text-white text-sm font-bold mb-2">
                                  Mật khẩu
                                  <span className="text-red-600 font-extrabold text-[17px] ml-1">
                                    *
                                  </span>
                                </label>
                              </div>
                              <div className="relative">
                                <input
                                  value={password}
                                  className={`bg-[#414141] border pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300 ${errPassword
                                      ? "focus:ring-red-500 focus:ring-1"
                                      : "focus:ring-purple-500 focus:ring-2"
                                    } focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent`}
                                  placeholder="Mật khẩu"
                                  onChange={onChangePassword}
                                />
                                <div className="absolute top-[49%] right-0 transform -translate-y-1/2 cursor-pointer">
                                  <a
                                    onClick={onGeneratePassWord}
                                    className=" text-white border p-[11px] rounded-lg bg-blue-500 text-xs hover:bg-blue-600 active:bg-blue-400"
                                  >
                                    Tạo mật khẩu
                                  </a>
                                </div>
                              </div>

                              <div className="mt-1 h-[10px]">
                                <span className="text-red-500">
                                  {errPassword ? "Hãy tạo mật khẩu" : ""}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-span-2">
                          <div className="flex flex-wrap">
                            <div className="w-full md:w-1/2 pl-3  md:px-3 mb-6 md:mb-0">
                              <label className=" text-white text-sm font-bold mb-2">
                                Ngày kích hoạt
                                <span className="text-red-600 font-extrabold text-[17px] ml-1">
                                  *
                                </span>
                              </label>
                              <DatePicker
                                suffixIcon={
                                  <CalendarOutlined className="text-white" />
                                }
                                placeholder="Ngày kích hoạt"
                                format="DD/MM/YYYY"
                                onChange={onChangeStartDate}
                                style={{
                                  borderRadius: "6px",
                                  width: "100%",
                                  height: "40px",
                                  backgroundColor: "#414141",
                                  color: "#fff",
                                }}
                                className="custom-datepicker bg-[#414141] hover:ring-2 hover:ring-purple-300 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                              />
                            </div>
                            <div className="w-full md:w-1/2 pl-3  md:px-3 mb-6 md:mb-0">
                              <label className=" text-white text-sm font-bold mb-2">
                                Ngày hết hạn
                                <span className="text-red-600 font-extrabold text-[17px] ml-1">
                                  *
                                </span>
                              </label>
                              <DatePicker
                                suffixIcon={
                                  <CalendarOutlined className="text-white" />
                                }
                                placeholder="Ngày hết hạn"
                                format="DD/MM/YYYY"
                                onChange={onChangeExpirationDate}
                                style={{
                                  borderRadius: "6px",
                                  width: "100%",
                                  height: "40px",
                                  backgroundColor: "#414141",
                                  color: "#fff",
                                }}
                                className="custom-datepicker hover:ring-2 hover:ring-purple-300 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    <div className="col-span-2 md:col-span-1">
                      <div className="flex flex-wrap">
                        <div className="w-full pl-3">
                          <label className=" text-white text-sm font-bold mb-2">
                            Danh mục
                            <span className="text-red-600 font-extrabold text-[17px] ml-1">
                              *
                            </span>
                          </label>
                          <select
                            {...register("licenseKeyCategoryId", {
                              required: true,
                            })}
                            className="bg-[#414141] border h-[40px] pl-[10px] pr-8 rounded-md w-full hover:ring-2 hover:ring-purple-300 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                          // defaultValue="1"
                          >
                            {listAllLicenseKeyCategory.map((item) => {
                              return (
                                <>
                                  <option value="" disabled selected hidden>
                                    Chọn danh mục
                                  </option>
                                  <option key={item.id} value={item.id}>
                                    {item.title}
                                  </option>
                                  ;
                                </>
                              );
                            })}
                          </select>
                          <div className="mt-1 h-[10px]">
                            <span className="text-red-500">
                              {errors?.licenseKeyCategoryId &&
                                errors?.licenseKeyCategoryId.type === "required"
                                ? "Hãy chọn danh mục"
                                : ""}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-2">
                      <div className="flex flex-wrap mb-8">
                        <div className="w-full pl-3 md:px-3 ">
                          <label className=" text-white text-sm font-bold mb-2">
                            Ghi chú
                          </label>
                          <textarea
                            {...register("note")}
                            className="bg-[#414141] pl-[10px] pt-[10px] w-full border rounded-[6px] hover:ring-2 hover:ring-purple-300 focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                            rows={5}
                            placeholder="Ghi chú"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center mb-[30px]">
                    <div className="mx-2">
                      <ButtonCancel onClick={() => history.goBack()} />
                    </div>
                    <div className="mx-2">
                      <ButtonSubmit titleButton="Xác nhận" />
                    </div>
                  </div>
                </form>
              </ConfigProvider>{" "}
            </>
          ) : (
            <>
              <ConfigProvider
                renderEmpty={() => (
                  <div style={{ textAlign: "center", marginTop: "10px" }}>
                    <InfoCircleOutlined
                      style={{ fontSize: 30, color: "white" }}
                    />
                    <p className="text-white mt-1">Trống</p>
                  </div>
                )}
              >
                <form onSubmit={handleSubmit(onSubmitOutSideHTSC)}>
                  <div className="grid grid-cols-2 md:gap-6 mx-[40px] my-[30px]">
                    <div className="col-span-2 md:col-span-1">
                      <div className="flex flex-wrap mb-6">
                        <div className="w-full pl-3 ">
                          <label className=" text-white text-sm font-bold mb-2">
                            Tổ chức
                            <span className="text-red-600 font-extrabold text-[17px] ml-1">
                              *
                            </span>
                          </label>
                          <Select
                            className="ant-select-custom border w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300  focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                            showSearch
                            allowClear
                            placeholder="Chọn tổ chức"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={onChangeOrganization}
                            value={idOrganization}
                          >
                            {listAllOrganization?.map((item) => {
                              return (
                                <Option key={item.id} value={item.id}>
                                  {item.title}
                                </Option>
                              );
                            })}
                          </Select>
                        </div>
                        <div className="w-full pl-3 pt-4">
                          <label className=" text-white text-sm font-bold mb-2">
                            Phòng ban
                          </label>
                          <Select
                            className="ant-select-custom border w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300  focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                            showSearch
                            allowClear
                            placeholder="Chọn phòng ban"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            value={idDepartment}
                            onChange={onChangeDepartment}
                          >
                            {listDepartmentByOrganization?.map((item) => {
                              return (
                                <Option key={item.id} value={item.id}>
                                  {item.title}
                                </Option>
                              );
                            })}
                          </Select>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-2 md:col-span-1">
                      <div className="grid grid-cols-6 mb-6 gap-6 ">
                        <div className="hidden md:block col-span-1 w-full mt-[75px] pl-3">
                          <i className="fas fa-angle-double-right fa-3x text-gray-500"></i>
                        </div>
                        <div className="col-span-6 md:col-span-5 ">
                          <div className="w-full pl-3 md:px-3 md:mt-[50px]">
                            <div className="flex">
                              <label className="text-white text-sm font-bold mb-2">
                                Khách hàng
                                <span className="text-red-600 font-extrabold text-[17px] ml-1">
                                  *
                                </span>
                              </label>
                              <div
                                className={`${infoCustomer === null ? "hidden" : ""
                                  } ml-auto cursor-pointer`}
                              >
                                <Popover content={content} title="Thông tin">
                                  <i className="far fa-eye text-white hover:text-purple-700"></i>
                                </Popover>
                              </div>
                            </div>
                            <Select
                              className="ant-select-custom border w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300  focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                              showSearch
                              allowClear
                              placeholder="Chọn khách hàng"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              onChange={onChangeCustomer}
                              value={idCustomer}
                            >
                              {listCustomer?.map((item) => {
                                return (
                                  <Option key={item.id} value={item.id}>
                                    {item.name}
                                  </Option>
                                );
                              })}
                            </Select>
                            <div className="mt-1 h-[10px]">
                              <span className="text-red-500">
                                {errors?.customerId &&
                                  errors?.customerId.type === "required"
                                  ? "Hãy chọn khách hàng"
                                  : ""}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-2">
                      <div className="flex flex-wrap">
                        <div className="w-full md:w-1/2 pl-3  md:px-3 mb-6 md:mb-0 ">
                          <label className=" text-white text-sm font-bold mb-2">
                            Sản phẩm
                            <span className="text-red-600 font-extrabold text-[17px] ml-1">
                              *
                            </span>
                          </label>

                          <Creatable
                            formatCreateLabel={(inputText) =>
                              `Thêm mới "${inputText}"`
                            }
                            isSearchable={true}
                            className="custom-react-select"
                            placeholder="Chọn sản phẩm"
                            onChange={onChangeProductNotHtsc}
                            onCreateOption={onCreateOptionProductNotHtsc}
                            options={listProductNotHtsc}
                            value={idProductNotHtsc}
                          />
                        </div>
                        <div className="w-full md:w-1/2 pl-3  md:px-3 mb-6 md:mb-0 ">
                          <label className=" text-white text-sm font-bold mb-2">
                            Edition
                          </label>
                          <Creatable
                            formatCreateLabel={(inputText) =>
                              `Thêm mới "${inputText}"`
                            }
                            isClearable
                            isDisabled={idProductNotHtsc ? false : true}
                            placeholder={
                              !idProductNotHtsc
                                ? "Chọn sản phẩm trước"
                                : "Chọn edition"
                            }
                            isSearchable={true}
                            className="custom-react-select"
                            onChange={onChangeEditionNotHtsc}
                            onCreateOption={onCreateOptionEditionNotHtsc}
                            options={listEditionByProductNotHtsc}
                            value={idEditionNotHtsc}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-span-2">
                      <div className="flex flex-wrap">
                        <div className="w-full md:w-1/2 pl-3 md:px-3 mb-6 md:mb-0 ">
                          <label className=" text-white text-sm font-bold mb-2">
                            Version
                          </label>
                          <Creatable
                            formatCreateLabel={(inputText) =>
                              `Thêm mới "${inputText}"`
                            }
                            isClearable
                            isDisabled={idEditionNotHtsc ? false : true}
                            placeholder={
                              !idEditionNotHtsc
                                ? "Chọn edition trước"
                                : "Chọn version"
                            }
                            isSearchable={true}
                            className="custom-react-select"
                            onChange={onChangeVersionNotHtsc}
                            onCreateOption={onCreateOptionVersionNotHtsc}
                            options={listVersionByEditionNotHtsc}
                            value={idVersionNotHtsc}
                          />
                        </div>
                        <div className="w-full md:w-1/2 pl-3 md:px-3 mb-6 md:mb-0 ">
                          <label className=" text-white text-sm font-bold mb-2">
                            Key
                          </label>
                          <input
                            className={`bg-[#414141] border pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300 ${errors?.lastKey
                                ? "focus:ring-red-500 focus:ring-1"
                                : "focus:ring-purple-500 focus:ring-2"
                              } focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent`}
                            type="text"
                            placeholder="Nhập key"
                            {...register("lastKey")}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-span-2">
                      <div className="flex flex-wrap ">
                        <div className="w-full md:w-1/2 pl-3 mt-[13px] mb-6 md:mb-0  md:px-3 ">
                          <label className=" text-white text-sm font-bold mb-2">
                            Tài khoản
                          </label>
                          <input
                            className={`bg-[#414141] border pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300 ${errors?.email
                                ? "focus:ring-red-500 focus:ring-1"
                                : "focus:ring-purple-500 focus:ring-2"
                              } focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent`}
                            type="text"
                            placeholder="Tài khoản"
                            {...register("email")}
                          />
                          {/* <div className="mt-1 h-[10px]">
                            <span className="text-red-500">
                              {errors?.email && errors.email.type === "pattern"
                                ? "Định dạng Email không hợp lệ"
                                : ""}
                            </span>
                          </div> */}
                        </div>
                        <div className="w-full md:w-1/2 pl-3 mt-[13px]  md:px-3 mb-6 md:mb-0 ">
                          <div className="flex">
                            <label className=" text-white text-sm font-bold mb-2">
                              Mật khẩu
                            </label>
                          </div>
                          <div className="relative">
                            <input
                              {...register("password")}
                              className={`bg-[#414141] border pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300 focus:ring-purple-500 focus:ring-2 focus:outline-none focus:border-transparent`}
                              placeholder="Mật khẩu"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-2">
                      <div className="flex flex-wrap">
                        <div className="w-full md:w-1/2 pl-3  md:px-3 mb-6 md:mb-0">
                          <label className=" text-white text-sm font-bold mb-2">
                            Ngày kích hoạt
                            <span className="text-red-600 font-extrabold text-[17px] ml-1">
                              *
                            </span>
                          </label>
                          <DatePicker
                            suffixIcon={
                              <CalendarOutlined className="text-white" />
                            }
                            placeholder="Ngày kích hoạt"
                            format="DD/MM/YYYY"
                            onChange={onChangeStartDate}
                            style={{
                              borderRadius: "6px",
                              width: "100%",
                              height: "40px",
                              backgroundColor: "#414141",
                              color: "#fff",
                            }}
                            className="custom-datepicker bg-[#414141] hover:ring-2 hover:ring-purple-300 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                          />
                        </div>
                        <div className="w-full md:w-1/2 pl-3  md:px-3 mb-6 md:mb-0">
                          <label className=" text-white text-sm font-bold mb-2">
                            Ngày hết hạn
                            <span className="text-red-600 font-extrabold text-[17px] ml-1">
                              *
                            </span>
                          </label>
                          <DatePicker
                            suffixIcon={
                              <CalendarOutlined className="text-white" />
                            }
                            placeholder="Ngày hết hạn"
                            format="DD/MM/YYYY"
                            onChange={onChangeExpirationDate}
                            style={{
                              borderRadius: "6px",
                              width: "100%",
                              height: "40px",
                              backgroundColor: "#414141",
                              color: "#fff",
                            }}
                            className="custom-datepicker hover:ring-2 hover:ring-purple-300 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-span-2 md:col-span-1">
                      <div className="flex flex-wrap">
                        <div className="w-full pl-3">
                          <label className=" text-white text-sm font-bold mb-2">
                            Danh mục
                            <span className="text-red-600 font-extrabold text-[17px] ml-1">
                              *
                            </span>
                          </label>
                          <select
                            {...register("licenseKeyCategoryId", {
                              required: true,
                            })}
                            className="bg-[#414141] border h-[40px] pl-[10px] pr-8 rounded-md w-full hover:ring-2 hover:ring-purple-300 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                          // defaultValue="1"
                          >
                            {listAllLicenseKeyCategory.map((item) => {
                              return (
                                <>
                                  <option value="" disabled selected hidden>
                                    Chọn danh mục
                                  </option>
                                  <option key={item.id} value={item.id}>
                                    {item.title}
                                  </option>
                                  ;
                                </>
                              );
                            })}
                          </select>
                          <div className="mt-1 h-[10px]">
                            <span className="text-red-500">
                              {errors?.licenseKeyCategoryId &&
                                errors?.licenseKeyCategoryId.type === "required"
                                ? "Hãy chọn danh mục"
                                : ""}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-2">
                      <div className="flex flex-wrap">
                        <div className="w-full md:w-1/2 px-3 mb-3">
                          <Upload {...uploadFile}>
                            <Button>
                              <UploadOutlined /> Tải tệp đính kèm
                            </Button>
                          </Upload>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-span-2">
                      <div className="flex flex-wrap max-h-[200px] overflow-x-auto">
                        <div className="uploaded-file-list">
                          {listFileUpload &&
                            listFileUpload.map((item, index) => {
                              return (
                                <div
                                  className="uploaded-file relative"
                                  key={index}
                                >
                                  <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={`${API_URL}/${item}`}
                                    className="uploaded-file-list-item mx-3"
                                  >
                                    {item?.split(".")[
                                      item?.split(".")?.length - 1
                                    ] === "jpg" ||
                                    item?.split(".")[
                                      item?.split(".")?.length - 1
                                    ] === "png" ? (
                                      <Image
                                        preview={false}
                                        style={{
                                          height: 40,
                                          width: 40,
                                          objectFit: "cover",
                                        }}
                                        src={`${API_URL}/${item}`}
                                      />
                                    ) : (
                                      <i className="fas fa-file-archive fa-3x text-white"></i>
                                    )}
                                    <div className="uploaded-file-list-item-title">
                                      <a
                                        target="_blank"
                                        href={`${API_URL}/${item}`}
                                        rel="noreferrer"
                                        className="text-gray-300"
                                      >
                                        {item?.split("/")[2]?.split(".")[0]}
                                      </a>
                                    </div>
                                  </a>
                                  <div className="show-icon-dowload-file ">
                                    <Tooltip title="Tải xuống">
                                      <VerticalAlignBottomOutlined
                                        onClick={() => saveFile(item)}
                                        className="bg-slate-100 hover:bg-slate-300 p-[5px] opacity-75 text-lg cursor-pointer hover:scale-110 transition-all rounded-md"
                                      />
                                    </Tooltip>
                                    <Tooltip title="Xóa">
                                      <DeleteOutlined
                                        onClick={() =>
                                          setListFileUpload(
                                            listFileUpload.filter(
                                              (e) => e !== item
                                            )
                                          )
                                        }
                                        className="ml-2 bg-slate-100 hover:bg-slate-300 p-[5px] opacity-75 text-lg cursor-pointer hover:scale-110 transition-all rounded-md"
                                      />
                                    </Tooltip>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div> */}
                    <div className="col-span-2">
                      <div className="flex flex-wrap mb-8">
                        <div className="w-full pl-3 md:px-3 ">
                          <label className=" text-white text-sm font-bold mb-2">
                            Ghi chú
                          </label>
                          <textarea
                            {...register("note")}
                            className="bg-[#414141] pl-[10px] pt-[10px] w-full border rounded-[6px] hover:ring-2 hover:ring-purple-300 focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                            rows={5}
                            placeholder="Ghi chú"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center mb-[30px]">
                    <div className="mx-2">
                      <ButtonCancel onClick={() => history.goBack()} />
                    </div>
                    <div className="mx-2">
                      <ButtonSubmit titleButton="Xác nhận" />
                    </div>
                  </div>
                </form>
              </ConfigProvider>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default LicenseKeyAdd;
