import React, { useEffect, useMemo } from "react";
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CImg,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { logoutUser } from "../../pages/Auth/Login/LoginSlice";
import { Modal, notification } from "antd";
import { useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import { getAccountDetail } from "./../../pages/Auth/AccountDetail/AccountDetailSlice";
import { getUserInfo } from "./../../Utils/localStorage/GetUserInfo";
import avtUser from "../../assets/image/user.png";
import { API_URL } from "./../../Utils/Config";
import ROUTER from "../../routers/routerLink";
import { ExportOutlined } from "@ant-design/icons";

const TheHeaderDropdown = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const accountDetail = useSelector(
    (state) => state.accountDetail.accountDetail
  );
  const LogOut = async () => {
    Modal.confirm({
      title: "Bạn chắc chắn muốn đăng xuất?",
      okText: "Đăng xuất",
      cancelText: "Hủy",
      icon: <ExportOutlined />,
      onOk: () => {
        dispatch(logoutUser());
        history.push("/login");
        notification.success({ message: "Đăng xuất thành công !" });
      },
      onCancel() {},
    });
  };
  const data = useMemo(() => {
    return jwt_decode(getUserInfo("token"));
  }, [getUserInfo("token")]);
  useEffect(() => {
    // let data = jwt_decode(getUserInfo("token"));
    dispatch(getAccountDetail(data.sub));
  }, [dispatch, data]);

  return (
    <CDropdown inNav className="c-header-nav-items mx-2" direction="down">
      <CDropdownToggle
        className="c-header-nav-link border-l-2 border-opacity-50 "
        caret={false}
      >
        <div className="c-avatar ml-2">
          <CImg
            src={
              accountDetail?.avatar
                ? `${API_URL}/${accountDetail?.avatar}`
                : avtUser
            }
            style={{
              objectFit: "cover",
            }}
            className="c-avatar-img sm:w-full sm:h-full w-2/3 h-2/3 ring-2 ring-blue-300"
            alt=""
          />
        </div>
        <div className="hidden sm:block">
          <span className="text-white font-semibold pl-2 text-[15px] capitalize ">
            {accountDetail?.name} <i className="fas fa-caret-down pl-1"></i>
          </span>
        </div>
      </CDropdownToggle>
      <CDropdownMenu
        className="mt-2 pt-0 bg-[#2d2d2d] border-none rounded-[8px]"
        placement="bottom-end"
      >
        <CDropdownItem
          header
          tag="div"
          className="text-white text-[15px] bg-[#262626] border-b-2 border-gray-400"
        >
          <div>
            <span className="capitalize text-[17px]">
              <strong>{accountDetail?.name}</strong>
            </span>
          </div>
          <div>
            <span className="capitalize font-thin text-[13px]">
              {accountDetail?.roleName}
            </span>
          </div>
        </CDropdownItem>
        <CDropdownItem className="text-white hover:bg-[#4f7b77]">
          <Link className="text-white" to={ROUTER.AUTH.AUTHDETAIL}>
            <CIcon name="cil-user" className="mfe-2" />
            Cập nhật tài khoản
          </Link>
        </CDropdownItem>
        <CDropdownItem className="text-white hover:bg-[#4f7b77]">
          <Link className="text-white" to={ROUTER.AUTH.CHANGEPASSWORD}>
            <CIcon name="cil-settings" className="mfe-2" />
            Đổi mật khẩu
          </Link>
        </CDropdownItem>
        {/* <CDropdownItem divider /> */}
        <CDropdownItem
          className="text-red-500 hover:bg-[#4f7b77]"
          onClick={LogOut}
        >
          <CIcon name="cil-lock-locked" className="mfe-2" />
          Đăng xuất
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default TheHeaderDropdown;
