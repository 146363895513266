import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import ButtonCancel from "../../../components/Button/ButtonCancel";
import ButtonSubmit from "../../../components/Button/ButtonSubmit";
import { notification, Switch } from "antd";
import { unwrapResult } from "@reduxjs/toolkit";
import ROUTER from "../../../routers/routerLink";
import Api from "../../../Utils/Api";
import { getAllProduct } from "../../Product/ProductSlice";
import { updateEdition } from "../EditionSlice";

const EditionEdit = () => {
  const { id, idproduct } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [type, setType] = useState(false);
  const [productIsHtsc, SetProductIsHtsc] = useState(false);
  const [nameProduct, setNameProduct] = useState("");
  const {
    handleSubmit,
    register,
    // setError,
    formState: { errors },
    reset,
  } = useForm();
  const getEditionById = async (id) => {
    try {
      let response = await Api.get(`api/edition/getbyid/${id}`);
      if (response.status === 200) {
        reset(response?.data?.data);
        setType(response?.data?.data.type);
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (id) {
      getEditionById(id);
    }
    dispatch(getAllProduct());
  }, [dispatch, id]);

  const getProductById = async (id) => {
    try {
      let response = await Api.get(`api/product/getbyid/${id}`);
      if (response.status === 200) {
        SetProductIsHtsc(response?.data?.data?.isHTSC);
        setNameProduct(response?.data?.data?.title);
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (idproduct) {
      getProductById(idproduct);
    }
  }, [dispatch, idproduct]);

  const onSubmit = async (value) => {
    const data = {
      type: type,
      productId: idproduct,
      title: value?.title?.trim(),
      description: value?.description?.trim() || "",
    };
    try {
      const resulDispatch = await dispatch(updateEdition({ id, data }));
      unwrapResult(resulDispatch);
      if (resulDispatch.payload.success === true) {
        notification.success({ message: `Sửa thành công !!!` });
        history.push(`${ROUTER.PRODUCT.EDITION.LIST}/${idproduct}`);
      }
    } catch (error) {
      if (error.message === "Rejected") {
        notification.error({
          message: `Lỗi: Vui lòng kiểm tra lại kết nối mạng !`,
        });
      } else {
        notification.error({ message: `Lỗi: ${error.message}` });
      }
    }
  };

  return (
    <>
      <div className="flex justify-center mb-10">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full max-w-[1000px] overflow-hidden shadow-lg bg-[#262626] rounded-[10px]"
        >
          <div className="mx-[30px] my-[30px]">
            <div className="flex flex-wrap mb-3">
              <div className="w-full md:w-1/2 px-3 ">
                <label className=" text-white text-sm font-bold mb-2">
                  Edition
                </label>
                <span className="text-red-600 font-extrabold text-[17px] ml-1">
                  *
                </span>
                <input
                  className={`bg-[#414141] border pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300 ${
                    errors?.title
                      ? "focus:ring-red-500 focus:ring-1"
                      : "focus:ring-purple-500 focus:ring-2"
                  } focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent`}
                  type="text"
                  placeholder="Edition"
                  {...register("title", {
                    required: true,
                  })}
                />
                <div className="mt-1 h-[10px]">
                  <span className="text-red-500">
                    {errors?.title && errors?.title.type === "required"
                      ? "Hãy nhập version"
                      : ""}
                  </span>
                </div>
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label className=" text-white text-sm font-bold mb-2">
                  Sản phẩm
                  <span className="text-red-600 font-extrabold text-[17px] ml-1">
                    *
                  </span>
                </label>
                <input
                  className="text-gray-600 bg-gray-300 border pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300 focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                  type="text"
                  value={nameProduct}
                  disabled
                />
              </div>
            </div>
            {productIsHtsc && (
              <div className="flex flex-wrap mb-3">
                <div className="w-full md:w-1/2 px-3 ">
                  <label className=" w-full text-white text-sm font-bold mb-2">
                    Loại
                    <span className="text-red-600 font-extrabold text-[17px] ml-1">
                      *
                    </span>
                  </label>
                  <Switch
                    checkedChildren="Online"
                    unCheckedChildren="Offline"
                    checked={type}
                    onChange={(value) => setType(value)}
                  />
                </div>
              </div>
            )}
            <div className="flex flex-wrap mb-8">
              <div className="w-full px-3">
                <label className=" text-white text-sm font-bold mb-2">
                  Mô tả
                </label>
                <textarea
                  className="bg-[#414141] pl-[10px] pt-[10px] w-full border rounded-[6px] hover:ring-2 hover:ring-purple-300 focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                  rows={5}
                  placeholder="Mô tả"
                  {...register("description")}
                />
                {/* <div className="mt-1 h-[10px]">
                  <span className="text-red-500">
                    {errors?.description &&
                    errors?.description.type === "required"
                      ? "Hãy nhập vài dòng mô tả sản phẩm"
                      : ""}
                  </span>
                </div> */}
              </div>
            </div>
            <div className="flex justify-center ">
              <div className="mx-2">
                <ButtonCancel onClick={() => history.goBack()} />
              </div>
              <div className="mx-2">
                <ButtonSubmit titleButton="Xác nhận" />
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default EditionEdit;
