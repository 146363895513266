import React from "react";
import "./App.css";
import "../src/assets/scss/style.scss";
import TheLayout from "./components/MainLayout/TheLayout";
import { Route, Redirect, Switch } from "react-router-dom";
import Login from "./pages/Auth/Login/Login";
import ForgotPassword from "./pages/Auth/ForgotPassword/ForgotPassword";
import decode from "jwt-decode";
import { useDispatch } from "react-redux";
import { logoutUser } from "./pages/Auth/Login/LoginSlice";
import ROUTER from "./routers/routerLink";

const jwtSecret = process.env.JWT_SECRET
  ? process.env.JWT_SECRET
  : "license_plate_hti";
function App() {
  const dispatch = useDispatch();
  const checkAuth = () => {
    const token = localStorage.getItem("token");
    if (!token) {
      dispatch(logoutUser());
      return false;
    }
    try {
      const { exp } = decode(token, jwtSecret);
      if (exp < parseInt(new Date().getTime() / 1000)) {
        window.location.href = "/login";
        dispatch(logoutUser());
        return false;
      } else {
        return true;
      }
    } catch (error) {
      dispatch(logoutUser());
      return false;
    }
  };
  return (
    <>
      <Switch>
        <Route path={ROUTER.AUTH.LOGIN} component={Login} exact />
        <Route
          path={ROUTER.AUTH.FORGOTPASSWORD}
          component={ForgotPassword}
          exact
        />
        <Route
          path={ROUTER.HOME.HOME}
          render={(props) =>
            checkAuth() ? (
              <TheLayout />
            ) : (
              <Redirect
                to={{
                  pathname: ROUTER.AUTH.LOGIN,
                  state: { from: props.location },
                }}
              />
            )
          }
        />
      </Switch>
    </>
  );
}

export default App;
