import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Switch,
  notification,
  Button,
  ConfigProvider,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import Api from "../../Utils/Api";
import { unwrapResult } from "@reduxjs/toolkit";
import { sendMailExpirationDate } from "./LicenseKeySendMailExpirationDateSlice";
import moment from "moment";
import Creatable from "react-select/creatable";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { EDITORCONFIGURATION } from "./../../Utils/Config";

const LicenseKeySendMailExpirationDate = () => {
  const dispatch = useDispatch();
  const accountDetail = useSelector(
    (state) => state?.accountDetail?.accountDetail
  );
  const isLoading = useSelector(
    (state) => state?.licenseKeySendMail?.isLoading
  );
  const { id } = useParams();
  const [messager, setMessager] = useState("");
  const [listBcc, setListBcc] = useState([]);
  const [listSendTo, setListSendTo] = useState([]);
  // const [historyMail, setHistoryMail] = useState([]);
  const [infoLicenseKey, setInfoLicenseKey] = useState("");

  const [form] = Form.useForm();

  // const getHistoryMail = async (id) => {
  //   try {
  //     let response = await Api.get(`api/mail/historty/${id}/get`);
  //     if (response.status === 200) {
  //       setHistoryMail(response?.data?.data);
  //     }
  //   } catch (error) {}
  // };
  const getTemplateMailByLicenseKey = async (id) => {
    try {
      let response = await Api.get(
        `api/templatemailauto/getbylicensekeyid/${id}`
      );
      if (response.status === 200) {
        form.setFieldsValue({
          subject: response?.data?.data?.subject,
        });
        setMessager(response?.data?.data?.context);
      }
    } catch (error) {}
  };

  const getSendTo = async (id) => {
    try {
      let response = await Api.get(`/api/customer/${id}/licensekey`);
      const listOption = [];
      if (response.status === 200) {
        const dataRes = response?.data?.data;
        for (let i = 0; i < dataRes.length; i++) {
          listOption.push({
            label: `${dataRes[i]?.name} (${dataRes[i]?.email})`,
            value: dataRes[i]?.email,
          });
        }
        setListSendTo(listOption);
      }
    } catch (error) {}
  };

  const getLicenseById = async (id) => {
    try {
      let response = await Api.get(`api/licensekey/getbyid/${id}`);
      if (response.status === 200) {
        setInfoLicenseKey(response?.data?.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (id) {
      // getHistoryMail(id);
      getSendTo(id);
      getLicenseById(id);
      getTemplateMailByLicenseKey(id);
    }
  }, [id]);

  const getBcc = async (id) => {
    try {
      let response = await Api.get(`/api/account/get/${id}/manager`);
      const listOption = [];
      if (response.status === 200) {
        const dataRes = response?.data?.data;

        for (let i = 0; i < dataRes.length; i++) {
          listOption.push({
            label: dataRes[i]?.name,
            value: dataRes[i]?.email,
          });
        }
        setListBcc(listOption);
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (accountDetail?.id) {
      getBcc(accountDetail?.id);
    }
  }, [accountDetail]);

  const onFinish = async (value) => {
    const sentoList = [];
    if (value?.sendTo.length > 0) {
      value?.sendTo.map((e) => sentoList.push(e.value));
    }
    const bccList = [];
    if (value?.sendBcc) {
      value?.sendBcc.map((e) => bccList.push(e.value));
    }
    const data = {
      accountId: accountDetail?.id,
      licenseKeyId: id,
      sendTo: sentoList,
      sendBcc: bccList || [],
      subject: value?.subject,
      messager: messager,
      sendToCreated: value?.sendToCreated || false,
    };
    try {
      const resulDispatch = await dispatch(sendMailExpirationDate(data));
      unwrapResult(resulDispatch);
      if (resulDispatch.payload.success === true) {
        notification.success({ message: `${resulDispatch?.payload?.data}` });
        // getHistoryMail(id);
      }
    } catch (error) {
      if (error.message === "Rejected") {
        notification.error({
          message: `Lỗi: Vui lòng kiểm tra lại kết nối mạng !`,
        });
      } else {
        notification.error({ message: `Lỗi: ${error.message}` });
      }
    }
  };

  return (
    <>
      <div className="lg:flex lg:justify-center">
        <ConfigProvider
          renderEmpty={() => (
            <div style={{ textAlign: "center", marginTop: "10px" }}>
              <InfoCircleOutlined style={{ fontSize: 30, color: "white" }} />
              <p className="text-white mt-1">Trống</p>
            </div>
          )}
        >
          <Form
            layout="vertical"
            onFinish={onFinish}
            form={form}
            className="w-full max-w-[1150px] overflow-hidden shadow-lg bg-[#262626] rounded-[10px] mb-12"
          >
            <div className="p-7">
              <div className="flex flex-wrap ">
                <div className="w-1/2">
                  <Form.Item
                    name="sendTo"
                    className="w-full px-3"
                    label="Người nhận"
                    rules={[
                      {
                        required: true,
                        message: "Hãy chọn người nhận",
                      },
                    ]}
                  >
                    <Creatable
                      isSearchable={true}
                      className="custom-react-select"
                      placeholder="Người nhận"
                      isMulti
                      options={listSendTo}
                    />
                  </Form.Item>
                </div>
                <div className="w-1/2">
                  <Form.Item name="sendBcc" className="w-full px-3" label="Bcc">
                    <Creatable
                      isSearchable={true}
                      className="custom-react-select"
                      placeholder="Bcc"
                      isMulti
                      options={listBcc}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="flex flex-wrap ">
                <div className="w-full">
                  <Form.Item
                    name="sendToCreated"
                    className="w-full px-3"
                    label="Gửi người tạo"
                  >
                    <Switch />
                  </Form.Item>
                </div>
              </div>
              <div className="flex flex-wrap ">
                <div className="w-full">
                  <Form.Item
                    name="subject"
                    className="w-full px-3"
                    label="Tiêu đề"
                    rules={[
                      {
                        required: true,
                        message: "Hãy nhập tiêu đề",
                      },
                    ]}
                  >
                    <Input
                      className="text-white custom-input-ant border w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300  focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                      placeholder="Tiêu đề"
                    />
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    name="messager"
                    className="w-full px-3"
                    label="Nội dung"
                    rules={[
                      {
                        required: true,
                        message: "Hãy viết nội dung",
                      },
                    ]}
                  >
                    <CKEditor
                      // className="min-h-[500px]"
                      editor={Editor}
                      config={EDITORCONFIGURATION}
                      data={messager}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setMessager(data);
                      }}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="flex justify-center">
                <div className="mx-2">
                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    disabled={isLoading}
                    loading={isLoading}
                    className="w-[100px]"
                  >
                    Gửi
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </ConfigProvider>
        <div className="w-full lg:max-w-[400px] overflow-hidden  mb-12 mt-[30px] lg:mt-0 lg:ml-[20px] ">
          <div className=" shadow-lg bg-[#616060] rounded-[10px] h-auto min-h-[200px] mb-[20px]">
            <h1 className="p-3 text-white text-2xl font-bold">
              Thông tin licensekey
            </h1>
            <ul className="text-base text-white pb-2">
              <li className="px-4 py-2 ">
                <span className=" inline-block font-thin">Khách hàng :</span>
                <span className="ml-2 font-medium capitalize">
                  {infoLicenseKey?.customerName}
                </span>
              </li>
              <li className="px-4 py-2 ">
                <span className=" inline-block font-thin">Sản phẩm :</span>
                <span className="ml-2 font-medium capitalize">
                  {infoLicenseKey?.productName}
                </span>
              </li>
              <li className="px-4 py-2 ">
                <span className=" inline-block font-thin">Edition :</span>
                <span className="ml-2 font-medium capitalize">
                  {infoLicenseKey?.editionName} (
                  {infoLicenseKey?.licenseType === 1
                    ? " Online "
                    : infoLicenseKey?.licenseType === 2
                    ? " Offline "
                    : "S/p Ncc khác"}
                  )
                </span>
              </li>
              <li className="px-4 py-2 ">
                <span className=" inline-block font-thin">Version :</span>
                <span className="ml-2 font-medium capitalize">
                  {infoLicenseKey?.versionName}
                </span>
              </li>
              <li className="px-4 py-2 ">
                <span className=" inline-block font-thin">
                  Thời gian sử dụng:
                </span>
                <span className="ml-2 font-medium">
                  {moment(infoLicenseKey?.startDate).format("DD/MM/YYYY")} -{" "}
                  {moment(infoLicenseKey?.expirationDate).format("DD/MM/YYYY")}
                </span>
              </li>
            </ul>
          </div>
          {/* <div className="shadow-lg bg-[#262626] rounded-[10px] h-auto min-h-[200px]">
            <h1 className="p-3 text-white text-2xl font-bold">
              Lịch sử gửi email
            </h1>
            {historyMail?.length > 0 ? (
              <>
                <ul className="text-base text-white">
                  {historyMail?.map((item, index) => {
                    return (
                      <>
                        <li key={index} className="px-4 py-2 ">
                          <span className=" inline-block font-thin">
                            -{" "}
                            {moment(item?.sendingTime).format(
                              "DD/MM/YYYY, HH:mm"
                            )}{" "}
                            :
                          </span>
                          <span className="ml-2 font-medium">
                            {item?.sendByAccountName}
                          </span>
                        </li>
                      </>
                    );
                  })}
                </ul>
              </>
            ) : (
              <div className="my-6 p-3">
                <i className="text-[#9E9E9E] text-[13px] font-thin leading-5">
                  Chưa có lịch sử gửi Email
                </i>
              </div>
            )}
          </div> */}
        </div>
      </div>
    </>
  );
};

export default LicenseKeySendMailExpirationDate;
