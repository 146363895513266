import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../../Utils/Api";

export const sendMailExpirationDate = createAsyncThunk(
  "LKM/sendMailExpirationDate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Api.post(`/api/send/email`, data);
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

const LicenseKeySendMailExpirationDateSlice = createSlice({
  name: "LicenseKeySendMailExpirationDate",
  initialState: {
    items: [],
    meta: {
      page: 0,
      size: 10,
      total: 0,
    },
    isLoading: false,
  },
  extraReducers: {
    [sendMailExpirationDate.pending]: (state, action) => {
      state.isLoading = true;
    },
    [sendMailExpirationDate.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [sendMailExpirationDate.rejected]: (state, action) => {
      state.isLoading = false;
    },
  },
});
export default LicenseKeySendMailExpirationDateSlice.reducer;
