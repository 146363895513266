import { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, useParams, useLocation } from "react-router-dom";
import ButtonSubmit from "./../../../components/Button/ButtonSubmit";
import TableAdmin from "./../../../components/Table/TableAdmin";
import InputSearch from "../../../components/InputSearch/InputSearch";
import { unwrapResult } from "@reduxjs/toolkit";
import { notification, Form, Button } from "antd";
import ROUTER from "../../../routers/routerLink";
import { debounce } from "lodash";
import queryString from "query-string";
import { getEditionByIdProduct, deleteEdition } from "./../EditionSlice";
import ModalVersionByEditon from "./ModalVersionByEditon";

const EditionList = (props) => {
  const [valueSearch, setValueSearch] = useState("");
  const [form] = Form.useForm();
  const search = useLocation().search;
  const { idproduct } = useParams();
  const dispatch = useDispatch();
  const listEdition = useSelector((state) => state?.edition?.items);
  const meta = useSelector((state) => state?.edition?.meta);
  const isLoading = useSelector((state) => state?.edition?.isLoading);
  const [dataModal, setDataModal] = useState({
    openModal: false,
    idEdition: "",
  });
  useEffect(() => {
    if (search) {
      setValueSearch(queryString.parse(search)["keyword"]);
    } else {
      setValueSearch("");
    }
  }, [search]);

  useEffect(() => {
    const { search } = props.history.location;
    let params;
    const filterOptions = search.split("?")[1];
    if (filterOptions !== undefined) {
      params = `{"${decodeURI(filterOptions)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')}"}`;
      dispatch(
        getEditionByIdProduct({ id: idproduct, params: JSON.parse(params) })
      );
    } else {
      dispatch(getEditionByIdProduct({ id: idproduct, params: null }));
    }
  }, [dispatch, props.history.location, idproduct]);

  const columns = [
    { dataIndex: "title", title: "Edition", type: "center" },
    {
      dataIndex: "productTitle",
      title: "Sản phẩm",
    },
    {
      dataIndex: "type",
      title: "Loại",
      render: (value) => (
        <span className="font-medium">
          {value === true
            ? "Online"
            : value === false
            ? "Offline"
            : "S/p Ncc khác"}
        </span>
      ),
    },
    {
      title: "Version",
      sorting: true,
      render: (value) => (
        <Button onClick={() => showModalVersion(value)}>Chi tiết</Button>
      ),
    },
    // {
    //   dataIndex: "description",
    //   title: "Ghi chú",
    //   type: "left",
    //   render: (value) =>
    //     value?.length >= 20 ? (
    //       <Popover
    //         content={
    //           <>
    //             <div className="max-w-[250px] break-words">
    //               <h1 className="border-b-2 text-xl font-semibold">Ghi chú </h1>
    //               <p> {value}</p>
    //             </div>
    //           </>
    //         }
    //       >
    //         <span className="font-medium">
    //           {value.substring(0, 20) + " ..."}
    //         </span>
    //       </Popover>
    //     ) : (
    //       <span className="font-medium">{value}</span>
    //     ),
    // },
  ];

  const showModalVersion = (value) => {
    setDataModal({
      openModal: true,
      idEdition: value?.id,
      idProduct: value?.productId,
    });
  };
  const handleCancel = async () => {
    await setDataModal({
      openModal: false,
      idEdition: null,
    });
    dispatch(getEditionByIdProduct({ id: idproduct, params: null }));
  };
  const onDelete = async (id) => {
    try {
      const resultAction = await dispatch(deleteEdition(id));
      unwrapResult(resultAction);
      notification.success({ message: `Xoá thành công!` });
    } catch (error) {
      if (error.message === "Rejected") {
        notification.error({
          message: `Lỗi: Vui lòng kiểm tra lại kết nối mạng !`,
        });
      } else {
        notification.error({ message: `Lỗi: ${error.message}` });
      }
    }
  };
  const fecthSearch = (value) => {
    if (value) {
      props.history.push(
        `${ROUTER.PRODUCT.EDITION.LIST}/${idproduct}?keyword=${value}`
      );
    } else {
      props.history.push(`${ROUTER.PRODUCT.EDITION.LIST}/${idproduct}`);
      dispatch(getEditionByIdProduct({ id: idproduct, params: null }));
    }
  };
  const debounceSearch = useCallback(debounce(fecthSearch, 1000), []);
  const onSearch = (e) => {
    setValueSearch(e.target.value.replace(/[&%*?]/g, ""));
    debounceSearch(e.target.value.replace(/[&%?]/g, ""));
  };

  const onChangePage = (page, size) => {
    const { search } = props.history.location;
    if (search !== "") {
      let params;
      const filterOptions = search.split("?")[1];
      if (filterOptions !== undefined) {
        params = `{"${decodeURI(filterOptions)
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"')}"}`;
      }
      const obj = JSON.parse(params);
      params = { ...obj, page, size };
      const urlParameters = [];
      Object.entries(params).forEach((e) => {
        if (e[1] !== undefined && e[1] !== "") {
          urlParameters.push(e.join("="));
        }
      });
      props.history.push(
        `${ROUTER.PRODUCT.EDITION.LIST}/${idproduct}`.concat(
          `?${urlParameters.join("&")}`
        )
      );
    } else {
      props.history.push(
        `${ROUTER.PRODUCT.EDITION.LIST}/${idproduct}`.concat(
          `?page=${page}&size=${size}`
        )
      );
    }
  };
  return (
    <>
      <div className="overflow-x-auto mb-10 mt-4">
        <ModalVersionByEditon
          openModal={dataModal.openModal}
          idEdition={dataModal.idEdition}
          idProduct={dataModal.idProduct}
          handleCancel={handleCancel}
        />
        <div className="flex justify-center mb-3 bg-[#262626] px-4 py-8 rounded-[10px]">
          <div className="w-full">
            <div className="w-full mb-3 md:flex md:justify-between">
              <div>
                <NavLink to={`${ROUTER.PRODUCT.EDITION.LIST}/${idproduct}/add`}>
                  <ButtonSubmit
                    // onClick={() => history.push(ROUTER.VERSION.ADD)}
                    iconButton={<i className="fas fa-plus pr-[15px]"></i>}
                    titleButton="Thêm mới"
                  />
                </NavLink>
              </div>
              <Form form={form}>
                <div className="md:flex md:justify-end">
                  <Form.Item name="searchName">
                    <InputSearch
                      placeholder="Tìm kiếm edition..."
                      onChange={onSearch}
                      valueSearch={valueSearch}
                    />
                  </Form.Item>
                </div>
              </Form>
            </div>
            <div>
              <TableAdmin
                columns={columns}
                dataTable={listEdition}
                Url={`${ROUTER.PRODUCT.EDITION.LIST}/${idproduct}/edit`}
                spinning={isLoading}
                onDelete={onDelete}
                // handlePagination={(v) =>
                //   onChangePage(v?.currentPage, v?.itemsPerPage)
                // }
                // totalItems={meta?.total}
                meta={meta}
                onChangePage={onChangePage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditionList;
