import React, { useEffect } from "react";
import { Modal, Tooltip, Form, Button, Input, notification } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import Api from "../../../Utils/Api";

const ModalTemplateMail = ({
  handleCancelModalTitle,
  showModalTemplateMail,
  context,
  title,
  id,
  setTemplateSendMailId,
}) => {
  const [form] = Form.useForm();
  useEffect(() => {
    if (title) {
      form.setFieldsValue({
        title: title,
      });
    } else {
      form.resetFields();
    }
  }, [title, form]);
  const onFinish = async (value) => {
    const data = {
      title: value?.title.trim(),
      context: context,
    };
    if (id && id !== "default") {
      const responseUpdate = await Api.put(
        `/api/templatemail/update/${id}`,
        data
      );
      if (responseUpdate?.data?.success === true) {
        setTemplateSendMailId(responseUpdate?.data?.data?.id);
        notification.success({ message: `Cập nhật mẫu mail thành công !!!` });
        handleCancelModalTitle();
        form.resetFields();
      }
    } else {
      const responseAdd = await Api.post(`/api/templatemail/create`, data);
      if (responseAdd?.data?.success === true) {
        setTemplateSendMailId(responseAdd?.data?.data?.id);
        notification.success({ message: `Thêm mới mẫu email thành công !!!` });
        handleCancelModalTitle();
        form.resetFields();
      }
    }
  };
  return (
    <>
      <Modal
        // confirmLoading={isLoading}
        title={title ? "Cập nhật" : "Thêm mới"}
        visible={showModalTemplateMail}
        onCancel={() => {
          handleCancelModalTitle();
          form.resetFields();
        }}
        centered
        destroyOnClose={true}
        footer={[
          <Button
            danger
            type="primary"
            onClick={() => {
              handleCancelModalTitle();
              form.resetFields();
            }}
          >
            Hủy
          </Button>,
          <Button
            className="min-w-[80px]"
            form={`templateMail`}
            type="primary"
            htmlType="submit"
            // disabled={isLoading}
            // loading={isLoading}
          >
            Lưu
          </Button>,
        ]}
        closeIcon={
          <Tooltip title="Đóng">
            <CloseOutlined />
          </Tooltip>
        }
      >
        <Form
          layout="vertical"
          onFinish={onFinish}
          form={form}
          id={`templateMail`}
        >
          <div className="flex flex-wrap">
            <Form.Item
              name="title"
              className="w-full px-3"
              label="Tên template"
              rules={[
                {
                  required: true,
                  message: "Hãy nhập tên template",
                },
              ]}
            >
              <Input
                className="text-white custom-input-ant border w-full h-[40px] rounded-[6px] hover:ring-1  hover:ring-purple-300  focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
                placeholder="Tên template"
              />
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default ModalTemplateMail;
