import moment from "moment";
import { useState } from "react";
import Chart from "react-apexcharts";
import { useHistory } from "react-router-dom";
import ROUTER from "../../../routers/routerLink";
import { useDispatch } from "react-redux";
import { setFilters } from "../../LicenseKeyInChart/LicenseKeyInChartSlice";

const ChartColumn = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const getProductID = (indexProduct, ListProduct) => {
    return ListProduct[indexProduct].productId;
  };
  const [dataChart] = useState({
    series: [
      {
        name: "Trên 15 ngày",
        data: props?.listMoreThan15Day ? props?.listMoreThan15Day : [],
      },
      {
        name: "Dưới 15 ngày",
        data: props?.listLessThan15Days ? props?.listLessThan15Days : [],
      },
      {
        name: "Hết hạn",
        data: props?.listExpirationDate ? props?.listExpirationDate : [],
      },
    ],
    options: {
      chart: {
        stacked: true,
        foreColor: "#fff",
        background: "none",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: true,
        },
        events: {
          dataPointSelection: (event, chartContext, config) => {
            if (config.w.config.series[config.seriesIndex].name === "Hết hạn") {
              dispatch(
                setFilters({
                  timestart: moment().format("YYYY-MM-DD"),
                })
              );
            } else if (
              config.w.config.series[config.seriesIndex].name === "Trên 15 ngày"
            ) {
              dispatch(
                setFilters({
                  timeend: moment().add(15, "days").format("YYYY-MM-DD"),
                })
              );
            } else {
              dispatch(
                setFilters({
                  timestart: moment().format("YYYY-MM-DD"),
                  timeend: moment().add(15, "days").format("YYYY-MM-DD"),
                })
              );
            }
            const productId = getProductID(
              config.dataPointIndex,
              props?.listProduct
            );
            history.push(`${ROUTER.LICENSEKEYINCHART.LIST}/${productId}`);
          },
        },
      },
      colors: ["#598ef0", "#ffc001", "#ff0000"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      xaxis: {
        categories: props?.listProduct.map((item) => item.title),
        labels: {
          trim: true,
        },
        range: 2,
      },
      legend: {
        position: "bottom",
      },
      fill: {
        opacity: 1,
      },
    },
  });

  return (
    <>
      <div className="w-full max-w-[100%] xl:max-w-[90%]">
        <Chart
          series={dataChart?.series}
          options={dataChart?.options}
          type="bar"
          height={310}
        />
      </div>
    </>
  );
};

export default ChartColumn;
