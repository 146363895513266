import React from "react";
import { CFooter } from "@coreui/react";
import moment from "moment";
const TheFooter = () => {
  return (
    <CFooter fixed={false} className="bg-[#262626] flex justify-center">
      <div>
        <span className="ml-1 text-white">
          &copy; {moment().format("YYYY")} Toàn bộ bản quyền thuộc về{" "}
          <a className="text-blue-500" href="https://htigroup.vn">
            HTI
          </a>
        </span>
      </div>
    </CFooter>
  );
};

export default React.memo(TheFooter);
