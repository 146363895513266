import { Popconfirm, Spin } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";
import SelectColumn from "./SelectColumn";
import { Pagination, Tooltip } from "antd";
import ROUTER from "../../routers/routerLink";
import moment from "moment";

const TableAdmin = ({
  dataTable,
  columns,
  Url,
  onDelete,
  spinning,
  meta,
  onChangePage,
  hiddenAction,
  downloadTxt,
  sendMail,
}) => {
  const [downloadLiscenseKey, setDownloadLiscenseKey] = useState("");

  //export key txt "User: ađâsđá", "\n", "Password: đâsđâsád"
  const makeLiscenseKey = (itemData) => {
    if (itemData?.licenseType === 1) {
      const data = new Blob(
        [
          `Họ tên:  ${itemData?.customerName}`,
          "\n",
          `Sản phẩm:  ${itemData?.productName}`,
          "\n",
          `Ngày kích hoạt:  ${moment(itemData?.startDate).format(
            "DD-MM-YYYY"
          )}`,
          "\n",
          `Ngày hết hạn:  ${moment(itemData?.expirationDate).format(
            "DD-MM-YYYY"
          )}`,
          "\n",
          `Email: ${itemData?.email}`,
          "\n",
          `Mật khẩu: ${itemData?.password}`,
        ],
        {
          type: "text/plain",
        }
      );
      if (downloadLiscenseKey !== "")
        window.URL.revokeObjectURL(downloadLiscenseKey);
      setDownloadLiscenseKey(window.URL.createObjectURL(data));
    } else if (itemData?.licenseType === 2) {
      const data = new Blob(
        [
          `Họ tên:  ${itemData?.customerName}`,
          "\n",
          `Sản phẩm:  ${itemData?.productName}`,
          "\n",
          `Ngày kích hoạt:  ${moment(itemData?.startDate).format(
            "DD-MM-YYYY"
          )}`,
          "\n",
          `Ngày hết hạn:  ${moment(itemData?.expirationDate).format(
            "DD-MM-YYYY"
          )}`,
          "\n",
          `LicenseKey: ${itemData?.lastKey}`,
        ],
        {
          type: "text/plain",
        }
      );
      if (downloadLiscenseKey !== "")
        window.URL.revokeObjectURL(downloadLiscenseKey);
      setDownloadLiscenseKey(window.URL.createObjectURL(data));
    } else {
      const data = new Blob(
        [
          `Họ tên:  ${itemData?.customerName}`,
          "\n",
          `Sản phẩm:  ${itemData?.productName}`,
          "\n",
          `Ngày kích hoạt:  ${moment(itemData?.startDate).format(
            "DD-MM-YYYY"
          )}`,
          "\n",
          `Ngày hết hạn:  ${moment(itemData?.expirationDate).format(
            "DD-MM-YYYY"
          )}`,
          "\n",
          `Email: ${itemData?.email || "Chưa có"}`,
          "\n",
          `Mật khẩu: ${itemData?.password || "Chưa có"}`,
          "\n",
          `LicenseKey: ${itemData?.lastKey || "Chưa có"}`,
        ],
        {
          type: "text/plain",
        }
      );
      if (downloadLiscenseKey !== "")
        window.URL.revokeObjectURL(downloadLiscenseKey);
      setDownloadLiscenseKey(window.URL.createObjectURL(data));
    }
  };

  const [sorting, setSorting] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState(columns);
  const listData = [...dataTable];
  const handleSortClick = ({ dataIndex, direction }) => {
    const newSort = sorting.filter((_item) => _item?.dataIndex !== dataIndex);
    newSort?.push({ dataIndex, direction });
    selectedColumns?.map((_column) => {
      if (_column?.dataIndex === dataIndex) {
        _column.sorting = { dataIndex, direction };
      }
      return _column;
    });
    setSorting(newSort);
  };

  const getSortedData = (data) => {
    if (sorting?.length <= 0) return data;
    let result = [];
    for (let i = 0; i < sorting?.length; i++) {
      result = data?.sort((a, b) => {
        if (sorting[i]?.direction === "desc") {
          return a[sorting[i]?.dataIndex] > b[sorting[i]?.dataIndex] ? -1 : 1;
        } else {
          return a[sorting[i]?.dataIndex] > b[sorting[i]?.dataIndex] ? 1 : -1;
        }
      });
    }
    return result;
  };

  const handleRenderColumn = (value) => {
    let resultArray = [];
    columns.map((i) =>
      value.includes(i?.dataIndex) ? resultArray.push(i) : ""
    );
    setSelectedColumns(resultArray);
  };

  return (
    <>
      <Spin spinning={spinning ? spinning : false}>
        <div className="shadow overflow-auto">
          <table className="min-w-max w-full table-auto">
            <thead>
              <tr className="bg-[#3E3C3C] text-white uppercase text-sm leading-normal">
                <th className="py-1 px-4 text-center w-[80px] rounded-tl-[10px] rounded-bl-[10px]">
                  STT
                </th>
                {selectedColumns?.map((item, index) => (
                  <th
                    key={index}
                    className={`py-3 px-2 text-${
                      item.type ? item.type : "center"
                    } cursor-pointer`}
                    onClick={() =>
                      handleSortClick({
                        dataIndex: item?.dataIndex,
                        direction:
                          item?.sorting?.direction === "asc" ? "desc" : "asc",
                      })
                    }
                  >
                    {item?.title}
                    {item?.sorting && (
                      <span style={{ marginLeft: "5px", opacity: "0.5" }}>
                        {item?.sorting?.direction === "asc" && (
                          <i className="fas fa-sort-amount-up" />
                        )}
                        {item?.sorting?.direction === "desc" && (
                          <i className="fas fa-sort-amount-down" />
                        )}
                        {item?.sorting?.direction === "default" && (
                          <i className="fas fa-sort" />
                        )}
                      </span>
                    )}
                  </th>
                ))}
                {!hiddenAction ? (
                  <>
                    <th className="py-1 px-6 text-center rounded-tr-[10px] rounded-br-[10px]">
                      <span>Chức Năng</span>
                      <div className="transform hover:scale-110 inline-block">
                        <SelectColumn
                          defaultColumn={columns}
                          handleChildSelectColumn={handleRenderColumn}
                        />
                      </div>
                    </th>
                  </>
                ) : (
                  ""
                )}
              </tr>
            </thead>

            {listData && listData?.length > 0 ? (
              <>
                <tbody className="text-white text-sm font-light ">
                  {getSortedData(listData)?.map((itemData, index) => (
                    <tr
                      key={index}
                      className=" hover:bg-[#4f7b77] border-b-2 border-gray-700"
                    >
                      <td className="p-2 text-center font-medium rounded-tl-[10px] rounded-bl-[10px] ">
                        {index + 1}
                      </td>
                      {selectedColumns?.map((itemColumns, index) => (
                        <td
                          key={index}
                          className={` p-2 text-${
                            itemColumns.type ? itemColumns.type : "center"
                          }`}
                        >
                          {typeof itemColumns?.render === "function" ? (
                            itemColumns?.dataIndex ? (
                              itemColumns?.render(
                                itemData[itemColumns?.dataIndex]
                              )
                            ) : (
                              itemColumns?.render(itemData)
                            )
                          ) : (
                            <span className="font-medium">
                              {itemColumns?.dataIndex
                                ? itemData[itemColumns?.dataIndex]
                                : ""}
                            </span>
                          )}
                        </td>
                      ))}
                      {!hiddenAction && (
                        <>
                          <td className="p-2 text-center rounded-tr-[10px] rounded-br-[10px]">
                            <div className="flex item-center justify-center">
                              {sendMail ? (
                                <>
                                  <div className="mr-2 transform hover:scale-110">
                                    <Tooltip title="Gửi mail thông báo hết hạn">
                                      <Link
                                        to={`${ROUTER.LICENSEKEY_SEND_MAIL_EXPIRATIONDATE.LICENSEKEY_SEND_MAIL_EXPIRATIONDATE}/${itemData.id}`}
                                      >
                                        <i className="fas fa-envelope stroke-current text-white  bg-[#6E74EF] rounded-full p-[8px]"></i>
                                      </Link>
                                    </Tooltip>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}

                              {downloadTxt ? (
                                <>
                                  <div className="mr-2 transform hover:scale-110">
                                    <Tooltip title="Tải xuống">
                                      <a
                                        className=""
                                        download={`${itemData?.customerName}-licensekey.txt`}
                                        href={downloadLiscenseKey}
                                        onClick={() =>
                                          makeLiscenseKey(itemData)
                                        }
                                      >
                                        <i className="fas fa-download stroke-current text-white  bg-[#6E74EF] rounded-full p-[8px]"></i>
                                      </a>
                                    </Tooltip>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                              <div className="mr-2 transform hover:scale-110">
                                <Tooltip title="Chỉnh sửa">
                                  <Link to={`${Url}/${itemData.id}`}>
                                    <i className="fas fa-pencil-alt  stroke-current text-white  bg-[#6E74EF] rounded-full p-[8px] "></i>
                                  </Link>
                                </Tooltip>
                              </div>
                              <div className="mr-2 transform  hover:scale-110">
                                <Popconfirm
                                  title="Bạn có chắc chắn muốn xóa không ?"
                                  okText="Đồng ý"
                                  cancelText="Hủy"
                                  onConfirm={() => onDelete(itemData.id)}
                                >
                                  <Tooltip title="Xóa">
                                    <i className="fas fa-trash-alt  stroke-current text-white  bg-[#EF5D5D] rounded-full p-[8px] cursor-pointer"></i>
                                  </Tooltip>
                                </Popconfirm>
                              </div>
                            </div>
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </>
            ) : (
              <>
                <tbody className="text-white text-xl font-semibold h-[150px]">
                  <div className="absolute top-1/3  right-1/2 transform translate-x-1/2 flex flex-col text-center">
                    <i className="fas fa-exclamation-circle fa-2x"></i>
                    <span> Không có dữ liệu</span>
                  </div>
                </tbody>
              </>
            )}
          </table>
        </div>
        {/* <Pagination
          data={dataTable}
          totalItems={totalItems}
          handleChildPagination={(v) => handlePagination(v)}
        /> */}
        <div className="flex justify-end mt-3">
          {meta?.total > 10 ? (
            <>
              <Pagination
                // size="small"
                className="custom-pagination"
                current={meta?.page ? meta?.page : 1}
                total={meta?.total}
                pageSize={meta?.size}
                onChange={onChangePage}
                showSizeChanger
              />
            </>
          ) : (
            ""
          )}
        </div>
      </Spin>
    </>
  );
};

export default TableAdmin;
