import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../../Utils/Api";
export const getEmailActiveList = createAsyncThunk(
    "LKM/getEmailActiveList",
    async (params, { rejectWithValue }) => {
      try {
        const response = await Api.get(`/api/mailactive/list`, {
          params: params,
        });
        if (response?.status === 200) {
          return response?.data;
        }
      } catch (error) {
        return rejectWithValue(error.data);
      }
    }
);

export const sendMailToActiveState =  createAsyncThunk(
  "LKM/sendMailToActive",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Api.post(`/api/licensekey/active`,  payload);
      if (response?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

const MailActiveSlice = createSlice({
    name: "MailActive",
    initialState: {
      items: [],
      filters: {},
      reportTimeList: [],
      meta: {
        page: 0,
        size: 10,
        total: 0,
      },
      isLoading: false,
    },
    reducers: {

    },
    extraReducers: {
      [sendMailToActiveState.pending]: (state) => {
        state.isLoading = true;
      },
      [getEmailActiveList.pending]: (state) => {
          state.isLoading = true;
      },
      [getEmailActiveList.fulfilled]: (state, action) => {
        const { data, meta } = action?.payload;
        state.isLoading = false;
        state.items = data;
        state.meta = {
          page: meta?.page,
          size: meta?.size,
          total: meta?.total,
        };
      },
      [sendMailToActiveState.fulfilled]: (state, action) => {
        let dataUpdate = action.payload?.data
        const index = state.items.findIndex((item) => item.id === dataUpdate.id)
        state.items[index] = dataUpdate
        state.isLoading = false;
      },
      [getEmailActiveList.rejected]: (state) => {
        state.isLoading = false;
      },
      [sendMailToActiveState.rejected]: (state) => {
        state.isLoading = false;
      },
    }


})
export const { setFilters, removeFilters } = MailActiveSlice.actions;
export default MailActiveSlice.reducer;