import React from "react";

const ButtonSubmit = (props) => {
  const { iconButton, titleButton, onClick, disabled } = props;
  return (
    <>
      <button
        className="custom-btn btn-sb "
        style={{ width: "auto", height: "40px" }}
        onClick={onClick}
        disabled={disabled}
      >
        {iconButton}
        {titleButton}
      </button>
    </>
  );
};

export default ButtonSubmit;
