import React, { useState } from "react";
import { validateEmail } from "../../../Utils/Validate/validateInput";
import { useForm } from "react-hook-form";
import "../../../assets/css/login.css";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { notification, Alert } from "antd";
import logo from "../../../assets/image/logo.png";
import ROUTER from "../../../routers/routerLink";
import { forgotPassword } from "./ForgotPasswordSlice";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.forgotPassword);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const onSubmit = async (value) => {
    try {
      const resultApi = await dispatch(forgotPassword(value));
      unwrapResult(resultApi);
      if (resultApi.payload.success === true) {
        setEmail(value?.email);
      }
    } catch (error) {
      if (error.message === "Rejected") {
        notification.error({
          message: `Lỗi: Vui lòng kiểm tra lại kết nối mạng !`,
        });
      } else {
        notification.error({ message: `Lỗi: ${error.message}` });
      }
    }
  };
  return (
    <>
      <div
        className="login-box border-2 border-gray-500"
        style={{ backgroundColor: "rgb(220,220,220,0.1)" }}
      >
        <div className="flex justify-center mb-6 ">
          <img className=" w-[200px] h-[80px] " src={logo} alt="" />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          {email && (
            <Alert
              className="my-3"
              message={`Chúng tôi sẽ gửi thông tin lấy lại mật khẩu mới vào email: ${email} trong 1-2 phút tới. Bạn vui lòng mở email và làm theo hướng dẫn!`}
              type="success"
            />
          )}
          <div className="">
            <label className=" text-gray-200 text-sm font-bold mb-2">
              Email
            </label>
            <input
              className="border pl-[10px] w-full h-[40px] rounded-[6px] hover:ring-2 hover:ring-purple-300 focus:outline-none focus:ring-2  focus:ring-purple-600 focus:border-transparent"
              type="text"
              placeholder="Email"
              {...register("email", {
                required: true,
                pattern: validateEmail,
              })}
            />
            <div className="mt-1 h-[10px] ">
              <span className="text-red-500">
                {errors?.email && errors?.email.type === "required"
                  ? "Hãy nhập email cần lấy lại mật khẩu"
                  : ""}
                {errors?.email && errors.email.type === "pattern"
                  ? "Định dạng Email không hợp lệ"
                  : ""}
              </span>
            </div>
          </div>
          <div className="flex justify-center">
            <button disabled={isLoading} href="#">
              {isLoading ? "Loading..." : "Lấy lại mật khẩu"}
              {isLoading ? (
                <>
                  <span className="span1" />
                  <span className="span2" />
                  <span className="span3" />
                  <span className="span4" />
                </>
              ) : (
                ""
              )}
            </button>
          </div>
        </form>
        <div className="pt-3 text-center">
          <a
            href={ROUTER.AUTH.LOGIN}
            className="font-semibold text-[#1877f2] hover:text-blue-300"
          >
            Đăng nhập ngay
          </a>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
