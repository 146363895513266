import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CHeader,
  CToggler,
  CHeaderBrand,
  CHeaderNav,
  CBreadcrumbRouter,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import routes from "../../routers/routers";
import { useLocation } from "react-router-dom";
import { TheHeaderDropdown, TheHeaderDropdownNotif } from "./index";
import logo1 from "../../assets/image/logo.png";
import "../../assets/css/TheHeader.css";
import ConfigSendMailLicenseKey from "./ConfigSendMailLicenseKey/ConfigSendMailLicenseKey";

const TheHeader = () => {
  const dispatch = useDispatch();
  const sidebarShow = useSelector((state) => state.changeState.sidebarShow);

  // const toggleSidebar = () => {
  //   const val = [true, "responsive"].includes(sidebarShow)
  //     ? false
  //     : "responsive";
  //   dispatch({ type: "set", sidebarShow: val });
  // };

  const toggleSidebarMobile = () => {
    const val = [false, "responsive"].includes(sidebarShow)
      ? true
      : "responsive";
    dispatch({ type: "set", sidebarShow: val });
  };
  const location = useLocation().pathname;
  const titlePage = routes.filter(
    (item) =>
      item.path === location ||
      item.path.split("/")[(0, 1)] + "/" + item.path.split("/")[(0, 2)] ===
        location.split("/")[(0, 1)] + "/" + location.split("/")[(0, 2)] ||
      item.path.split("/")[(0, 1)] +
        "/" +
        item.path.split("/")[(0, 2)] +
        "/" +
        item.path.split("/")[(0, 4)] ===
        location.split("/")[(0, 1)] +
          "/" +
          location.split("/")[(0, 2)] +
          "/" +
          location.split("/")[(0, 4)]
  );
  return (
    <>
      <CHeader withSubheader className="h-[65px] bg-[#262626]">
        <CToggler
          inHeader
          className="ml-md-3 d-lg-none text-white"
          onClick={toggleSidebarMobile}
        />
        {/* <CToggler
          inHeader
          className="ml-3 d-md-down-none"
          onClick={toggleSidebar}
        /> */}
        <CHeaderBrand className="mx-auto d-lg-none" to="/">
          <CIcon name="logo" src={logo1} alt="Logo" />
        </CHeaderBrand>
        <CHeaderNav className="d-md-down-none mr-auto ">
          {/* <CHeaderNavItem className="px-3">
            <CHeaderNavLink className="text-white" to="/">
              Dashboard
            </CHeaderNavLink>
          </CHeaderNavItem>
          <CHeaderNavItem className="px-3">
            <CHeaderNavLink to="/account">Users</CHeaderNavLink>
          </CHeaderNavItem>
          <CHeaderNavItem className="px-3">
            <CHeaderNavLink to="/license-key">LicenseKey</CHeaderNavLink>
          </CHeaderNavItem> */}
        </CHeaderNav>

        <CHeaderNav className="sm:px-3 ">
          <ConfigSendMailLicenseKey />
          <TheHeaderDropdownNotif />
          <TheHeaderDropdown />
        </CHeaderNav>
      </CHeader>
      <div className="px-3 justify-content-between sm:mt-2 md:mt-4 ">
        <span className="customer-title-header md:ml-[14px] text-[20px] md:text-[28px] capitalize  text-white font-extrabold">
          {titlePage && titlePage.length > 0
            ? titlePage[0]?.name
            : location.slice(1, 21) === "license-key-in-chart"
            ? "Danh sách LicenseKey hết hạn"
            : location.slice(1, 27) === "license-key-category_chart"
            ? "Danh sách LicenseKey danh mục"
            : location.slice(1, 37) === "license-key-send-mail-expirationdate"
            ? "Gửi Email licensekey hết hạn"
            : location.slice(1, 28) === "license-key-send-mail-intro"
            ? "Chi tiết Email"
            : location.slice(1, 16) === "product/version"
            ? "Quản lý Version"
            : "Cập nhật"}
        </span>
        <CBreadcrumbRouter
          className={`border-0 m-0 px-0 px-md-3 font-thin ${
            location === "/" && "hidden"
          } `}
          routes={routes}
        />
      </div>
    </>
  );
};

export default TheHeader;
