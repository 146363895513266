// export const API_URL = "http://192.168.133.10:8080/licensekey";
import { getUserInfo } from "./localStorage/GetUserInfo";
export const API_URL = "https://licensekey.api.htsc.vn";

const token = getUserInfo("token");
export const EDITORCONFIGURATION = {
  simpleUpload: {
    uploadUrl: `${API_URL}/api/sendmail/ckeditor/uploads`,
    headers: {
      "X-CSRF-TOKEN": "CSRF-Token",
      Authorization: `Bearer ${token}`,
    },
  },
};
